import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import {
  Timeline,
  GetTimelinePayload,
  ListTimelinePayload,
  CreateTimelinePayload,
  UpdateTimelinePayload,
  DeleteTimelinePayload,
} from "../models";

interface ITimelineClient {
  /**
   * 時系列の取得
   */
  fetchTimeline: (payload: GetTimelinePayload) => Promise<Timeline>;

  /**
   * 時系列一覧の取得
   */
  fetchTimelines: (payload: ListTimelinePayload) => Promise<Timeline[]>;

  /**
   * 時系列の作成
   */
  createTimeline: (payload: CreateTimelinePayload) => Promise<Timeline>;

  /**
   * 時系列の更新
   */
  updateTimeline: (payload: UpdateTimelinePayload) => Promise<Timeline>;

  /**
   * 時系列の削除
   */
  deleteTimeline: (payload: DeleteTimelinePayload) => Promise<Timeline>;
}

export class TimelineClient implements ITimelineClient {
  async fetchTimeline(payload: GetTimelinePayload): Promise<Timeline> {
    const { novelId, timelineId } = payload;
    const params: RequestParam = {
      query: fetchTimeline,
      variables: {
        input: {
          novelId,
          timelineId,
        },
      },
    };

    const { timeline } = await GraphQLClient.request(params);
    return timeline;
  }

  async fetchTimelines(payload: ListTimelinePayload): Promise<Timeline[]> {
    const { novelId } = payload;
    const params: RequestParam = {
      query: fetchTimelines,
      variables: {
        input: {
          novelId,
        },
      },
    };

    const { timelines } = await GraphQLClient.request(params);
    return timelines.items;
  }

  async createTimeline(payload: CreateTimelinePayload): Promise<Timeline> {
    const { novelId, timelineType, name, tableHeaders, tableBodies } = payload;
    const params: RequestParam = {
      query: createTimelineQuery,
      variables: {
        create: {
          novelId,
          timelineType,
          name,
          tableHeaders,
          tableBodies,
        },
      },
    };

    const { createTimeline } = await GraphQLClient.request(params);
    return createTimeline;
  }

  async updateTimeline(payload: UpdateTimelinePayload): Promise<Timeline> {
    const { novelId, timelineId, timelineType, name, tableHeaders, tableBodies } = payload;
    const params: RequestParam = {
      query: updateTimelineQuery,
      variables: {
        update: {
          novelId,
          timelineId,
          timelineType,
          name,
          tableHeaders,
          tableBodies,
        },
      },
    };

    const { updateTimeline } = await GraphQLClient.request(params);
    return updateTimeline;
  }

  async deleteTimeline(payload: DeleteTimelinePayload): Promise<Timeline> {
    const { novelId, timelineId } = payload;
    const params: RequestParam = {
      query: deleteTimelineQuery,
      variables: {
        delete: {
          novelId,
          timelineId,
        },
      },
    };

    const { deleteTimeline } = await GraphQLClient.request(params);
    return deleteTimeline;
  }
}

/**
 * Query
 */

const fetchTimeline = gql`
  query Timeline($input: GetTimelineInput!) {
    timeline(input: $input) {
      userId
      novelId
      timelineId
      timelineType
      name
      tableHeaders {
        id
        name
      }
      tableBodies {
        id
        kind
        items {
          key
          value
          color
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const fetchTimelines = gql`
  query Timelines($input: ListTimelineInput!) {
    timelines(input: $input) {
      items {
        userId
        novelId
        timelineId
        timelineType
        name
        tableHeaders {
          id
          name
        }
        tableBodies {
          id
          kind
          items {
            key
            value
            color
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * Mutation
 */

const createTimelineQuery = gql`
  mutation CreateTimeline($create: CreateTimelineInput!) {
    createTimeline(input: $create) {
      novelId
      timelineId
      timelineType
      name
      tableHeaders {
        id
        name
      }
      tableBodies {
        id
        kind
        items {
          key
          value
          color
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const updateTimelineQuery = gql`
  mutation UpdateTimeline($update: UpdateTimelineInput!) {
    updateTimeline(input: $update) {
      novelId
      timelineId
      timelineType
      name
      tableHeaders {
        id
        name
      }
      tableBodies {
        id
        kind
        items {
          key
          value
          color
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const deleteTimelineQuery = gql`
  mutation DeleteTimeline($delete: DeleteTimelineInput!) {
    deleteTimeline(input: $delete) {
      novelId
      timelineId
      timelineType
      name
      tableHeaders {
        id
        name
      }
      tableBodies {
        id
        kind
        items {
          key
          value
          color
        }
      }
      createdAt
      updatedAt
    }
  }
`;
