import { isAfter, isBefore } from "date-fns";

export function reOrderData<T>(items: T[], order: string[], keyAttr: string = "id"): T[] {
  if (order.length < 1) {
    return items;
  }

  const keyMap: { [s: string]: number } = {};
  order.forEach((o, index) => (keyMap[o] = index + 1));

  const result = items.concat();
  result.sort((a, b) => compare(a, b, keyMap, keyAttr));

  return result;
}

type SortType = "createdAsc" | "createdDesc" | "updatedAsc" | "updatedDesc" | "titleAsc" | "titleDesc";

export function createdAtSort(a: any, b: any, sortType: SortType): number {
  const aCreatedDate = new Date(a.createdAt);
  const bCreatedDate = new Date(b.createdAt);

  switch (sortType) {
    case "createdAsc":
      return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
    case "createdDesc":
      return isAfter(aCreatedDate, bCreatedDate) ? -1 : 1;
    default:
      return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
  }
}

function compare(a: any, b: any, keyMap: { [s: string]: number }, keyAttr: string): number {
  const aOrder = keyMap[a[keyAttr]];
  const bOrder = keyMap[b[keyAttr]];

  if (aOrder && bOrder) return aOrder - bOrder;

  if (!aOrder && !bOrder) return a.createdAt!.localeCompare(b.createdAt!)!;

  // 新規作成時の場合は後に表示
  if (aOrder) return -1;

  return 1;
}
