import { NolaApiInitializer } from "@/initializer";
import { API } from "aws-amplify";
import {
  ContestEntries,
  EntryContestInput,
  EntryContestOutput,
  NolaContest,
  NolaContestUser,
} from "../models/nolaContest";

const API_NAME = "NolaContestAPI";

interface INolaContestClient {
  fetchContest: (contestId: string) => Promise<NolaContest>;
  entryContest: (payload: EntryContestInput) => Promise<EntryContestOutput>;
  fetchUser: () => Promise<NolaContestUser>;
  updateUser: (payload: NolaContestUser) => Promise<NolaContestUser>;
  fetchContestEntries: () => Promise<ContestEntries[]>;
}

export class NolaContestClient implements INolaContestClient {
  async fetchContest(contestId: string): Promise<NolaContest> {
    NolaApiInitializer.init();

    const init = { queryStringParameters: { id: contestId } };
    const result = await API.get(API_NAME, "/get-contest", init);

    return result;
  }

  async entryContest(payload: EntryContestInput): Promise<EntryContestOutput> {
    NolaApiInitializer.init();

    const result = await API.post(API_NAME, "/entry-contest", { body: payload });

    return result;
  }

  async fetchUser(): Promise<NolaContestUser> {
    NolaApiInitializer.init();

    const result = await API.get(API_NAME, "/get-user", {});

    return result;
  }

  async updateUser(payload: NolaContestUser): Promise<NolaContestUser> {
    NolaApiInitializer.init();

    const result = await API.post(API_NAME, "/set-user", { body: payload });

    return result;
  }

  async fetchContestEntries(): Promise<ContestEntries[]> {
    NolaApiInitializer.init();

    const result = await API.get(API_NAME, "/get-contest-entries", {});

    return result;
  }
}
