/* eslint-disable camelcase */
import qs from "query-string";
import store from "store";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { callApi } from "@/lib/api";
import gql from "graphql-tag";
import env from "./env";

const sessionKey = "session";
const { location } = window;

export default async function initialize() {
  const sessionObject = store.get(sessionKey);
  if (location.search) {
    const queryStr = qs.parse(location.search);

    if (queryStr.oauth_token && queryStr.oauth_verifier) {
      await authProcess(queryStr.oauth_token, queryStr.oauth_verifier);
    }
  }
  if (sessionObject) {
    return await applyCredential(sessionObject);
  }
  return true;
}

async function authProcess(oauth_token, oauth_verifier) {
  try {
    console.log("twitter authenticate");

    const oauth_token_secret = store.get("twitter-token-secret");

    const res = await fetch(
      `${process.env.VUE_APP_TWITTER_GETTOKEN_API_ENDPOINT}?${qs.stringify({
        oauth_token,
        oauth_verifier,
        oauth_token_secret,
      })}`
    );
    const credentialJson = await res.json();
    const credential = credentialJson.token;

    const onetime = { gotoMigrate: true };
    applyCredential(credential, onetime);

    store.set(sessionKey, credential);

    if (onetime.gotoMigrate) {
      location.href = "/migrate";
    } else {
      location.href = "/mypage";
    }
  } catch (err) {
    console.log({ err });
    await alert("内部エラーが発生しました");
  }
}

async function applyCredential(credential, onetime = { gotoMigrate: true }) {
  const IdentityPoolId = env.VUE_APP_ID_POOL_ID as any;

  AWS.config.region = "ap-northeast-1";
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId,
    Logins: {
      "api.twitter.com": credential,
    },
  });

  await (AWS.config.credentials as any).getPromise();
  await Auth.currentCredentials();
  _isTwitterLogin = true;

  if (!window.localStorage.getItem("TESTING")) {
    const { user } = await callApi(
      gql`
        {
          user {
            name
            migrated
          }
        }
      `
    );
    if (user.migrated) {
      alert("あなたは既にメールアドレスを登録済みです。\n\nメールアドレスとパスワードでログインしてください。");
      await Auth.signOut();
      window.localStorage.clear();
      location.href = "/auth/signin";
      return false;
    }
    const NovelsQuery = `{
        novels {
            items { novelId }
        }
        }`;
    const { novels } = await callApi(NovelsQuery);
    if (!novels.items.length) {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          "Twitterログインは新規ユーザーは利用できません。\nメールアドレスで会員登録、ログインしてください。\n\nログアウトしますか？"
        )
      ) {
        // eslint-disable-next-line no-param-reassign
        onetime.gotoMigrate = false;
        return true;
      }
      await Auth.signOut();
      window.localStorage.clear();
      location.href = "/auth/signin";

      return false;
    }
  }

  let lastCredentialUpdate = Date.now();
  setInterval(async () => {
    const now = Date.now();

    if (lastCredentialUpdate + 1000 * 60 * 50 < now) {
      lastCredentialUpdate = now;
      console.log(`fresh! ${AWS.config.credentials && (AWS.config.credentials as any).data.Credentials.Expiration}`);
      await (AWS.config.credentials as any).refreshPromise();
    }
  }, 1000);
  return true;
}

let _isTwitterLogin = false;

export function isTwitterLogin() {
  return _isTwitterLogin;
}

export async function getAuth() {
  try {
    if (_isTwitterLogin) {
      const currentCredentials = await Auth.currentCredentials();
      return (currentCredentials as any).data.IdentityId;
    }
    const user = await Auth.currentUserPoolUser();
    return user.signInUserSession.idToken.payload.sub;
  } catch (_) {
    return null;
  }
}
