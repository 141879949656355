import gql from "graphql-tag";
import { compareAsc } from "date-fns";
import GraphQLClient, { RequestParam } from "../api";
import { CorrelationGroup, Correlation, ListCorrelation } from "../models";

interface ICorrelationClient {
  fetchCorrelationGroupList: (novelId: string) => Promise<CorrelationGroup[]>;
  fetchCorrelationList: (payload: ListCorrelation) => Promise<Correlation[]>;
}

export class CorrelationClient implements ICorrelationClient {
  async fetchCorrelationGroupList(novelId: string): Promise<CorrelationGroup[]> {
    const params: RequestParam = {
      query: fetchCorrelationGroupList,
      variables: {
        novelId,
      },
    };

    const { correlationGroupList } = await GraphQLClient.request(params);
    return correlationGroupList.items;
  }

  async fetchCorrelationList({ novelId, correlationGroupId }: ListCorrelation): Promise<Correlation[]> {
    const params: RequestParam = {
      query: fetchCorrelationList,
      variables: {
        input: {
          novelId,
          correlationGroupId,
        },
      },
    };

    const { correlationList } = await GraphQLClient.request(params);
    return correlationList.items.sort((x: Correlation, y: Correlation) => compareAsc(x.createdAt!, y.createdAt!));
  }
}

/**
 * Query
 */

const fetchCorrelationGroupList = gql`
  query CorrelationGroupList($novelId: ID!) {
    correlationGroupList(novelId: $novelId) {
      items {
        novelId
        correlationGroupId
        name
        diagram {
          correlationKeys
          coordinate {
            characterId
            x
            y
          }
        }
      }
    }
  }
`;

const fetchCorrelationList = gql`
  query CorrelationList($input: CorrelationListGetInput!) {
    correlationList(input: $input) {
      items {
        novelId
        correlationId
        correlationGroupId
        characterA
        characterB
        relationshipAtoB {
          relationship
          color
        }
        relationshipBtoA {
          relationship
          color
        }
        memo
        createdAt
      }
    }
  }
`;
