import { ActionContext, ActionTree, GetterTree, MutationTree, Store, Commit } from "vuex";
import { ManuscriptAnalysisClient } from "@/lib/clients";
import {
  ManuscriptAnalysis,
  GetManuscriptAnalysisInput,
  CreateManuscriptAnalysisInput,
  AnalysisResult,
} from "@/lib/models";

const manuscriptAnalysisClient = new ManuscriptAnalysisClient();

// Stateの型定義
export interface ManuscriptAnalysisState {
  manuscriptAnalysisList: ManuscriptAnalysis[];
  manuscriptAnalysisResult: AnalysisResult;
  totalAnalyzedManuscriptContentLength: number;
  monthlyAnalyzedManuscriptContentLength: number;
}

type Getters = {
  manuscriptAnalysisResult(state: ManuscriptAnalysisState): AnalysisResult;
  manuscriptAnalysisList(state: ManuscriptAnalysisState): ManuscriptAnalysis[];
  totalAnalyzedManuscriptContentLength(state: ManuscriptAnalysisState): number;
  monthlyAnalyzedManuscriptContentLength(state: ManuscriptAnalysisState): number;
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<ManuscriptAnalysisState, {}>, payload: string) => Promise<void>;
  createManuscriptAnalysis: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptAnalysisState, {}>,
    payload: CreateManuscriptAnalysisInput
  ) => Promise<void>;
};

const mutations: MutationTree<ManuscriptAnalysisState> = {
  setManuscriptAnalysisList(state, payload: ManuscriptAnalysis[]) {
    const sortedPayload = payload.sort(
      (a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime()
    );
    return (state.manuscriptAnalysisList = sortedPayload);
  },
  setTotalAnalyzedManuscriptContentLength(state, payload: number) {
    return (state.totalAnalyzedManuscriptContentLength = payload);
  },
  setMonthlyAnalyzedManuscriptContentLength(state, payload: number) {
    return (state.monthlyAnalyzedManuscriptContentLength = payload);
  },
  setAnalysisResults(state, payload: AnalysisResult) {
    return (state.manuscriptAnalysisResult = payload);
  },
};

const actions: ActionTree<ManuscriptAnalysisState, {}> & Actions = {
  async initialize({ commit }, novelId) {
    // 原稿診断一覧を取得
    const manuscriptAnalysisList = await manuscriptAnalysisClient.fetchManuscriptAnalysisList(novelId);
    commit("setManuscriptAnalysisList", manuscriptAnalysisList);

    // 読者AI診断対象の原稿文字数総数を取得
    const totalAnalyzedManuscriptContentLength = await manuscriptAnalysisClient.fetchTotalAnalyzedManuscriptContentLength();
    commit("setTotalAnalyzedManuscriptContentLength", totalAnalyzedManuscriptContentLength);

    // 月間の読者AI診断対象の原稿文字数を取得
    const monthlyAnalyzedManuscriptContentLength = await manuscriptAnalysisClient.fetchMonthlyAnalyzedManuscriptContentLength();
    commit("setMonthlyAnalyzedManuscriptContentLength", monthlyAnalyzedManuscriptContentLength);
  },
  async createManuscriptAnalysis({ commit }, input) {
    const runningManuscriptAnalysis = await manuscriptAnalysisClient.createManuscriptAnalysis(input);

    const { novelId, manuscriptAnalysisId } = runningManuscriptAnalysis;
    const params: GetManuscriptAnalysisInput = {
      novelId,
      manuscriptAnalysisId,
    };

    await pollManuscriptAnalysis(commit, params);
  },
};

function pollManuscriptAnalysis(commit: Commit, params: GetManuscriptAnalysisInput): Promise<void> {
  // 本処理呼び出し先のコンポーネント側で処理終了まで待機する為に、Promiseオブジェクトでラップする
  return new Promise((resolve, reject) => {
    const doPolling = async () => {
      const waitForCompletion = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

      let attempts = 0;
      const maxAttempts = 10;

      await waitForCompletion(10000);

      while (attempts < maxAttempts) {
        // awaitをループ内で使用すると、ESLintの警告が出るが、本処理内では順次処理を行う為に、意図的にループ内で
        // awaitを行っている為、警告は無視する。(以後の処理も同様)
        // eslint-disable-next-line no-await-in-loop
        const completedManuscriptAnalysis = await manuscriptAnalysisClient.fetchManuscriptAnalysis(params);
        if (completedManuscriptAnalysis.completed && !completedManuscriptAnalysis.isError) {
          commit("setAnalysisResults", {
            manuscriptAnalysisResult: completedManuscriptAnalysis.manuscriptAnalysisResult,
            createdAt: new Date(completedManuscriptAnalysis.createdAt as string).toLocaleString(),
          });
          resolve();
          return;
        }
        if (completedManuscriptAnalysis.completed && completedManuscriptAnalysis.isError) {
          reject();
          return;
        }

        // eslint-disable-next-line no-await-in-loop
        await waitForCompletion(5000);
        attempts += 1;
      }
      reject(); // 最大試行回数に達しても処理が完了していない場合、エラーとして扱う。
    };

    doPolling().catch(reject);
  });
}

const getters: GetterTree<ManuscriptAnalysisState, {}> & Getters = {
  manuscriptAnalysisResult: (state) => state.manuscriptAnalysisResult,
  manuscriptAnalysisList: (state: ManuscriptAnalysisState) => state.manuscriptAnalysisList,
  totalAnalyzedManuscriptContentLength: (state) => state.totalAnalyzedManuscriptContentLength,
  monthlyAnalyzedManuscriptContentLength: (state) => state.monthlyAnalyzedManuscriptContentLength,
};

export default {
  namespaced: true,
  state: {
    manuscriptAnalysisResult: null,
    manuscriptAnalysisList: [],
    totalAnalyzedManuscriptContentLength: 0,
    monthlyAnalyzedManuscriptContentLength: 0,
  },
  getters,
  actions,
  mutations,
};
