
import Vue, { PropType } from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    popup: Object as PropType<Popup>,
  },
  computed: {
    id() {
      const { popup } = this;
      const { id } = popup;
      return id;
    },
    title() {
      const { popup } = this;
      const { title } = popup;
      return title;
    },
    body() {
      const { popup } = this;
      const { body } = popup;
      return body;
    },
    image() {
      const { popup } = this;
      const { image } = popup;
      const { url } = image;
      return url;
    },
    buttonText() {
      const { popup } = this;
      const { buttonText } = popup;
      return buttonText;
    },
    url() {
      const { popup } = this;
      const { url } = popup;
      return url;
    },
    page() {
      const { popup } = this;
      const { page } = popup;
      return page && page[0];
    },
    transition() {
      const { popup } = this;
      const { transition } = popup;
      return transition;
    },
    enabledNavigationButton() {
      const { buttonText, url } = this;
      return !!(buttonText && url);
    },
    isOnlyImage() {
      const { title, body, image, buttonText, url } = this;
      return !!(image && url) && !(title && body && buttonText);
    },
  },
  methods: {
    onPositiveClick() {
      const { url, transition } = this;

      if (transition.includes("newTab")) window.open(url);
      else window.location.href = url!;

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onClickClose() {
      this.$close(true);
    },
  },
});

interface Props {
  popup: Popup;
}

interface Data {}

interface Computed {
  id: string;
  title: string;
  body: string;
  image: string;
  buttonText?: string;
  url?: string;
  page?: string;
  transition: Transition[];
  enabledNavigationButton: boolean;
  isOnlyImage: boolean;
}

interface Methods {
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  onClickClose: () => void;
}

export type PopupDialogProps = Props;

export type Popup = {
  id: string;
  startedAt?: string;
  endedAt?: string;
  title: string;
  body: string;
  image: {
    url: string;
  };
  buttonText?: string;
  url?: string;
  platform: Platform[];
  version?: string;
  target: Target[];
  page: string[];
  trigger: string[];
  type: Type[];
  frequency: Frequency[];
  transition: Transition[];
  operator: Operator[];
  conditions: Condition[];
};

type Platform = "app" | "web";
type Target = "free" | "premium";
type Type = "popup" | "push";
type Frequency = "once" | "every";
type Transition = "newTab" | "currentPage";
type Operator = "AND" | "OR";
type Condition = {
  fieldId: string;
  value: string[];
  min?: number;
  max?: number;
};
