import { WorldViewClient, DisplayOrderClient } from "@/lib/clients";
import {
  WorldView,
  WorldViewFolder,
  SelectWorldViewFolder,
  CreateWorldView,
  UpdateWorldView,
  DeleteWorldView,
  CreateWorldViewFolder,
  UpdateWorldViewFolder,
} from "@/lib/models";
import { Kind, UpdateOrder } from "@/lib/models/sortOrder";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const worldViewClient = new WorldViewClient();
const displayOrderClient = new DisplayOrderClient();

// Stateの型定義
export interface WorldViewState {
  worldViewList: WorldView[];
  worldViewFolderList: WorldViewFolder[];
}

type Getters = {
  worldView(state: WorldViewState): (worldViewId: string) => WorldView | undefined;
  worldViewList(state: WorldViewState): WorldView[];
  worldViewFolder(state: WorldViewState): (folderId: string) => WorldViewFolder | undefined;
  worldViewFolderList(state: WorldViewState): WorldViewFolder[];
  worldViewsInFolder(state: WorldViewState): (folderId: string) => WorldView[];
  foldersBelongWorldView(state: WorldViewState): (worldViewId: string) => WorldViewFolder[];
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<WorldViewState, {}>, payload: string) => Promise<void>;
  selectFolder: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: SelectWorldViewFolder
  ) => Promise<void>;
  updateOrder: (this: Store<{}>, injectee: ActionContext<WorldViewState, {}>, payload: UpdateOrder) => Promise<void>;
  createWorldView: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: CreateWorldView
  ) => Promise<WorldView>;
  updateWorldView: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: UpdateWorldView
  ) => Promise<void>;
  deleteWorldView: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: DeleteWorldView
  ) => Promise<void>;
  createFolder: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: CreateWorldViewFolder
  ) => Promise<void>;
  updateFolder: (
    this: Store<{}>,
    injectee: ActionContext<WorldViewState, {}>,
    payload: UpdateWorldViewFolder
  ) => Promise<void>;
};

const mutations: MutationTree<WorldViewState> = {
  setWorldViewList(state, payload) {
    return (state.worldViewList = payload);
  },
  pushWorldView(state, payload) {
    state.worldViewList.push(payload);
    return state.worldViewList;
  },
  updateWorldView(state, payload: WorldView) {
    return (state.worldViewList = state.worldViewList.map((worldView) => {
      if (worldView.worldViewId === payload.worldViewId) {
        return payload;
      }
      return worldView;
    }));
  },
  popWorldView(state, payload: WorldView) {
    return (state.worldViewList = state.worldViewList.filter(
      (worldView) => worldView.worldViewId !== payload.worldViewId
    ));
  },
  setWorldViewFolderList(state, payload) {
    return (state.worldViewFolderList = payload);
  },
  pushWorldViewFolder(state, payload) {
    state.worldViewFolderList.push(payload);
    return state.worldViewFolderList;
  },
};

const actions: ActionTree<WorldViewState, {}> & Actions = {
  async initialize({ commit }, novelId: string) {
    const worldViewList = await worldViewClient.fetchAllWorldView(novelId);
    commit("setWorldViewList", worldViewList);

    const worldViewFolderList = await worldViewClient.fetchAllWorldViewFolder(novelId);
    commit("setWorldViewFolderList", worldViewFolderList);
  },
  async selectFolder({ commit }, payload) {
    const worldViewList = await worldViewClient.fetchWorldViewsInFolder(payload);
    commit("setWorldViewList", worldViewList);
  },
  async updateOrder(_, payload) {
    await displayOrderClient.updateDisplayOrder({
      ...payload,
      kind: Kind.WORLDVIEW,
    });
  },
  async createWorldView({ commit }, payload) {
    const worldView = await worldViewClient.createWorldView(payload);
    commit("pushWorldView", worldView);
    return worldView;
  },
  async updateWorldView({ commit }, payload) {
    const worldView = await worldViewClient.updateWorldView(payload);
    commit("updateWorldView", worldView);
  },
  async deleteWorldView({ commit }, payload) {
    const worldView = await worldViewClient.deleteWorldView(payload);
    commit("popWorldView", worldView);
  },
  async createFolder({ commit }, payload) {
    const folder = await worldViewClient.createWorldViewFolder(payload);
    commit("pushWorldViewFolder", { ...folder, worldViewKeys: [] });
  },
  async updateFolder({ commit }, payload) {
    const folders = await worldViewClient.updateWorldViewFolder(payload);
    commit("setWorldViewFolderList", folders);
  },
};

const getters: GetterTree<WorldViewState, {}> & Getters = {
  worldView: (state) => (worldViewId: string) => state.worldViewList.find((x) => x.worldViewId === worldViewId),
  worldViewList: (state) => state.worldViewList,
  worldViewFolder: (state) => (folderId: string) =>
    state.worldViewFolderList.find((x) => x.worldViewFolderId === folderId),
  worldViewFolderList: (state) => state.worldViewFolderList,
  worldViewsInFolder: (state) => (folderId: string) => {
    const folder = state.worldViewFolderList.find((x) => x.worldViewFolderId === folderId);
    if (!folder) return [];
    const worldViews = state.worldViewList.filter((x) => folder.worldViewKeys.includes(x.worldViewId));
    return worldViews.sort(
      (x, y) => folder.worldViewKeys.indexOf(x.worldViewId) - folder.worldViewKeys.indexOf(y.worldViewId)
    );
  },
  foldersBelongWorldView: (state) => (worldViewId: string) =>
    state.worldViewFolderList.filter((x) => x.worldViewKeys.includes(worldViewId)),
};

export default {
  namespaced: true,
  state: {
    worldViewList: [],
    worldViewFolderList: [],
  },
  getters,
  actions,
  mutations,
};
