import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { User, ConnectedOtherServices } from "../models";

interface IUserClient {
  fetchUser: () => Promise<User>;
  checkConnectedOtherServices: () => Promise<ConnectedOtherServices>;
  updateUser: (name: string) => Promise<User>;
  deleteUser: (email: string) => Promise<User>;
  startNolaAgentFeature: () => Promise<User>;
}

export class UserClient implements IUserClient {
  async fetchUser(): Promise<User> {
    const { user } = await GraphQLClient.request({ query: fetchUserQuery });
    return user;
  }

  async checkConnectedOtherServices(): Promise<ConnectedOtherServices> {
    const { checkConnectedOtherServices } = await GraphQLClient.request({ query: checkConnectedOtherServicesQuery });
    return checkConnectedOtherServices;
  }

  async updateUser(name: string): Promise<User> {
    const params: RequestParam = {
      query: updateUserQuery,
      variables: {
        input: {
          name,
        },
      },
    };
    const { updateUser } = await GraphQLClient.request(params);
    return updateUser;
  }

  async deleteUser(email: string): Promise<User> {
    const params: RequestParam = {
      query: deleteUserQuery,
      variables: {
        input: {
          delete: true,
          email,
        },
      },
    };
    const { deleteUser } = await GraphQLClient.request(params);
    return deleteUser;
  }

  async startNolaAgentFeature(): Promise<User> {
    const params: RequestParam = {
      query: startNolaAgentFeatureQuery,
      variables: {},
    };
    const { startNolaAgentFeature } = await GraphQLClient.request(params);
    return startNolaAgentFeature;
  }
}

/**
 * Query
 */

const fetchUserQuery = gql`
  {
    user {
      userId
      name
      agentStartedAt
    }
  }
`;

const checkConnectedOtherServicesQuery = gql`
  {
    checkConnectedOtherServices {
      nolaPublishing {
        result
        error
      }
      nolaNovel {
        result
        error
      }
    }
  }
`;

/** Mutation */

const updateUserQuery = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userId
      name
      agentStartedAt
    }
  }
`;

const deleteUserQuery = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      userId
      name
    }
  }
`;

const startNolaAgentFeatureQuery = gql`
  mutation StartNolaAgentFeature {
    startNolaAgentFeature {
      userId
      name
      agentStartedAt
    }
  }
`;
