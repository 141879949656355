import { NolaApiInitializer } from "@/initializer";
import { API } from "aws-amplify";

/** 警告ログ */
export async function warn(message: any) {
  await log({ level: "warn", message });
}

/** エラーログ */
export async function error(message: any) {
  await log({ level: "error", message });
}

/** エラーログ */
async function log(message: any) {
  const init = {
    body: message,
  };

  NolaApiInitializer.init();

  await API.post("GW", "log", init);
}
