import Dialog from "@/components/ui/Dialog.vue";
import YesNoDialog from "@/components/ui/YesNoDialog.vue";
import PresetSelectorDialog from "@/components/ui/PresetSelectorDialog.vue";
import UserDeleteDialog from "@/components/ui/UserDeleteDialog.vue";
import PopupNotification from "@/components/ui/PopupNotification.vue";
import Helper from "@/components/ui/HelperDialog.vue";
import ManuscriptEditorMenuDialog from "@/components/ui/ManuscriptEditorMenuDialog.vue";
import { create, DialogPromise } from "vue-modal-dialogs";

/** OK/キャンセルオプション */
interface OkCancelOptions {
  /** タイトル */
  title?: string;

  /** 本文 */
  content: string;

  /** OKボタン文言 */
  okButton?: string;

  /** キャンセルボタン文言 */
  cancelButton?: string;

  mode?: string;
}

/** 通知オプション */
interface NotifyOptions {
  /** タイトル */
  title?: string;

  /** 本文 */
  content: string;

  /** OKボタン文言 */
  okButton?: string;
}

const notifyDialog = create(Dialog);
let lastDialog: DialogPromise<any> | null = null;

export async function showOkCancelDialog(option: OkCancelOptions) {
  const yesNoDialog = create(YesNoDialog);
  return await openDialog(yesNoDialog, option);
}

export async function showNotifyDialog(options: NotifyOptions) {
  const myOptions = {
    ...options,
    okButton: "閉じる",
    showCancelButton: false,
  };

  return await openDialog(notifyDialog, myOptions);
}

export async function showHelperDialog(options: { title: string; content: string }) {
  const notifyDialog = create(Helper);
  return await notifyDialog(options);
}

export async function openUserDeleteDialog() {
  const notifyDialog = create(UserDeleteDialog);
  return await notifyDialog({});
}

export async function openPresetSelectorDialog() {
  const dlg = create(PresetSelectorDialog);
  return await dlg({});
}

export async function openManuscriptEditorMenuDialog() {
  const notifyDialog = create(ManuscriptEditorMenuDialog);
  return await notifyDialog();
}

const notification = create(PopupNotification);
export async function openPopup(text: string) {
  return await notification({
    content: text,
  });
}

async function openDialog<T>(dialog: (option: T) => DialogPromise<any>, option: T) {
  if (lastDialog) {
    // 以前のダイアログがあった場合は待機
    await lastDialog;
  }

  lastDialog = dialog(option);
  const dialogPromise = await lastDialog;
  lastDialog = null;
  return dialogPromise;
}
