import { Component, create, DialogPromise } from "vue-modal-dialogs";

interface IDialog {
  /**
   * ダイアログを表示する
   */
  show: (options?: object) => void;
}

/**
 * Dialog表示を管理するクラス
 */
export class Dialog<T extends object> implements IDialog {
  component: Component<T, any>;

  execute: (data?: Partial<any> | undefined) => DialogPromise<any>;

  constructor(component: Component<T, any>) {
    this.component = component;
  }

  async show(options?: object) {
    this.execute = create(this.component);
    return await this.execute(options);
  }
}
