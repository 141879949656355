export const Color = {
  /** black */
  black: "#2B2B2B",
  /** pink */
  pink: "#D991C3",
  /** red */
  red: "#DE6C6C",
  /** orange */
  orange: "#EA9846",
  /** yellow */
  yellow: "#DBBD41",
  /** violet */
  violet: "#783AAD",
  /** blue */
  blue: "#608FBE",
  /** cyan */
  cyan: "#64C7D0",
  /** green */
  green: "#59B267",
  /** gray */
  gray: "#909090",
} as const;
// eslint-disable-next-line no-redeclare
export type Color = typeof Color[keyof typeof Color];

export const ColorList: Color[] = [
  Color.black,
  Color.pink,
  Color.red,
  Color.orange,
  Color.yellow,
  Color.violet,
  Color.blue,
  Color.cyan,
  Color.green,
  Color.gray,
];
