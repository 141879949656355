import { SharedLinkClient, NovelClient, ManuscriptClient, PlotClient } from "@/lib/clients";
import { SharedLink, Comment, Novel, Manuscript, OverallPlot, PlotList, SubPlot, SharedLinkKind } from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store, Commit } from "vuex";
import { compareAsc } from "date-fns";
import { db, Comment as CommentDexie } from "@/lib/indexeddb";

const sharedLinkClient = new SharedLinkClient();
const novelClient = new NovelClient();
const manuscriptClient = new ManuscriptClient();
const plotClient = new PlotClient();

// Stateの型定義
export interface SharedLinkState {
  sharedLink: SharedLink;
  sharedLinkList: SharedLink[];
  sharedLinkListBelongOwner: SharedLink[];
  commentList: Comment[];
  commentListBelongOwner: Comment[];
  unreadCommentList: CommentDexie[];
  commentInIndexedDB: CommentDexie[];
  isShowPublishSharedLink: boolean;
  createdSharedLink: SharedLink;
  selectedSharedLinkId: string;
  selectedContentKey: string;
  quoteText: string;
  novel: Novel;
  manuscriptList: Manuscript[];
  plotList: PlotList[];
}

type Actions = {
  initializeSharedLink: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: GetSharedLinkPayload
  ) => any;
  initializeSharedLinkList: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: string) => any;
  createSharedLink: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: CreateSharedLinkPayload
  ) => any;
  updateSharedLink: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: UpdateSharedLinkPayload
  ) => any;
  deleteSharedLink: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: DeleteSharedLinkPayload
  ) => any;
  initializeSharedLinkListBelongOwner: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  initializeCommentList: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: string) => any;
  createComment: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: CreateCommentPayload) => any;
  updateComment: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: UpdateCommentPayload) => any;
  deleteComment: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: DeleteCommentPayload) => any;
  initializeCommentListBelongOwner: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: string
  ) => any;
  createCommentBelongOwner: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: CreateCommentPayload
  ) => any;
  updateCommentBelongOwner: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: UpdateCommentPayload
  ) => any;
  deleteCommentBelongOwner: (
    this: Store<{}>,
    injectee: ActionContext<SharedLinkState, {}>,
    payload: DeleteCommentPayload
  ) => any;
  readComment: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: CommentDexie[]) => any;
  showPublishSharedLink: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  closePublishSharedLink: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  onClickQuote: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: string) => any;
  initializeQuote: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  initializeCreatedSharedLink: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  selectSharedLink: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: string) => any;
  initializeSelectedSharedLink: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
  selectContent: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>, payload: string) => any;
  initializeSelectedContent: (this: Store<{}>, injectee: ActionContext<SharedLinkState, {}>) => any;
};

interface GetSharedLinkPayload {
  sharedLinkId: string;
  novelId: string;
}

interface CreateSharedLinkPayload {
  novelId: string;
  name: string;
  manuscriptKeys: string[];
  plotIds: string[];
  callback: () => void;
}

interface UpdateSharedLinkPayload {
  novelId: string;
  sharedLinkId: string;
  name: string;
  manuscriptKeys?: string[];
  plotIds?: string[];
  enabled: boolean;
  callback: () => void;
}

interface DeleteSharedLinkPayload {
  novelId: string;
  sharedLinkId: string;
  callback: () => void;
}

interface CreateCommentPayload {
  sharedLinkId: string;
  manuscriptKey?: string;
  plotId?: string;
  novelId: string;
  name: string;
  comment: string;
  callback: () => void;
}

interface UpdateCommentPayload {
  sharedLinkId: string;
  commentId: string;
  manuscriptKey: string;
  novelId: string;
  name: string;
  comment: string;
  callback: () => void;
}

interface DeleteCommentPayload {
  sharedLinkId: string;
  commentId: string;
  callback: () => void;
}

type Getters = {
  sharedLink(state: SharedLinkState): SharedLink;
  sharedLinkList(state: SharedLinkState): SharedLink[];
  sharedLinkListBelongOwner(state: SharedLinkState): SharedLink[];
  commentList(state: SharedLinkState): Comment[];
  commentListBelongOwner(state: SharedLinkState): (sharedLinkId: string) => Comment[];
  unreadCommentList(
    state: SharedLinkState
  ): (userId: string, novelId: string, kind: string, sharedLinkId?: string, contentKey?: string) => CommentDexie[];
  isShowPublishSharedLink(state: SharedLinkState): boolean;
  createdSharedLink(state: SharedLinkState): SharedLink;
  quoteText(state: SharedLinkState): string;
  selectedSharedLinkId(state: SharedLinkState): string;
  selectedContentKey(state: SharedLinkState): string;
  novel(state: SharedLinkState): Novel;
  manuscriptList(state: SharedLinkState): Manuscript[];
  plotList(state: SharedLinkState): PlotList[];
};

const mutations: MutationTree<SharedLinkState> = {
  setSharedLink(state, payload) {
    return (state.sharedLink = payload);
  },
  setSharedLinkList(state, payload) {
    return (state.sharedLinkList = payload);
  },
  pushSharedLink(state, payload) {
    state.sharedLinkList.push(payload);
    return state.sharedLinkList;
  },
  setCreatedSharedLink(state, payload) {
    return (state.createdSharedLink = payload);
  },
  updateSharedLink(state, payload: SharedLink) {
    return (state.sharedLinkList = state.sharedLinkList.map((sharedLink) => {
      if (sharedLink.sharedLinkId === payload.sharedLinkId) {
        return payload;
      }
      return sharedLink;
    }));
  },
  popSharedLink(state, payload) {
    const sharedLink = state.sharedLinkList.filter((sharedLink) => sharedLink.sharedLinkId !== payload);
    return (state.sharedLinkList = sharedLink);
  },
  setSharedLinkListBelongOwner(state, payload) {
    return (state.sharedLinkListBelongOwner = payload);
  },
  setCommentList(state, payload) {
    return (state.commentList = payload);
  },
  pushComment(state, payload) {
    state.commentList.push(payload);
    return state.commentList;
  },
  updateComment(state, payload: Comment) {
    return (state.commentList = state.commentList.map((comment) => {
      if (comment.commentId === payload.commentId) {
        return payload;
      }
      return comment;
    }));
  },
  popComment(state, payload) {
    const comment = state.commentList.filter((comment) => comment.commentId !== payload);
    return (state.commentList = comment);
  },
  setCommentListBelongOwner(state, payload: Comment[]) {
    return (state.commentListBelongOwner = payload);
  },
  pushCommentBelongOwner(state, payload: Comment) {
    state.commentListBelongOwner.push(payload);
    return state.commentListBelongOwner;
  },
  updateCommentBelongOwner(state, payload: Comment) {
    return (state.commentListBelongOwner = state.commentListBelongOwner.map((comment) => {
      if (comment.commentId === payload.commentId) {
        return payload;
      }
      return comment;
    }));
  },
  popCommentBelongOwner(state, payload) {
    const comment = state.commentListBelongOwner.filter((comment) => comment.commentId !== payload);
    return (state.commentListBelongOwner = comment);
  },
  setCommentInIndexedDB(state, payload: CommentDexie[]) {
    return (state.commentInIndexedDB = payload);
  },
  setIsShowPublishSharedLink(state, payload) {
    state.isShowPublishSharedLink = payload;
  },
  setQuoteText(state, payload) {
    state.quoteText = payload;
  },
  setSelectedSharedLinkId(state, payload) {
    state.selectedSharedLinkId = payload;
  },
  setSelectedContentKey(state, payload) {
    state.selectedContentKey = payload;
  },
  setNovel(state, payload) {
    return (state.novel = payload);
  },
  setManuscript(state, payload) {
    return (state.manuscriptList = payload);
  },
  setPlotList(state, payload) {
    return (state.plotList = payload);
  },
};

const actions: ActionTree<SharedLinkState, {}> & Actions = {
  async initializeSharedLink({ commit }, payload: GetSharedLinkPayload) {
    const sharedLink = await sharedLinkClient.fetchSharedLink(payload);
    commit("setSharedLink", sharedLink);

    if (!sharedLink) return;

    const { userId, novelId } = sharedLink;
    const novel = await novelClient.fetchNovelByExternalUser(userId!, novelId);
    commit("setNovel", novel);

    if (sharedLink.manuscriptKeys) {
      const manuscriptList = await manuscriptClient.fetchAllManuscriptByExternalUser(userId!, novelId);
      // sharedLink.manuscriptKeysの順序でmanuscriptListから要素を取り出し、新しい配列を作成
      const availableManuscriptList = sharedLink.manuscriptKeys
        .map((key) => manuscriptList.find((manuscript) => manuscript.key === key))
        .filter((manuscript) => manuscript !== undefined);

      commit("setManuscript", availableManuscriptList);
    }

    if (sharedLink.plotIds) {
      generatePlotList(commit, sharedLink, userId!, novelId);
    }
  },
  async initializeSharedLinkList({ commit }, novelId: string) {
    const sharedLinkList = await sharedLinkClient.fetchAllSharedLink(novelId);

    commit("setSharedLinkList", sharedLinkList);
  },
  async createSharedLink({ commit }, payload: CreateSharedLinkPayload) {
    const { callback } = payload;
    const sharedLink: SharedLink = await sharedLinkClient.createSharedLink(payload);
    if (sharedLink) {
      commit("pushSharedLink", sharedLink);
      commit("setCreatedSharedLink", sharedLink);
      if (callback) callback();
    }
  },
  async updateSharedLink({ commit }, payload: UpdateSharedLinkPayload) {
    const { callback } = payload;
    const sharedLink = await sharedLinkClient.updateSharedLink(payload);
    if (sharedLink) {
      commit("updateSharedLink", sharedLink);
      if (callback) callback();
    }
  },
  async deleteSharedLink({ commit }, payload: DeleteSharedLinkPayload) {
    const { callback } = payload;
    const sharedLink = await sharedLinkClient.deleteSharedLink(payload);
    if (sharedLink) {
      commit("popSharedLink", sharedLink.sharedLinkId);
      if (callback) callback();
    }
  },
  async initializeSharedLinkListBelongOwner({ commit }) {
    const sharedLinkListBelongOwner = await sharedLinkClient.fetchAllSharedLinkBelongOwner();
    commit("setSharedLinkListBelongOwner", sharedLinkListBelongOwner);
  },
  async initializeCommentList({ commit }, sharedLinkId: string) {
    const commentList: Comment[] = await sharedLinkClient.fetchAllComment(sharedLinkId);
    commentList.sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
    commit("setCommentList", commentList);
  },
  async createComment({ commit }, payload: CreateCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.createComment(payload);
    if (comment) {
      commit("pushComment", comment);
      if (callback) callback();
    }
  },
  async updateComment({ commit }, payload: UpdateCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.updateComment(payload);
    if (comment) {
      commit("updateComment", comment);
      if (callback) callback();
    }
  },
  async deleteComment({ commit }, payload: DeleteCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.deleteComment(payload);
    if (comment) {
      commit("popComment", comment.commentId);
      if (callback) callback();
    }
  },
  async initializeCommentListBelongOwner({ commit }, novelId: string) {
    const commentListBelongOwner: Comment[] = await sharedLinkClient.fetchAllCommentBelongOwner(novelId);
    commentListBelongOwner.sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
    commit("setCommentListBelongOwner", commentListBelongOwner);

    /** IndexedDBに保存されているコメント */
    const commentInIndexedDB = await db.transaction("readonly", db.comment, async () => await db.comment.toArray());
    /** 新着コメント */
    const newCommentList = commentListBelongOwner.filter(
      (comment) => !commentInIndexedDB.some((x) => x.commentId === comment.commentId)
    );

    /** 新着コメントをIndexedDBに書き込む */
    await Promise.all(
      newCommentList.map(
        async (comment) =>
          await db.transaction("readwrite", db.comment, async () => {
            const { manuscriptKey, plotId, sharedLinkId, commentId, userId } = comment;
            await db.comment.add(new CommentDexie(manuscriptKey!, plotId!, sharedLinkId, commentId!, userId!, novelId));
          })
      )
    );

    /** 更新後のIndexedDBに保存されているコメント */
    const updatedCommentInIndexedDB = await db.transaction(
      "readonly",
      db.comment,
      async () => await db.comment.toArray()
    );
    /** コメントをstoreにセットする */
    commit("setCommentInIndexedDB", updatedCommentInIndexedDB);
  },
  async createCommentBelongOwner({ commit }, payload: CreateCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.createComment(payload);
    if (comment) {
      commit("pushCommentBelongOwner", comment);
      if (callback) callback();
    }
  },
  async updateCommentBelongOwner({ commit }, payload: UpdateCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.updateComment(payload);
    if (comment) {
      commit("updateCommentBelongOwner", comment);
      if (callback) callback();
    }
  },
  async deleteCommentBelongOwner({ commit }, payload: DeleteCommentPayload) {
    const { callback } = payload;
    const comment: Comment = await sharedLinkClient.deleteComment(payload);
    if (comment) {
      commit("popCommentBelongOwner", comment.commentId);
      if (callback) callback();
    }
  },
  async readComment({ commit }, payload: CommentDexie[]) {
    /** 対象コメントを既読にする */
    await Promise.all(
      payload.map(
        async (comment) =>
          await db.transaction("readwrite", db.comment, async () => {
            const { commentId } = comment;
            await db.comment.update(commentId, { alreadyRead: true });
          })
      )
    );

    /** 更新後のIndexedDBに保存されているコメント */
    const updatedCommentInIndexedDB = await db.transaction(
      "readonly",
      db.comment,
      async () => await db.comment.toArray()
    );
    /** コメントをstoreにセットする */
    commit("setCommentInIndexedDB", updatedCommentInIndexedDB);
  },
  showPublishSharedLink({ commit }) {
    commit("setIsShowPublishSharedLink", true);
  },
  closePublishSharedLink({ commit }) {
    commit("setIsShowPublishSharedLink", false);
  },
  onClickQuote({ commit }, text) {
    commit("setQuoteText", text);
  },
  initializeQuote({ commit }) {
    commit("setQuoteText", "");
  },
  initializeCreatedSharedLink({ commit }) {
    commit("setCreatedSharedLink", "");
  },
  selectSharedLink({ commit }, sharedLinkId) {
    commit("setSelectedSharedLinkId", sharedLinkId);
  },
  initializeSelectedSharedLink({ commit }) {
    commit("setSelectedSharedLinkId", "");
  },
  selectContent({ commit }, contentKey) {
    commit("setSelectedContentKey", contentKey);
  },
  initializeSelectedContent({ commit }) {
    commit("setSelectedContentKey", "");
  },
};

const getters: GetterTree<SharedLinkState, {}> & Getters = {
  sharedLink: (state) => state.sharedLink,
  sharedLinkList: (state) => state.sharedLinkList,
  sharedLinkListBelongOwner: (state) => state.sharedLinkListBelongOwner,
  commentList: (state) => state.commentList,
  commentListBelongOwner: (state) => (sharedLinkId) =>
    state.commentListBelongOwner.filter((comment) => comment.sharedLinkId === sharedLinkId),
  commentInIndexedDB: (state) => state.commentInIndexedDB,
  unreadCommentList: (state) => (userId, novelId, kind, sharedLinkId, contentKey) => {
    let filteredComments = state.commentInIndexedDB.filter(
      (comment) => comment.novelId === novelId && comment.alreadyRead === false && comment.userId !== userId
    );

    if (sharedLinkId) {
      filteredComments = filteredComments.filter((comment) => comment.sharedLinkId === sharedLinkId);
    }

    switch (kind) {
      case SharedLinkKind.MANUSCRIPT:
        filteredComments = contentKey
          ? filteredComments.filter((comment) => comment.manuscriptKey === contentKey)
          : filteredComments.filter((comment) => comment.manuscriptKey);
        break;
      case SharedLinkKind.PLOT:
        filteredComments = contentKey
          ? filteredComments.filter((comment) => comment.plotId === contentKey)
          : filteredComments.filter((comment) => comment.plotId);
        break;
      default:
        return [];
    }

    return filteredComments;
  },
  sharedLinkListWithEnabled: (state) => state.sharedLinkList.filter((sharedLink) => sharedLink.enabled === true),
  isShowPublishSharedLink: (state) => state.isShowPublishSharedLink,
  createdSharedLink: (state) => state.createdSharedLink,
  quoteText: (state) => state.quoteText,
  selectedSharedLinkId: (state) => state.selectedSharedLinkId,
  selectedContentKey: (state) => state.selectedContentKey,
  novel: (state) => state.novel,
  manuscriptList: (state) => state.manuscriptList,
  plotList: (state) => state.plotList,
};

async function generatePlotList(commit: Commit, sharedLink: SharedLink, userId: string, novelId: string) {
  const plots = await plotClient.fetchPlotsByExternalUser({ userId: userId!, novelId });
  const subPlots = await plotClient.fetchSubPlotsByExternalUser({ userId: userId!, novelId });

  // 全体プロットはソートキー「novelId | pl」のレコードではplotId属性を持っていない。
  // 識別・画面表示用に共有リンク作成時に"overall-plot-"のプレフィックスを付与して保存している為
  // プレフィックスがついているplotIdが共有リンクレコードに存在すれば、オーナーから共有された全体プロットとみなして、外部ユーザーに公開する。
  const overallPlotIdPrefix = "overall-plot-";
  let overallPlotId: string | undefined;
  if (sharedLink.plotIds && sharedLink.plotIds.length > 0) {
    overallPlotId = sharedLink.plotIds.find((id) => id.startsWith(overallPlotIdPrefix));
  }

  let overallPlot: OverallPlot | undefined;
  if (overallPlotId) {
    overallPlot = {
      name: "全体プロット",
      plotId: overallPlotId,
      ...plots,
    };
  }

  const plotIds = sharedLink.plotIds || [];
  const availablePlotList: PlotList = plotIds
    .map((plotId) => {
      if (plotId === overallPlotId) {
        return overallPlot;
      }
      return subPlots.find((subPlot) => subPlot.plotId === plotId);
    })
    .filter((plot): plot is OverallPlot | SubPlot => plot !== undefined);

  commit("setPlotList", availablePlotList);
}

export default {
  namespaced: true,
  state: {
    sharedLink: "",
    sharedLinkList: [],
    sharedLinkListBelongOwner: [],
    commentList: [],
    commentListBelongOwner: [],
    commentInIndexedDB: [],
    unreadCommentList: [],
    isShowPublishSharedLink: false,
    createdSharedLink: "",
    quoteText: "",
    selectedSharedLinkId: "",
    selectedContentKey: "",
    novel: "",
    manuscriptList: [],
    plotList: [],
  },
  getters,
  actions,
  mutations,
};
