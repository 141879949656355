import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { WritingData, WritingDataGetInput, WritingDataListInput } from "../models/writingData";

interface IWritingDataClient {
  writingCount: (args: WritingDataGetInput) => Promise<WritingData>;
  writingCountList: (args: WritingDataListInput) => Promise<WritingData[]>;
}

export class WritingDataClient implements IWritingDataClient {
  async writingCount({ duration, date, novelId }: WritingDataGetInput): Promise<WritingData> {
    const params: RequestParam = {
      query: writingCountQuery,
      variables: {
        input: {
          duration,
          date,
          novelId,
        },
      },
    };

    const { writingCount } = await GraphQLClient.request(params);
    return writingCount;
  }

  async writingCountList({ duration, startDate, endDate, novelId }: WritingDataListInput): Promise<WritingData[]> {
    const params: RequestParam = {
      query: writingCountListQuery,
      variables: {
        input: {
          duration,
          startDate,
          endDate,
          novelId,
        },
      },
    };

    const { writingCountList } = await GraphQLClient.request(params);
    return writingCountList.items;
  }
}

/**
 * Mutation
 */

const writingCountQuery = gql`
  query WritingCount($input: WritingDataGetInput!) {
    writingCount(input: $input) {
      userId
      duration
      date
      novelId
      analyticsValue
      createdAt
      updatedAt
    }
  }
`;

const writingCountListQuery = gql`
  query WritingCountList($input: WritingDataListInput!) {
    writingCountList(input: $input) {
      items {
        userId
        duration
        date
        novelId
        analyticsValue
        createdAt
        updatedAt
      }
    }
  }
`;
