import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import {
  ManuscriptAnalysis,
  ManuscriptAnalysisList,
  GetManuscriptAnalysisInput,
  CreateManuscriptAnalysisInput,
} from "../models";

interface IManuscriptAnalysisClient {
  fetchManuscriptAnalysis: (args: GetManuscriptAnalysisInput) => Promise<ManuscriptAnalysis>;
  fetchManuscriptAnalysisList: (novelId: string) => Promise<ManuscriptAnalysisList>;
  fetchTotalAnalyzedManuscriptContentLength: () => Promise<number>;
  fetchMonthlyAnalyzedManuscriptContentLength: () => Promise<number>;
  createManuscriptAnalysis: (args: CreateManuscriptAnalysisInput) => Promise<ManuscriptAnalysis>;
}

export class ManuscriptAnalysisClient implements IManuscriptAnalysisClient {
  async fetchManuscriptAnalysis(input: GetManuscriptAnalysisInput): Promise<ManuscriptAnalysis> {
    const params: RequestParam = {
      query: fetchManuscriptAnalysis,
      variables: { input },
    };

    const { manuscriptAnalysis } = await GraphQLClient.request(params);
    return manuscriptAnalysis;
  }

  async fetchManuscriptAnalysisList(novelId: string): Promise<ManuscriptAnalysisList> {
    const params: RequestParam = {
      query: fetchManuscriptAnalysisList,
      variables: {
        novelId,
      },
    };

    const { manuscriptAnalysisList } = await GraphQLClient.request(params);
    return manuscriptAnalysisList.items;
  }

  async fetchTotalAnalyzedManuscriptContentLength(): Promise<number> {
    const { countTotalContentLengthForManuscriptAnalysis } = await GraphQLClient.request({
      query: fetchTotalAnalyzedManuscriptContentLength,
    });
    return countTotalContentLengthForManuscriptAnalysis;
  }

  async fetchMonthlyAnalyzedManuscriptContentLength(): Promise<number> {
    const { countMonthlyContentLengthForManuscriptAnalysis } = await GraphQLClient.request({
      query: fetchMonthlyAnalyzedManuscriptContentLength,
    });
    return countMonthlyContentLengthForManuscriptAnalysis;
  }

  async createManuscriptAnalysis(input: CreateManuscriptAnalysisInput): Promise<ManuscriptAnalysis> {
    const params: RequestParam = {
      query: createManuscriptAnalysisQuery,
      variables: {
        input,
      },
    };

    const { createManuscriptAnalysis } = await GraphQLClient.request(params);
    return createManuscriptAnalysis;
  }
}

/**
 * Query
 */

const fetchManuscriptAnalysis = gql`
  query ManuscriptAnalysis($input: GetManuscriptAnalysisInput!) {
    manuscriptAnalysis(input: $input) {
      userId
      novelId
      manuscriptAnalysisId
      manuscriptKeys
      manuscriptAnalysisResult
      completed
      isError
      createdAt
      updatedAt
    }
  }
`;

const fetchManuscriptAnalysisList = gql`
  query ManuscriptAnalysisList($novelId: ID!) {
    manuscriptAnalysisList(novelId: $novelId) {
      items {
        userId
        novelId
        manuscriptAnalysisId
        manuscriptKeys
        manuscriptAnalysisResult
        completed
        isError
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchTotalAnalyzedManuscriptContentLength = gql`
  query CountTotalContentLengthForManuscriptAnalysis {
    countTotalContentLengthForManuscriptAnalysis
  }
`;

const fetchMonthlyAnalyzedManuscriptContentLength = gql`
  query CountMonthlyContentLengthForManuscriptAnalysis {
    countMonthlyContentLengthForManuscriptAnalysis
  }
`;

/**
 * Mutation
 */

const createManuscriptAnalysisQuery = gql`
  mutation CreateManuscriptAnalysis($input: CreateManuscriptAnalysisInput!) {
    createManuscriptAnalysis(input: $input) {
      userId
      novelId
      manuscriptAnalysisId
      manuscriptKeys
      manuscriptAnalysisResult
      completed
      isError
      createdAt
      updatedAt
    }
  }
`;
