import { v4 as uuidv4 } from "uuid";

export enum LayoutEnum {
  DICTIONARY = "dictionary-layout",
  RESUME = "resume-layout",
  ENLARGEMENT = "enlargement-layout",
}

/**
 * 資料要素の種別
 */
export enum MaterialItemEnum {
  IMAGE_SQUARE = "image-square",
  IMAGE_PORTRATE = "image-portrait",
  IMAGE_LANDSCAPE = "image-landscape",
  TEXT_MULTILINE = "multiline-text",
  TEXT_SINGLELINE = "singleline-text",
}

/**
 * 資料に含まれる各要素
 */
export interface MaterialItem {
  id?: string;
  type: MaterialItemEnum;
  isRequired: boolean;
  ratioX?: number;
  ratioY?: number;
  title?: string;
  value?: string;
  imagePath?: string;
}

export function createNewItem(type: MaterialItemEnum, isRequired: boolean): MaterialItem {
  return {
    id: uuidv4(),
    type,
    isRequired,
  };
}

/**
 * 資料テンプレート
 */
export interface MaterialInputTemplate {
  id: string;
  name: string;
  layout: LayoutEnum;
  items: MaterialItem[];
  createdAt?: string;
  updatedAt?: string;
}

/**
 * 作品資料
 */
export interface NovelMaterial {
  id: string;
  novelId: string;
  attributeId: string;
  layout: LayoutEnum;
  items: MaterialItem[];
  createdAt?: string;
  updatedAt?: string;
}

/**
 * 資料種別
 */
export interface MaterialAttribute {
  id: string;
  novelId: string;
  name: string;
  materialOrder: string[];
  folderOrder: string[];
  createdAt?: string;
  updatedAt?: string;
}

/**
 * 資料のフォルダ
 */
export interface MaterialFolder {
  id: string;
  novelId: string;
  attributeId: string;
  name: string;
  materialOrder: string[];
  createdAt?: string;
  updatedAt?: string;
}

/**
 * 最近開いた資料ID
 */
export interface RecentMaterialId {
  attributeId: string;
  materialId: string;
}
