import { Color } from "../colorPicker";

/**
 * Data Model
 */
export interface Timeline {
  novelId: string;
  timelineId: string;
  timelineType: TimelineType;
  name: string;
  tableHeaders?: TableHeader[];
  tableBodies: TableBody[];

  createdAt?: string;
  updatedAt?: string;
}

export interface TableHeader {
  id: string;
  name: string;
}

export interface TableBody {
  id: string;
  kind: string | Kind;
  items: BodyItem[];
}

export interface BodyItem {
  key: string;
  value: string;
  color: Color;
}

export const TimelineType = {
  /** 年表 */
  chronology: "chronology",
  /** プロット */
  plot: "plot",
} as const;
// eslint-disable-next-line no-redeclare
export type TimelineType = typeof TimelineType[keyof typeof TimelineType];

export const Kind = {
  /** ストーリー */
  story: "story",
  /** イベント */
  event: "event",
  /** 伏線 */
  foreshadowing: "foreshadowing",
  /** 伏線回収 */
  payoffForeshadowing: "payoffForeshadowing",
  /** 登場人物 */
  character: "character",
  /** 資料 */
  material: "material",
  /** 相関図 */
  correlation: "correlation",
  /** 感情 */
  emotional: "emotional",
  /** 巻数 */
  volume: "volume",
  /** 話数 */
  episode: "episode",
  /** ページ数 */
  page: "page",
  /** タイトル */
  title: "title",
  /** 備考 */
  remarks: "remarks",
} as const;
// eslint-disable-next-line no-redeclare
export type Kind = typeof Kind[keyof typeof Kind];

/**
 * Request Model
 */
export interface GetTimelinePayload {
  novelId: string;
  timelineId: string;
}

export interface ListTimelinePayload {
  novelId: string;
}

export interface CreateTimelinePayload {
  novelId: string;
  timelineType: TimelineType;
  name: string;
  tableHeaders?: TableHeader[];
  tableBodies: TableBody[];
  callback?: (timelineId: string) => void;
}

export interface UpdateTimelinePayload {
  novelId: string;
  timelineId: string;
  timelineType: TimelineType;
  name: string;
  tableHeaders?: TableHeader[];
  tableBodies: TableBody[];
  callback?: () => void;
}

export interface DeleteTimelinePayload {
  novelId: string;
  timelineId: string;
  callback?: () => void;
}

export interface SyncTimelinePayload {
  timelineId: string;
}
