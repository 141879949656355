import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { SharedLink, Comment } from "../models";

interface ISharedLinkClient {
  /**
   * 共有リンクの取得
   */
  fetchSharedLink: (sharedLinkInput: SharedLink) => Promise<SharedLink>;

  /**
   * 共有リンク一覧の取得
   */
  fetchAllSharedLink: (novelId: string) => Promise<SharedLink[]>;

  /**
   * ユーザーに属する共有リンク一覧の取得
   */
  fetchAllSharedLinkBelongOwner: () => Promise<SharedLink[]>;

  /**
   * コメント一覧の取得
   */
  fetchAllComment: (sharedLinkId: string) => Promise<Comment[]>;

  /**
   * オーナーに属するコメント一覧の取得
   */
  fetchAllCommentBelongOwner: (sharedLinkId: string) => Promise<Comment[]>;

  /**
   * 共有リンクの作成
   */
  createSharedLink: (sharedLinkInput: SharedLink) => Promise<SharedLink>;

  /**
   * 共有リンクの更新
   */
  updateSharedLink: (sharedLinkInput: SharedLink) => Promise<SharedLink>;

  /**
   * 共有リンクの削除
   */
  deleteSharedLink: (sharedLinkInput: SharedLink) => Promise<SharedLink>;

  /**
   * コメントの作成
   */
  createComment: (commentInput: Comment) => Promise<Comment>;

  /**
   * コメントの更新
   */
  updateComment: (commentInput: Comment) => Promise<Comment>;

  /**
   * コメントの削除
   */
  deleteComment: (commentInput: Comment) => Promise<Comment>;
}

export class SharedLinkClient implements ISharedLinkClient {
  async fetchSharedLink(sharedLinkInput: SharedLink): Promise<SharedLink> {
    const { novelId, sharedLinkId } = sharedLinkInput;
    const params: RequestParam = {
      query: fetchSharedLinkQuery,
      variables: {
        input: {
          novelId,
          sharedLinkId,
        },
      },
    };

    const { sharedLink } = await GraphQLClient.request(params);
    return sharedLink;
  }

  async fetchAllSharedLink(novelId: string): Promise<SharedLink[]> {
    const params: RequestParam = {
      query: fetchAllSharedLinkQuery,
      variables: {
        novelId,
      },
    };

    const { sharedLinkList } = await GraphQLClient.request(params);
    return sharedLinkList.items;
  }

  async fetchAllSharedLinkBelongOwner(): Promise<SharedLink[]> {
    const { allSharedLinkList } = await GraphQLClient.request({ query: fetchAllSharedLinkBelongOwnerQuery });
    return allSharedLinkList.items;
  }

  async fetchAllComment(sharedLinkId: string): Promise<Comment[]> {
    const params: RequestParam = {
      query: fetchAllCommentQuery,
      variables: {
        sharedLinkId,
      },
    };

    const { commentList } = await GraphQLClient.request(params);
    return commentList.items;
  }

  async fetchAllCommentBelongOwner(novelId: string): Promise<Comment[]> {
    const params: RequestParam = {
      query: fetchAllCommentBelongOwnerQuery,
      variables: {
        novelId,
      },
    };

    const { commentListBelongOwner } = await GraphQLClient.request(params);
    return commentListBelongOwner.items;
  }

  async createSharedLink(sharedLinkInput: SharedLink) {
    const { novelId, name, manuscriptKeys, plotIds } = sharedLinkInput;

    const createObject = {
      novelId,
      name,
      manuscriptKeys,
      plotIds,
    };

    const params: RequestParam = {
      query: createSharedLinkQuery,
      variables: {
        create: createObject,
      },
    };

    const { createSharedLink } = await GraphQLClient.request(params);
    return createSharedLink;
  }

  async updateSharedLink(sharedLinkInput: SharedLink) {
    const { novelId, sharedLinkId, name, manuscriptKeys, plotIds, enabled } = sharedLinkInput;

    const updateObject = {
      novelId,
      sharedLinkId,
      name,
      manuscriptKeys,
      plotIds,
      enabled,
    };

    const params: RequestParam = {
      query: updateSharedLinkQuery,
      variables: {
        update: updateObject,
      },
    };

    const { updateSharedLink } = await GraphQLClient.request(params);
    return updateSharedLink;
  }

  async deleteSharedLink(sharedLinkInput: SharedLink) {
    const { novelId, sharedLinkId } = sharedLinkInput;
    const params: RequestParam = {
      query: deleteSharedLinkQuery,
      variables: {
        delete: {
          novelId,
          sharedLinkId,
        },
      },
    };

    const { deleteSharedLink } = await GraphQLClient.request(params);
    return deleteSharedLink;
  }

  async createComment(commentInput: Comment) {
    const { sharedLinkId, manuscriptKey, plotId, novelId, name, comment } = commentInput;
    const params: RequestParam = {
      query: createCommentQuery,
      variables: {
        create: {
          sharedLinkId,
          manuscriptKey,
          plotId,
          novelId,
          name,
          comment,
        },
      },
    };

    const { createComment } = await GraphQLClient.request(params);
    return createComment;
  }

  async updateComment(commentInput: Comment) {
    const { sharedLinkId, commentId, manuscriptKey, plotId, novelId, name, comment } = commentInput;
    const params: RequestParam = {
      query: updateCommentQuery,
      variables: {
        update: {
          sharedLinkId,
          commentId,
          manuscriptKey,
          plotId,
          novelId,
          name,
          comment,
        },
      },
    };

    const { updateComment } = await GraphQLClient.request(params);
    return updateComment;
  }

  async deleteComment(commentInput: Comment) {
    const { sharedLinkId, commentId } = commentInput;
    const params: RequestParam = {
      query: deleteCommentQuery,
      variables: {
        delete: {
          sharedLinkId,
          commentId,
        },
      },
    };

    const { deleteComment } = await GraphQLClient.request(params);
    return deleteComment;
  }
}

/**
 * Query
 */

const fetchSharedLinkQuery = gql`
  query SharedLink($input: GetSharedLinkInput!) {
    sharedLink(input: $input) {
      userId
      novelId
      sharedLinkId
      name
      manuscriptKeys
      plotIds
      enabled
      createdAt
      updatedAt
    }
  }
`;

const fetchAllSharedLinkQuery = gql`
  query SharedLinkList($novelId: ID!) {
    sharedLinkList(novelId: $novelId) {
      items {
        novelId
        sharedLinkId
        name
        manuscriptKeys
        plotIds
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchAllSharedLinkBelongOwnerQuery = gql`
  {
    allSharedLinkList {
      items {
        novelId
        sharedLinkId
        name
        manuscriptKeys
        plotIds
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchAllCommentQuery = gql`
  query CommentList($sharedLinkId: ID!) {
    commentList(sharedLinkId: $sharedLinkId) {
      items {
        userId
        sharedLinkId
        commentId
        manuscriptKey
        plotId
        novelId
        name
        comment
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchAllCommentBelongOwnerQuery = gql`
  query CommentListBelongOwner($novelId: ID!) {
    commentListBelongOwner(novelId: $novelId) {
      items {
        userId
        sharedLinkId
        commentId
        manuscriptKey
        plotId
        novelId
        name
        comment
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * Mutation
 */

const createSharedLinkQuery = gql`
  mutation CreateSharedLink($create: CreateSharedLinkInput!) {
    createSharedLink(input: $create) {
      novelId
      sharedLinkId
      name
      manuscriptKeys
      plotIds
      enabled
      createdAt
      updatedAt
    }
  }
`;

const updateSharedLinkQuery = gql`
  mutation UpdateSharedLink($update: UpdateSharedLinkInput!) {
    updateSharedLink(input: $update) {
      novelId
      sharedLinkId
      name
      manuscriptKeys
      plotIds
      enabled
      createdAt
      updatedAt
    }
  }
`;

const deleteSharedLinkQuery = gql`
  mutation DeleteSharedLink($delete: DeleteSharedLinkInput!) {
    deleteSharedLink(input: $delete) {
      novelId
      sharedLinkId
      name
      manuscriptKeys
      plotIds
      enabled
      createdAt
      updatedAt
    }
  }
`;

const createCommentQuery = gql`
  mutation CreateComment($create: CreateCommentInput!) {
    createComment(input: $create) {
      userId
      sharedLinkId
      commentId
      manuscriptKey
      plotId
      novelId
      name
      comment
      createdAt
      updatedAt
    }
  }
`;

const updateCommentQuery = gql`
  mutation UpdateComment($update: UpdateCommentInput!) {
    updateComment(input: $update) {
      userId
      sharedLinkId
      commentId
      manuscriptKey
      plotId
      novelId
      name
      comment
      createdAt
      updatedAt
    }
  }
`;

const deleteCommentQuery = gql`
  mutation DeleteComment($delete: DeleteCommentInput!) {
    deleteComment(input: $delete) {
      userId
      sharedLinkId
      commentId
      manuscriptKey
      plotId
      novelId
      name
      comment
      createdAt
      updatedAt
    }
  }
`;
