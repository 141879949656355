import Storage from "@aws-amplify/storage";
import JSZip from "jszip";
import { Memo } from "../models";

interface IMemoStorage {
  /**
   * メモのtextファイルを保存する
   */
  uploadFile: (id: string, content: string) => Promise<string>;

  /**
   * メモのtextファイルを読み込む
   */
  downloadFile: (memo: Memo) => Promise<string>;
}

/**
 * 今はs3.tsに集約されてるっぽいけど
 * Storageに関するBaseクラス一つ用意してもいいかも
 */
export class MemoStorage implements IMemoStorage {
  async uploadFile(id: string, content: string) {
    const key = `memos/${id}.zip`;
    const binary = await this.zip(content);
    (await Storage.vault.put(key, binary)) as { key: string };
    const result = await Storage.vault.get(key, { download: false });
    if (typeof result === "string") {
      // 署名がついた全部のURLはいらないのでパラメータ以前のものを返す
      return result.split("?")[0];
    }

    throw result;
  }

  async downloadFile(memo: Memo) {
    const key = `memos/${memo.id}.zip`;
    const data = await Storage.vault.get(key, { download: true });

    return await this.unzip(data);
  }

  private async unzip(data: any) {
    const zip = new JSZip();
    await zip.loadAsync(data.Body);

    const fileData = await zip.file("memo.txt");
    if (fileData) {
      return await fileData.async("string");
    }
    return "";
  }

  private async zip(data: any): Promise<Blob> {
    const zip = new JSZip();
    zip.file("memo.txt", data);
    const binary = await zip.generateAsync({ type: "blob" });
    return binary;
  }
}
