/**
 * Data Model
 */
export interface Plot {
  novelId: string;
  plotGroups: PlotGroups[];
  mode: Mode;
  layout: Layout;
  displayFormat: DisplayFormat;
}

export interface PlotGroups {
  name: string;
  description: string;
  plots: Plots[];
}

export interface Plots {
  key: string;
  text: string;
  linkedPlotKey: string;
}

export interface SubPlot extends Plot {
  plotId: string;
  name: string;
}

/** プロット共有時の全体プロット用 */
export interface OverallPlot extends Plot {
  name: string;
  plotId: string;
}

/**
 * Type Guard
 */

export function isSubPlot(plot: Plot | SubPlot): plot is SubPlot {
  return "plotId" in plot && "name" in plot;
}

/**
 * Request Model
 */
export interface UpdatePlotPayload {
  novelId: string;
  plotGroups: PlotGroups[];
  layout: Layout;
  displayFormat: DisplayFormat;
}

export interface UpdatePlotModePayload extends UpdatePlotPayload {
  mode: Mode;
}

export interface CreateSubPlotPayload {
  novelId: string;
  name: string;
  plotGroups: PlotGroups[];
  mode: Mode;
  layout: Layout;
  displayFormat: DisplayFormat;
  callback: (plotId: string) => void;
}

export interface UpdateSubPlotPayload {
  novelId: string;
  plotId: string;
  name: string;
  plotGroups: PlotGroups[];
  mode: Mode;
  layout: Layout;
  displayFormat: DisplayFormat;
}

export interface UpdateSubPlotSortOrderPayload {
  novelId: string;
  order: string[];
}

export interface DeleteSubPlotPayload {
  novelId: string;
  plotId: string;
  callback: () => void;
}

export interface GetPlotsByExternalUserInput {
  userId: string;
  novelId: string;
}

export interface GetSubPlotsByExternalUserInput {
  userId: string;
  novelId: string;
}

/**
 * Enum
 */
export const Mode = {
  /** 起承転結 */
  kishoutenketsu: "kishoutenketsu",
  /** 序破急 */
  johakyu: "johakyu",
  /** カスタム */
  custom: "custom",
} as const;
// eslint-disable-next-line no-redeclare
export type Mode = typeof Mode[keyof typeof Mode];

export const Layout = {
  /** シンプル */
  simple: "simple",
  /** 詳細設計 */
  detail: "detail",
} as const;
// eslint-disable-next-line no-redeclare
export type Layout = typeof Layout[keyof typeof Layout];

export const DisplayFormat = {
  /** 横 */
  HORIZONTAL: "horizontal",
  /** 縦 */
  VERTICAL: "vertical",
} as const;
// eslint-disable-next-line no-redeclare
export type DisplayFormat = typeof DisplayFormat[keyof typeof DisplayFormat];

/**
 * Type
 */

/** プロット共有時の表示リスト */
export type PlotList = (OverallPlot | SubPlot)[];
