import { Auth, AuthClass } from "aws-amplify";

// AwsConfig type
export type AwsConfig = {
  region: string;
  identityPoolId: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  clientMetadata: { serviceName: string };
};

// AuthService class
export class AuthService {
  constructor(private auth: AuthClass = Auth) {}

  // Authのconfigureを設定する
  // Nola, NolaノベルのAPI呼び出しで別の設定が必要となる（ほぼ変わらないけど）
  configure(config: AwsConfig) {
    this.auth.configure(config);
  }

  // JWTトークンを取得する
  async getJwtToken(): Promise<string> {
    try {
      const session = await this.auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      throw new Error(`Error getting JWT token: ${error}`);
    }
  }

  // ユーザーのサブを取得する
  async getUserSub(): Promise<string> {
    try {
      const user = await this.auth.currentAuthenticatedUser();
      return user.attributes.sub;
    } catch (error) {
      throw new Error(`Error getting user sub: ${error}`);
    }
  }

  // サインイン（とりあえずテストでのみ利用）
  async signIn(username: string, password: string) {
    try {
      await this.auth.signIn(username, password);
    } catch (error) {
      throw new Error(`Error signing in: ${error}`);
    }
  }
}
