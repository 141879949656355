import { NovelSetting } from "@/lib/models/novel";

/** ジャンル */
export const genre: NovelSetting[] = [
  {
    id: "novel",
    name: "小説",
    image: "none",
  },
  {
    id: "lightNovel",
    name: "ライトノベル",
    image: "none",
  },
  {
    id: "pureLiterature",
    name: "純文学",
    image: "none",
  },
  {
    id: "comic",
    name: "漫画",
    image: "none",
  },
  {
    id: "gameScenario",
    name: "ゲームシナリオ",
    image: "none",
  },
  {
    id: "videoScenario",
    name: "映像シナリオ",
    image: "none",
  },
  {
    id: "script",
    name: "脚本",
    image: "none",
  },
  {
    id: "pictureBook",
    name: "絵本",
    image: "none",
  },
  {
    id: "childrenBook",
    name: "児童書",
    image: "none",
  },
  {
    id: "literaryJottings",
    name: "随筆",
    image: "none",
  },
  {
    id: "essay",
    name: "エッセイ",
    image: "none",
  },
  {
    id: "book",
    name: "書籍",
    image: "none",
  },
  {
    id: "other",
    name: "その他",
    image: "none",
  },
];

/** カテゴリ */
export const category: NovelSetting[] = [
  {
    id: "romantic",
    name: "恋愛",
    image: "none",
  },
  {
    id: "romanticComedy",
    name: "ラブコメ",
    image: "none",
  },
  {
    id: "battle",
    name: "バトル",
    image: "none",
  },
  {
    id: "fantasy",
    name: "ファンタジー",
    image: "none",
  },
  {
    id: "differentWorldFantasy",
    name: "異世界ファンタジー",
    image: "none",
  },
  {
    id: "SF",
    name: "SF",
    image: "none",
  },
  {
    id: "mystery",
    name: "ミステリー",
    image: "none",
  },
  {
    id: "horror",
    name: "ホラー",
    image: "none",
  },
  {
    id: "detective",
    name: "探偵",
    image: "none",
  },
  {
    id: "gag",
    name: "ギャグ",
    image: "none",
  },
  {
    id: "swashbuckler",
    name: "冒険活劇",
    image: "none",
  },
  {
    id: "history",
    name: "歴史もの",
    image: "none",
  },
  {
    id: "BL",
    name: "BL",
    image: "none",
  },
  {
    id: "TL",
    name: "TL",
    image: "none",
  },
  {
    id: "eroticNovel",
    name: "官能小説",
    image: "none",
  },
  {
    id: "education",
    name: "教育",
    image: "none",
  },
  {
    id: "other",
    name: "その他",
    image: "none",
  },
];

/** 制作状況 */
export const workStatus: NovelSetting[] = [
  {
    id: "inProgress",
    name: "制作中",
    image: "none",
  },
  {
    id: "completed",
    name: "制作完了",
    image: "none",
  },
  {
    id: "pending",
    name: "保留",
    image: "none",
  },
  {
    id: "suspend",
    name: "制作中断",
    image: "none",
  },
];

/** ソート */
export const sort: NovelSetting[] = [
  {
    id: "createdAtAsc",
    name: "作成日（昇順）",
    image: "none",
  },
  {
    id: "createdAtDesc",
    name: "作成日（降順）",
    image: "none",
  },
  {
    id: "updatedAtAsc",
    name: "更新日（昇順）",
    image: "none",
  },
  {
    id: "updatedAtDesc",
    name: "更新日（降順）",
    image: "none",
  },
  {
    id: "titleAsc",
    name: "タイトル（昇順）",
    image: "none",
  },
  {
    id: "titleDesc",
    name: "タイトル（降順）",
    image: "none",
  },
];
