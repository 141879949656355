export const Kind = {
  /** 原稿 */
  MANUSCRIPT: "manuscript",
  /** プロット */
  PLOT: "plot",
  /** 登場人物 */
  CHARACTER: "character",
  /** 世界観 */
  WORLDVIEW: "worldView",
  /** 相関関係グループ */
  CORRELATION_GROUP: "correlationGroup",
  /** メモタグ */
  MEMO_TAG: "memoTag",
  /** 資料種別 */
  MATERIAL_ATTRIBUTE: "materialAttribute",
  /** 資料入力テンプレート */
  MATERIAL_INPUT_TEMPLATE: "materialInputTemplate",
} as const;
// eslint-disable-next-line no-redeclare
export type Kind = typeof Kind[keyof typeof Kind];

/**
 * 並び順
 */
export interface SortOrder {
  novelId?: string;
  kind: Kind;
  order: string[];
  createdAt: number;
  updatedAt: number;
}

export interface UpdateOrder {
  novelId: string;
  kind?: Kind;
  order: string[];
}
