export const UserStatus = {
  key: "user_status",
  value: {
    unRegister: "未登録",
    freeUser: "無料会員",
    subscriptionUser: "有料会員",
    schoolUser: "スクール会員",
  },
};

export const SubscribeStatus = {
  key: "subscribe_status",
  value: {
    none: "未課金",
    unSubscribe: "課金退会",
    subscribeMonth: "月額",
    subscribeYear: "年額",
  },
};

export const LoginStatus = {
  key: "login_status",
  value: {
    loggedIn: "ログイン済",
    loggedOut: "未ログイン",
  },
};

export const AgentStatus = {
  key: "agent_status",
  value: {
    on: "on",
    off: "off",
  },
};

export const AgentNovelCount = {
  key: "agent_novel_count",
  value: {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    sixToTen: "6~10",
    elevenToTwenty: "11~20",
    twentyOneToFifty: "21~50",
    fiftyOneToNinetyNine: "51~99",
    overOneHundred: "100以上",
  },
};
