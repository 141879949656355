import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { Novel, TCopyNovelPayload } from "../models";
import { AssociatedDataType } from "../models/common";

interface INovelClient {
  fetchAllNovel: () => Promise<Novel[]>;
  fetchNovelByExternalUser: (userId: string, novelId: string) => Promise<Novel>;
  fetchNovelById: (id: string) => Promise<Novel>;

  setAssociatedData: (
    serviceName: "NolaNovel" | "NolaPublishing",
    novelId: string,
    id: string | null
  ) => Promise<{ novelId: string; associatedData: AssociatedDataType }>;

  createNovel: (title: string, genre?: string | null, category?: string[] | null) => Promise<string>;
  updateNovel: (novel: Novel) => Promise<Novel>;
  deleteNovel: (novelId: string, title: string) => Promise<void>;
  copyNovel: (payload: TCopyNovelPayload) => Promise<boolean>;

  requestNolaAgentForNovel: (novelId: string) => Promise<Novel>;
  revokeNolaAgentForNovel: (novelId: string) => Promise<Novel>;
}

export class NovelClient implements INovelClient {
  async fetchNovelById(id: string): Promise<Novel> {
    const params: RequestParam = {
      query: fetchNovelById,
      variables: {
        novelId: id,
      },
    };

    const { novel } = await GraphQLClient.request(params);
    return novel;
  }

  async fetchAllNovel(): Promise<Novel[]> {
    const { novels } = await GraphQLClient.request({ query: fetchAllNovel });
    return novels.items;
  }

  async fetchNovelByExternalUser(userId: string, novelId: string): Promise<Novel> {
    const params: RequestParam = {
      query: fetchNovelByExternalUser,
      variables: {
        input: {
          userId,
          novelId,
        },
      },
    };

    const { novelByExternalUser } = await GraphQLClient.request(params);
    return novelByExternalUser;
  }

  async setAssociatedData(
    serviceName: "NolaNovel" | "NolaPublishing",
    novelId: string,
    id: string | null
  ): Promise<{ novelId: string; associatedData: AssociatedDataType }> {
    const params: RequestParam = {
      query: setNovelAssociatedData,
      variables: {
        input: {
          serviceName,
          novelId,
          id,
        },
      },
    };

    const { setNovelAssociatedData: result } = await GraphQLClient.request(params);
    return result;
  }

  async createNovel(title: string, genre?: string | null, category?: string[] | null): Promise<string> {
    const params: RequestParam = {
      query: createNovel,
      variables: {
        input: {
          title,
          genre,
          category,
        },
      },
    };

    const { createNovel: result } = await GraphQLClient.request(params);
    const { novelId } = result;
    return novelId;
  }

  async updateNovel(novel: Novel): Promise<Novel> {
    const input = {
      ...novel,
    };
    delete input.associatedData;
    delete input.updatedAtLatestDataInNovel;
    delete input.agentStatus;
    delete input.agentStartedAt;
    delete input.createdAt;
    delete input.updatedAt;
    const params: RequestParam = {
      query: updateNovel,
      variables: {
        input,
      },
    };

    const { updateNovel: result } = await GraphQLClient.request(params);
    return result;
  }

  async deleteNovel(novelId: string, title: string): Promise<void> {
    const params: RequestParam = {
      query: deleteNovel,
      variables: {
        input: {
          novelId,
          title,
        },
      },
    };
    await GraphQLClient.request(params);
  }

  async copyNovel(payload: TCopyNovelPayload): Promise<boolean> {
    const { novelId, copySelectedData } = payload;
    const variables = {
      novelId,
      copySelectedData,
    };

    const params: RequestParam = {
      query: copyNovel,
      variables: {
        input: variables,
      },
    };
    const result = await GraphQLClient.request(params);

    return result.copyNovel.success;
  }

  async requestNolaAgentForNovel(novelId: string): Promise<Novel> {
    const variables = {
      novelId,
    };
    const params: RequestParam = {
      query: requestNolaAgentForNovel,
      variables: {
        input: variables,
      },
    };
    const { requestNolaAgentForNovel: result } = await GraphQLClient.request(params);
    return result;
  }

  async revokeNolaAgentForNovel(novelId: string): Promise<Novel> {
    const variables = {
      novelId,
    };
    const params: RequestParam = {
      query: revokeNolaAgentForNovel,
      variables: {
        input: variables,
      },
    };
    const { revokeNolaAgentForNovel: result } = await GraphQLClient.request(params);
    return result;
  }
}

/**
 * Query
 */

const fetchAllNovel = gql`
  query Novels {
    novels {
      items {
        novelId
        title
        image
        description
        genre
        category
        workStatus
        pinned
        agentStatus
        agentStartedAt
        associatedData {
          nolaNovel {
            id
          }
        }
        updatedAtLatestDataInNovel
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchNovelByExternalUser = gql`
  query NovelByExternalUser($input: GetNovelByExternalUserInput!) {
    novelByExternalUser(input: $input) {
      novelId
      title
      image
      description
      createdAt
      updatedAt
    }
  }
`;

const fetchNovelById = gql`
  query Novel($novelId: ID!) {
    novel(novelId: $novelId) {
      novelId
      title
      image
      description
      genre
      category
      workStatus
      agentStatus
      agentStartedAt
      associatedData {
        nolaNovel {
          id
        }
      }
      updatedAtLatestDataInNovel
      createdAt
      updatedAt
    }
  }
`;

const setNovelAssociatedData = gql`
  mutation SetNovelAssociatedData($input: SetNovelAssociatedDataInput!) {
    setNovelAssociatedData(input: $input) {
      novelId
      associatedData {
        nolaNovel {
          id
        }
      }
    }
  }
`;

const createNovel = gql`
  mutation CreateNovel($input: CreateNovelInput!) {
    createNovel(input: $input) {
      novelId
    }
  }
`;

const updateNovel = gql`
  mutation UpdateNovel($input: UpdateNovelInput!) {
    updateNovel(input: $input) {
      description
      novelId
      title
      image
      genre
      category
      workStatus
      pinned
      agentStatus
      agentStartedAt
      associatedData {
        nolaNovel {
          id
        }
      }
      updatedAtLatestDataInNovel
      createdAt
      updatedAt
    }
  }
`;

const deleteNovel = gql`
  mutation DeleteNovel($input: DeleteNovelInput!) {
    deleteNovel(input: $input)
  }
`;

const copyNovel = gql`
  mutation CopyNovel($input: CopyNovelInput!) {
    copyNovel(input: $input) {
      success
    }
  }
`;

const requestNolaAgentForNovel = gql`
  mutation requestNolaAgentForNovel($input: NolaAgentForNovelInput!) {
    requestNolaAgentForNovel(input: $input) {
      novelId
      agentStatus
      agentStartedAt
    }
  }
`;

const revokeNolaAgentForNovel = gql`
  mutation revokeNolaAgentForNovel($input: NolaAgentForNovelInput!) {
    revokeNolaAgentForNovel(input: $input) {
      novelId
      agentStatus
      agentStartedAt
    }
  }
`;
