
import Vue from "vue";
import { NolaAnalytics } from "@/lib/utils/analytics";
import { Auth } from "aws-amplify";
import { isTwitterLogin } from "@/lib/twitterAuth";
import GlobalComponent from "@/components/GlobalComponent.vue";
import WarningIcon from "@/components/atoms/WarningIcon.vue";
import { showNotifyDialog } from "@/lib/dialog";
import { Dialog, isBilling } from "@/lib/utils";
import PopupDialog, { PopupDialogProps, Popup } from "@/components/ui/PopupDialog.vue";
import axiosBase from "axios";
import { Route } from "vue-router";
import { User } from "./lib/models/user";
import { Novel } from "./lib/models/novel";
import { NovelFromNolaNovel } from "./lib/models/nolaNovel";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { GlobalComponent, WarningIcon },
  async created() {
    const { $store, showErrorDialog, persistStorage } = this;
    $store.dispatch("generalModule/initialize");
    await persistStorage();

    try {
      const popupRequest = await axios.get(`/notifications`);
      if (popupRequest.status !== 200) {
        await showErrorDialog();
        return;
      }
      this.popups = popupRequest.data.contents;
    } catch (e) {
      await showErrorDialog();
    }
  },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
      isLogin: false,
      popups: [],
    };
  },
  computed: {
    readPopupList() {
      return this.$store.getters["generalModule/readPopupList"];
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    novelsFromNolaNovel() {
      return this.$store.getters["nolaNovelModule/novels"];
    },
  },
  methods: {
    close(id) {
      this.$notify.close(id);
    },
    async showErrorDialog() {
      await showNotifyDialog({
        title: "エラー",
        content: "ポップアップ情報の取得に失敗しました。",
      });
    },
    async persistStorage() {
      /**
       * NOTE: IndexedDBを永続化させる
       * https://dexie.org/docs/StorageManager#controlling-persistence
       */
      if (navigator.storage && navigator.storage.persist) await navigator.storage.persist();
    },
  },
  watch: {
    async $route(to: Route, from: Route) {
      const { $store, $nextTick, popups, readPopupList } = this;

      const user = (await Auth.currentCredentials()) as any;
      const isAuthenticated = user.authenticated;
      const isLogin = user && !user.code && (isTwitterLogin() || isAuthenticated);
      this.isLogin = isLogin;

      // ////////////////////////////////////////
      // ユーザープロパティの設定
      // ////////////////////////////////////////

      this.nolaAnalytics.setUserProperties();

      // ////////////////////////////////////////
      // ポップアップ通知
      // ////////////////////////////////////////

      const toName = to.name;
      const fromName = from.name;
      const isTransitioned = toName !== fromName;
      if (!isTransitioned) return; // 画面遷移していない場合はreturn

      await $nextTick();

      // 表示ページ
      const popup = popups.find((popup) => popup.page && popup.page[0] === toName);
      if (!popup) return; // 現ページ対象のポップアップが存在しない場合はreturn

      const { startedAt, endedAt, id, platform, target, type, frequency, operator, conditions } = popup;

      // 配信期間
      const now = new Date();
      const isStarted = startedAt ? new Date(startedAt) <= now : true;
      const isEnded = endedAt ? new Date(endedAt) >= now : true;
      if (!isStarted || !isEnded) return; // 配信期間外の場合はreturn

      // プラットフォーム
      if (!platform.includes("web")) return;

      // プラン
      const isPremium = await isBilling($store);
      switch (target.length) {
        default:
        case 0:
          return;
        case 1:
          if ((target[0] === "free" && isPremium) || (target[0] === "premium" && !isPremium)) return;
          break;
        case 2:
          if (!target.includes("free") || !target.includes("premium")) return;
          break;
      }

      // 通知タイプ
      if (!type.includes("popup")) return;

      // 表示条件
      if (operator.length > 0 && conditions.length > 0) {
        const matchConditions = conditions.map((condition) => {
          const { fieldId, value, min, max } = condition;
          switch (fieldId) {
            // 制作状況
            case "workStatus": {
              const items = this.novels.filter((novel) => {
                let { workStatus } = novel;
                if (!workStatus) workStatus = "inProgress";
                return value.includes(workStatus);
              });
              if ((min && items.length < min) || (max && items.length > max)) return false;
              return true;
            }

            // ジャンル
            case "genre": {
              const items = this.novels.filter((novel) => {
                const { genre } = novel;
                return genre && value.includes(genre);
              });
              if ((min && items.length < min) || (max && items.length > max)) return false;
              return true;
            }

            // カテゴリ
            case "category": {
              const items = this.novels.filter((novel) => {
                const { category } = novel;
                return category && category.some((x) => value.includes(x));
              });
              if ((min && items.length < min) || (max && items.length > max)) return false;
              return true;
            }

            // ジャンル（Nolaノベル）
            case "nolaNovelGenre": {
              const items = this.novelsFromNolaNovel.filter((novel) => {
                const { genre } = novel;
                return genre && value.includes(genre);
              });
              if ((min && items.length < min) || (max && items.length > max)) return false;
              return true;
            }

            // サブジャンル（Nolaノベル）
            case "nolaNovelSubGenre": {
              const items = this.novelsFromNolaNovel.filter((novel) => {
                const { subGenre } = novel;
                return subGenre && value.includes(subGenre);
              });
              if ((min && items.length < min) || (max && items.length > max)) return false;
              return true;
            }

            // エージェント利用状況
            case "agentStatus": {
              const isAgentStarted = !!this.user.agentStartedAt;
              const isMatchValue =
                (value.includes("on") && isAgentStarted) || (value.includes("off") && !isAgentStarted);
              if (isMatchValue) {
                // エージェントを有効化していない場合、min/maxは参照しないため、早期リターン
                if (!isAgentStarted) return true;

                // エージェント連携している作品を取得
                const novels = this.novels.filter((novel) => novel.agentStatus === 1);
                if ((min && novels.length < min) || (max && novels.length > max)) return false;
                return true;
              }
              return false;
            }

            default: {
              return false;
            }
          }
        });

        // 条件に合致しない場合はreturn
        if (operator[0] === "AND" && !matchConditions.every((x) => x)) return;
        if (operator[0] === "OR" && !matchConditions.some((x) => x)) return;
      }

      // 既読かどうか
      const isReadPopup = readPopupList.includes(id);
      if (isReadPopup) return; // 既読ポップアップの場合はreturn

      // ポップアップを表示
      const dialog = new Dialog(PopupDialog);
      const data: PopupDialogProps = {
        popup,
      };
      dialog.show(data);

      // 表示頻度
      if (frequency[0] === "every") return; // 毎回表示の場合は既読にせずにreturn

      // ポップアップを既読にする
      $store.dispatch("generalModule/readPopup", id);
    },
    isLogin(newVal: boolean, oldVal: boolean) {
      if (newVal !== oldVal && newVal) this.$store.dispatch("userModule/initialize");
    },
  },
});

interface Props {}

interface Data {
  nolaAnalytics: NolaAnalytics;
  isLogin: boolean;
  popups: Popup[];
}

interface Computed {
  readPopupList: string[];
  user: User;
  novels: Novel[];
  novelsFromNolaNovel: NovelFromNolaNovel[];
}

interface Methods {
  close(id: number): void;
  showErrorDialog(): Promise<void>;
  persistStorage(): Promise<void>;
}
