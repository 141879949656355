
import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    content: String,
  },
  methods: {
    cancel() {
      this.$close(true);
    },
    focusOut(e) {
      if (e.target.classList.contains("dialog-mask")) {
        this.$close(true);
      }
    },
  },
});
