import { CorrelationClient } from "@/lib/clients";
import { CorrelationGroup, Correlation } from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const correlationClient = new CorrelationClient();

// Stateの型定義
export interface CorrelationState {
  correlationGroupList: CorrelationGroup[];
  correlationList: Correlation[];
}

type Getters = {
  correlationGroupList(state: CorrelationState): CorrelationGroup[];
  correlationList(state: CorrelationState): Correlation[];
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<CorrelationState, {}>, novelId: string) => any;
};

const mutations: MutationTree<CorrelationState> = {
  setCorrelationGroupList(state, payload) {
    return (state.correlationGroupList = payload);
  },
  setCorrelationList(state, payload) {
    return (state.correlationList = payload);
  },
};

const actions: ActionTree<CorrelationState, {}> & Actions = {
  async initialize({ commit }, novelId) {
    const correlationGroupList = await correlationClient.fetchCorrelationGroupList(novelId);
    commit("setCorrelationGroupList", correlationGroupList);

    const promiseArray = correlationGroupList.map(async (correlationGroup) => {
      const { correlationGroupId } = correlationGroup;
      return await correlationClient.fetchCorrelationList({ novelId, correlationGroupId });
    });
    const allCorrelationList = await Promise.all(promiseArray);
    commit("setCorrelationList", allCorrelationList.flat());
  },
};

const getters: GetterTree<CorrelationState, {}> & Getters = {
  correlationGroupList: (state) => state.correlationGroupList,
  correlationList: (state) => state.correlationList,
};

export default {
  namespaced: true,
  state: {
    correlationGroupList: [],
    correlationList: [],
  },
  getters,
  actions,
  mutations,
};
