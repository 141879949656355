
import Vue from "vue";

export default Vue.extend({
  created() {
    /* eslint-disable no-restricted-globals */
    addEventListener("offline", this.offline);
    addEventListener("online", this.online);
  },
  beforeDestroy() {
    removeEventListener("offline", this.offline);
    removeEventListener("online", this.online);
    /* eslint-enable no-restricted-globals */
  },
  data() {
    return {
      isOffline: false,
    };
  },
  methods: {
    /** オフラインになった時 */
    offline() {
      this.isOffline = true;
    },
    online() {
      this.isOffline = false;
    },
  },
});
