
import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    content: String,
    okButton: {
      type: String,
      default: "はい",
    },
    cancelButton: {
      type: String,
      default: "キャンセル",
    },
  },
  methods: {
    cancel() {
      this.$close(false);
    },
    focusOut(e) {
      // if (e.target.classList.contains("dialog-mask")) {
      //   //  this.$close(false);
      // }
    },
  },
});
