
import Vue from "vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    CheckboxIcon,
  },
  props: {
    existsNolaPublishingUser: {
      type: Boolean,
      default: false,
    },
    existsNolaNovelUser: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "はい",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      confirmed: false,
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onClickCheckbox() {
      this.confirmed = !this.confirmed;
    },
    onClickContact() {
      this.$close(true);
    },
  },
});

interface Props {
  existsNolaPublishingUser: boolean;
  existsNolaNovelUser: boolean;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  confirmed: boolean;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  onClickCheckbox: () => void;
  onClickContact: () => void;
}

export type UserDeleteDialogProps = Props;
