import { defineStore } from "pinia";
import { ref } from "vue";
import { showOkCancelDialog } from "@/lib/dialog";

export const usePageEditStore = defineStore("pageEdit", () => {
  const isModified = ref<boolean>(false);

  function setModified(value: boolean) {
    isModified.value = value;
  }

  async function confirmUnsavedChanges() {
    if (!isModified.value) {
      return true;
    }

    const confirmed = await showOkCancelDialog({
      content: "保存されていない変更があります。<br />変更を破棄して移動しますか？",
      okButton: "破棄して移動する",
      cancelButton: "留まる",
    });

    if (confirmed) {
      setModified(false);
    }

    return confirmed;
  }

  return { setModified, confirmUnsavedChanges };
});
