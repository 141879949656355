import { UserClient, BillingClient } from "@/lib/clients";
import { User, Plan, Novel, ConnectedOtherServices } from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const userClient = new UserClient();
const billingClient = new BillingClient();

// Stateの型定義
export interface UserState {
  user: User;
  plan: Plan;
  connectedOtherServices: ConnectedOtherServices;
}

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<UserState, {}>) => any;
  updateUser: (this: Store<{}>, injectee: ActionContext<UserState, {}>, payload: string) => any;
  deleteUser: (this: Store<{}>, injectee: ActionContext<UserState, {}>, payload: DeleteUserPayload) => any;
};

interface DeleteUserPayload {
  email: string;
  callback: () => void;
}

type Getters = {
  user(state: UserState): User;
  plan(state: UserState): Plan;
  connectedOtherServices(state: UserState): ConnectedOtherServices;
};

const mutations: MutationTree<UserState> = {
  setUser(state, payload: User) {
    return (state.user = payload);
  },
  setPlan(state, payload: Plan) {
    return (state.plan = payload);
  },
  setConnectedOtherServices(state, payload: ConnectedOtherServices) {
    return (state.connectedOtherServices = payload);
  },
};

const actions: ActionTree<UserState, {}> & Actions = {
  async initialize({ commit }) {
    const user = userClient.fetchUser();
    const plan = billingClient.fetchPlan();
    const connectedOtherServices = userClient.checkConnectedOtherServices();

    const result = await Promise.allSettled([user, plan, connectedOtherServices]);

    if (result[0].status === "fulfilled") commit("setUser", result[0].value);
    if (result[1].status === "fulfilled") commit("setPlan", result[1].value);
    if (result[2].status === "fulfilled") commit("setConnectedOtherServices", result[2].value);
  },
  async updateUser({ commit }, payload: string) {
    const user = await userClient.updateUser(payload);
    commit("setUser", user);
  },
  async deleteUser({ dispatch, commit, rootGetters }, payload: DeleteUserPayload) {
    await dispatch("novelModule/initialize", undefined, { root: true });
    const novels = rootGetters["novelModule/novels"] as Novel[];
    const promise: Promise<any>[] = [];

    for (let index = 0; index < novels.length; index += 1) {
      const novel = novels[index];
      const { novelId, associatedData } = novel;
      if (associatedData && associatedData.nolaNovel.id) {
        const nolaNovelId = associatedData.nolaNovel.id;
        promise.push(dispatch("nolaNovelModule/deleteNovelLink", { novelId, nolaNovelId }, { root: true }));
      }
    }

    await Promise.all(promise);

    const { email, callback } = payload;
    await userClient.deleteUser(email);
    commit("setUser", null);
    if (callback) callback();
  },
  async startNolaAgentFeature({ commit }) {
    const user = await userClient.startNolaAgentFeature();
    commit("setUser", user);
  },
};

const getters: GetterTree<UserState, {}> & Getters = {
  user: (state) => state.user,
  plan: (state) => state.plan,
  connectedOtherServices: (state) => state.connectedOtherServices,
};

export default {
  namespaced: true,
  state: {
    user: {
      userId: "",
      name: "",
    },
    plan: Plan.free,
    connectedOtherServices: {
      nolaNovel: {},
      nolaPublishing: {},
    },
  } as UserState,
  getters,
  actions,
  mutations,
};
