import { format } from "date-fns";
import gql from "graphql-tag";
import GraphQLClient, { NolaNovelGraphQLClient, RequestParam } from "../api";
import {
  EpisodeFromNolaNovel,
  Manuscript,
  ManuscriptFolderList,
  MoveManuscript,
  Version,
  ManuscriptFolder,
  CreateManuscriptFolder,
  UpdateManuscriptFolder,
} from "../models";

interface IManuscriptClient {
  fetchAllManuscript: (novelId: string) => Promise<Manuscript[]>;
  fetchAllManuscriptByExternalUser: (userId: string, novelId: string) => Promise<Manuscript[]>;
  fetchAllManuscriptFolder: (novelId: string) => Promise<ManuscriptFolderList>;
  fetchManuscriptByIdFromNolaNovel: (id: string) => Promise<EpisodeFromNolaNovel>;
  incrementWritingCount: (novelId: string, value: number) => Promise<boolean>;
  fetchManuscriptContent: (novelId: string, manuscriptKey: string, versionId: string) => Promise<string | null>;
  fetchManuscriptVersions: (novelId: string, manuscriptKey: string) => Promise<Version[]>;

  setAssociatedData: (
    serviceName: "NolaNovel" | "NolaPublishing",
    novelId: string,
    items: { manuscriptId: string; id?: string }[]
  ) => Promise<{ novelId: string; items: { manuscriptId: string; id?: string }[] }>;

  addEpisodeLink: (
    input: {
      linkNovelId: string;
      linkEpisodeId: string;
      episodeId: string;
    }[]
  ) => Promise<{ id: string; novelIdNola: string; episodeIdNola: string }[]>;

  deleteEpisodeLink: (episodeIds: string[]) => Promise<{ id: string; novelIdNola: string; episodeIdNola: string }[]>;
}

export class ManuscriptClient implements IManuscriptClient {
  async fetchAllManuscript(novelId: string): Promise<Manuscript[]> {
    const params: RequestParam = {
      query: fetchAllManuscript,
      variables: {
        novelId,
      },
    };

    const { manuscripts } = await GraphQLClient.request(params);
    return manuscripts.items;
  }

  async fetchAllManuscriptByExternalUser(userId: string, novelId: string): Promise<Manuscript[]> {
    const params: RequestParam = {
      query: fetchAllManuscriptByExternalUser,
      variables: {
        input: {
          userId,
          novelId,
        },
      },
    };

    const { manuscriptListByExternalUser } = await GraphQLClient.request(params);
    return manuscriptListByExternalUser.items;
  }

  async fetchAllManuscriptFolder(novelId: string): Promise<ManuscriptFolderList> {
    const params: RequestParam = {
      query: fetchAllManuscriptFolder,
      variables: {
        novelId,
      },
    };

    const { manuscriptFolders } = await GraphQLClient.request(params);
    return manuscriptFolders.items;
  }

  async fetchManuscriptByIdFromNolaNovel(id: string): Promise<EpisodeFromNolaNovel> {
    const params: RequestParam = {
      query: fetchManuscriptByIdFromNolaNovel,
      variables: {
        input: {
          id,
        },
      },
    };

    const { getEpisode } = await NolaNovelGraphQLClient.request(params);
    return getEpisode;
  }

  async incrementWritingCount(novelId: string, value: number): Promise<boolean> {
    const params: RequestParam = {
      query: incrementWritingCount,
      variables: {
        input: {
          date: format(new Date(), "YYYYMMDD"),
          novelId,
          value,
        },
      },
    };

    const { incrementWritingCount: result } = await GraphQLClient.request(params);
    return result;
  }

  async fetchManuscriptContent(novelId: string, manuscriptKey: string, versionId: string): Promise<string | null> {
    const params: RequestParam = {
      query: fetchManuscriptContent,
      variables: {
        input: {
          novelId,
          manuscriptKey,
          versionId,
        },
      },
    };

    const { manuscriptContent } = await GraphQLClient.request(params);
    return manuscriptContent;
  }

  async fetchManuscriptVersions(novelId: string, manuscriptKey: string): Promise<Version[]> {
    const params: RequestParam = {
      query: fetchManuscriptVersions,
      variables: {
        input: {
          novelId,
          manuscriptKey,
        },
      },
    };

    const { manuscriptVersions } = await GraphQLClient.request(params);
    return manuscriptVersions;
  }

  async setAssociatedData(
    serviceName: "NolaNovel" | "NolaPublishing",
    novelId: string,
    items: { manuscriptId: string; id?: string }[]
  ): Promise<{ novelId: string; items: { manuscriptId: string; id?: string }[] }> {
    const params: RequestParam = {
      query: setManuscriptAssociatedData,
      variables: {
        input: {
          serviceName,
          novelId,
          items,
        },
      },
    };

    const { setManuscriptAssociatedData: result } = await GraphQLClient.request(params);
    return result;
  }

  async addEpisodeLink(
    inputs: {
      linkNovelId: string;
      linkEpisodeId: string;
      episodeId: string;
    }[]
  ): Promise<{ id: string; novelIdNola: string; episodeIdNola: string }[]> {
    const inputValues: { [key: string]: any } = {};
    let inputArgs = "";
    let inputMethods = "";

    for (let index = 0; index < inputs.length; index += 1) {
      const input = inputs[index];
      const inputKey = `input${index}`;
      inputArgs += `$${inputKey}: LinkEpisodeIdInput!`;
      if (index > inputs.length) inputArgs += ",";

      inputMethods += `
      linkEpisodeId${index}: linkEpisodeId(input: $${inputKey}) {
        id
        novelIdNola
        episodeIdNola
      }
      `;

      inputValues[`${inputKey}`] = { ...input, service: "NOLA" };
    }

    const params: RequestParam = {
      query: linkNolaNovel(inputArgs, inputMethods),
      variables: inputValues,
    };

    const result = await NolaNovelGraphQLClient.request(params);
    const results = Object.keys(result).map((key) => result[key]);
    return results;
  }

  async createManuscript(manuscriptInput: Manuscript): Promise<string> {
    const { novelId, title, manuscriptFolderId } = manuscriptInput;
    const params: RequestParam = {
      query: createManuscriptQuery,
      variables: {
        input: {
          novelId,
          title,
          manuscriptFolderId,
        },
      },
    };

    const { createManuscript } = await GraphQLClient.request(params);
    return createManuscript as string;
  }

  async createMultipleManuscript(manuscriptInput: Manuscript[]): Promise<Manuscript[]> {
    const input = manuscriptInput.map((manuscript) => {
      const { novelId, key, title, content, manuscriptFolderId } = manuscript;
      return {
        novelId,
        key,
        title,
        content,
        manuscriptFolderId,
      };
    });
    const params: RequestParam = {
      query: createMultipleManuscriptQuery,
      variables: {
        input,
      },
    };

    const { createMultipleManuscript } = await GraphQLClient.request(params);
    return createMultipleManuscript.items;
  }

  async updateManuscript(manuscriptInput: Manuscript): Promise<Manuscript[]> {
    const { novelId, key, title, associatedData } = manuscriptInput;
    const params: RequestParam = {
      query: updateManuscriptQuery,
      variables: {
        input: {
          novelId,
          key,
          title,
          associatedData,
        },
      },
    };

    const { updateManuscript } = await GraphQLClient.request(params);
    return updateManuscript.items;
  }

  async deleteManuscript(manuscriptInput: Manuscript): Promise<Manuscript[]> {
    const { novelId, key } = manuscriptInput;
    const params: RequestParam = {
      query: deleteManuscriptQuery,
      variables: {
        input: {
          novelId,
          key,
        },
      },
    };

    const { deleteManuscript } = await GraphQLClient.request(params);
    return deleteManuscript.items;
  }

  async deleteEpisodeLink(episodeIds: string[]): Promise<{ id: string; novelIdNola: string; episodeIdNola: string }[]> {
    const inputValues: { [key: string]: any } = {};
    let inputArgs = "";
    let inputMethods = "";

    for (let index = 0; index < episodeIds.length; index += 1) {
      const episodeId = episodeIds[index];
      const inputKey = `input${index}`;
      inputArgs += `$${inputKey}: LinkEpisodeIdInput!`;
      if (index > episodeIds.length) inputArgs += ",";

      inputMethods += `
      linkEpisodeId${index}: linkEpisodeId(input: $${inputKey}) {
        id
        novelIdNola
        episodeIdNola
      }
      `;

      inputValues[`${inputKey}`] = { episodeId, service: "NOLA" };
    }

    const params: RequestParam = {
      query: linkNolaNovel(inputArgs, inputMethods),
      variables: inputValues,
    };

    const result = await NolaNovelGraphQLClient.request(params);
    const results = Object.keys(result).map((key) => result[key]);
    return results;
  }

  async moveManuscript(manuscriptInput: MoveManuscript): Promise<Manuscript[]> {
    const { novelId, key, direction } = manuscriptInput;
    const params: RequestParam = {
      query: moveManuscriptQuery,
      variables: {
        input: {
          novelId,
          key,
          direction,
        },
      },
    };

    const { moveManuscript } = await GraphQLClient.request(params);
    return moveManuscript.items;
  }

  async createManuscriptFolder({ novelId, name }: CreateManuscriptFolder): Promise<ManuscriptFolder> {
    const params: RequestParam = {
      query: createManuscriptFolderQuery,
      variables: {
        input: {
          novelId,
          name,
        },
      },
    };

    const { createManuscriptFolder: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateManuscriptFolder({ novelId, manuscriptFolders }: UpdateManuscriptFolder): Promise<ManuscriptFolder[]> {
    const params: RequestParam = {
      query: updateManuscriptFolderQuery,
      variables: {
        input: {
          novelId,
          items: manuscriptFolders,
        },
      },
    };

    const { updateManuscriptFolder: result } = await GraphQLClient.request(params);
    return result.items;
  }
}

/**
 * Query
 */

const fetchAllManuscript = gql`
  query ListManuscript($novelId: ID!) {
    manuscripts(novelId: $novelId) {
      novelId
      items {
        key
        title
        associatedData {
          nolaNovel {
            id
          }
        }
      }
    }
  }
`;

const fetchAllManuscriptByExternalUser = gql`
  query ListManuscriptByExternalUser($input: GetListManuscriptByExternalUserInput!) {
    manuscriptListByExternalUser(input: $input) {
      novelId
      items {
        key
        title
      }
    }
  }
`;

const fetchAllManuscriptFolder = gql`
  query ListManuscriptFolder($novelId: ID!) {
    manuscriptFolders(novelId: $novelId) {
      novelId
      items {
        manuscriptFolderId
        name
        manuscriptKeys
      }
    }
  }
`;

const fetchManuscriptByIdFromNolaNovel = gql`
  query GetEpisode($input: GetEpisodeInput!) {
    getEpisode(input: $input) {
      id
      type
      title
      order
      preface
      postscript
      bodyPath
      publishedAt
      isOncePublic
      characterCount
      novelIdNola
      episodeIdNola
      updatedAt
      createdAt
      novel {
        id
        title
        outline
        appeal
        imagePath
        workType
        characterCount
        genreType
        genre
        subGenre
        isComplete
        isPublic
        isOncePublic
        isAcceptReview
        isAcceptComment
        isAcceptTypoReport
        selfRating
        draftType
        rating
        readerGender
        readerAgeGroup
        readerTitle
        tag
        bookmarkCount
        reviewOverview {
          allPointCount
          allPointSum
          characterPointCount
          characterPointSum
          interestPointCount
          interestPointSum
          readablePointCount
          readablePointSum
          reviewCount
          storyPointCount
          storyPointSum
        }
        createdAt
        updatedAt
        publishedAt
      }
    }
  }
`;

const fetchManuscriptContent = gql`
  query ManuscriptContent($input: ManuscriptContentGetInput!) {
    manuscriptContent(input: $input)
  }
`;

const fetchManuscriptVersions = gql`
  query ManuscriptVersions($input: ManuscriptVersionListInput!) {
    manuscriptVersions(input: $input) {
      isLatest
      versionId
      lastModified
    }
  }
`;

const incrementWritingCount = gql`
  mutation IncrementWritingCount($input: WritingDataIncrementInput!) {
    incrementWritingCount(input: $input)
  }
`;

const setManuscriptAssociatedData = gql`
  mutation SetManuscriptAssociatedData($input: SetManuscriptAssociatedDataInput!) {
    setManuscriptAssociatedData(input: $input) {
      novelId
      items {
        manuscriptId
        id
      }
    }
  }
`;

const createManuscriptQuery = gql`
  mutation CreateManuscript($input: CreateManuscriptInput!) {
    createManuscript(input: $input)
  }
`;

const createMultipleManuscriptQuery = gql`
  mutation CreateMultipleManuscript($input: [CreateManuscriptInput]!) {
    createMultipleManuscript(input: $input) {
      novelId
      items {
        key
        title
        associatedData {
          nolaNovel {
            id
          }
        }
      }
    }
  }
`;

const updateManuscriptQuery = gql`
  mutation Update($input: UpdateManuscriptInput!) {
    updateManuscript(input: $input) {
      novelId
      items {
        key
        title
        associatedData {
          nolaNovel {
            id
          }
        }
      }
    }
  }
`;

const deleteManuscriptQuery = gql`
  mutation DeleteManuscript($input: DeleteManuscriptInput!) {
    deleteManuscript(input: $input) {
      novelId
      items {
        key
        title
      }
    }
  }
`;

const moveManuscriptQuery = gql`
  mutation MoveManuscript($input: MoveManuscriptInput!) {
    moveManuscript(input: $input) {
      novelId
      items {
        key
        title
      }
    }
  }
`;

const linkNolaNovel = (args: string, methods: string) => gql`
  mutation LinkNolaNovel(${args}) {
    ${methods}
  }
`;

const createManuscriptFolderQuery = gql`
  mutation CreateManuscriptFolder($input: CreateManuscriptFolderInput!) {
    createManuscriptFolder(input: $input) {
      manuscriptFolderId
      name
    }
  }
`;

const updateManuscriptFolderQuery = gql`
  mutation UpdateManuscriptFolders($input: UpdateManuscriptFolderInput!) {
    updateManuscriptFolder(input: $input) {
      novelId
      items {
        manuscriptFolderId
        name
        manuscriptKeys
      }
    }
  }
`;
