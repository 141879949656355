export interface SharedLink {
  /** ユーザーID */
  userId?: string;
  /** 作品ID */
  novelId: string;
  /** 共有リンクID */
  sharedLinkId?: string;
  /** 共有リンク名 */
  name?: string;
  /** 共有対象の原稿ID */
  manuscriptKeys?: string[];
  /** 共有対象のプロットID */
  plotIds?: string[];
  /** 共有リンクが有効か */
  enabled?: boolean;
  /** 作成日 */
  createdAt?: string;
  /** 更新日 */
  updatedAt?: string;
}

export interface Comment {
  /** ユーザーID */
  userId?: string;
  /** 共有リンクID */
  sharedLinkId: string;
  /** コメントID */
  commentId?: string;
  /** 原稿Key */
  manuscriptKey?: string;
  /** プロットID */
  plotId?: string;
  /** 作品ID */
  novelId?: string;
  /** 名前 */
  name?: string;
  /** コメント */
  comment?: string;
  /** 作成日 */
  createdAt?: string;
  /** 更新日 */
  updatedAt?: string;
}

export const SharedLinkKind = {
  /** 原稿 */
  MANUSCRIPT: "manuscript",
  /** プロット */
  PLOT: "plot",
} as const;
export type TSharedLinkKind = typeof SharedLinkKind[keyof typeof SharedLinkKind];
