import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import {
  CreateWorldView,
  CreateWorldViewFolder,
  DeleteWorldView,
  ListWorldViewInFolder,
  UpdateWorldView,
  UpdateWorldViewFolder,
  WorldView,
  WorldViewFolder,
} from "../models";

interface IWorldViewClient {
  fetchAllWorldView: (novelId: string) => Promise<WorldView[]>;
  fetchWorldViewsInFolder: ({ novelId, worldViewFolderId }: ListWorldViewInFolder) => Promise<WorldView[]>;
  fetchAllWorldViewFolder: (novelId: string) => Promise<WorldViewFolder[]>;
  createWorldView: (payload: CreateWorldView) => Promise<WorldView>;
  updateWorldView: (payload: UpdateWorldView) => Promise<WorldView>;
  deleteWorldView: (payload: DeleteWorldView) => Promise<WorldView>;
  createWorldViewFolder: ({ novelId, name }: CreateWorldViewFolder) => Promise<WorldViewFolder>;
  updateWorldViewFolder: ({ novelId, worldViewFolders }: UpdateWorldViewFolder) => Promise<WorldViewFolder[]>;
}

export class WorldViewClient implements IWorldViewClient {
  async fetchAllWorldView(novelId: string): Promise<WorldView[]> {
    const params: RequestParam = {
      query: fetchAllWorldView,
      variables: {
        input: {
          novelId,
        },
      },
    };

    const { worldViews } = await GraphQLClient.request(params);
    return worldViews.items;
  }

  async fetchWorldViewsInFolder({ novelId, worldViewFolderId }: ListWorldViewInFolder): Promise<WorldView[]> {
    const params: RequestParam = {
      query: fetchWorldViewsInFolder,
      variables: {
        input: {
          novelId,
          worldViewFolderId,
        },
      },
    };

    const { folderWorldViews } = await GraphQLClient.request(params);
    return folderWorldViews.items;
  }

  async fetchAllWorldViewFolder(novelId: string): Promise<WorldViewFolder[]> {
    const params: RequestParam = {
      query: fetchAllWorldViewFolder,
      variables: {
        novelId,
      },
    };

    const { worldViewFolders } = await GraphQLClient.request(params);
    return worldViewFolders.items;
  }

  async createWorldView(payload: CreateWorldView): Promise<WorldView> {
    const params: RequestParam = {
      query: createWorldView,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { createWorldView: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateWorldView(payload: UpdateWorldView): Promise<WorldView> {
    const params: RequestParam = {
      query: updateWorldView,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { updateWorldView: result } = await GraphQLClient.request(params);
    return result;
  }

  async deleteWorldView(payload: DeleteWorldView): Promise<WorldView> {
    const params: RequestParam = {
      query: deleteWorldView,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { deleteWorldView: result } = await GraphQLClient.request(params);
    return result;
  }

  async createWorldViewFolder({ novelId, name }: CreateWorldViewFolder): Promise<WorldViewFolder> {
    const params: RequestParam = {
      query: createWorldViewFolder,
      variables: {
        input: {
          novelId,
          name,
        },
      },
    };

    const { createWorldViewFolder: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateWorldViewFolder({ novelId, worldViewFolders }: UpdateWorldViewFolder): Promise<WorldViewFolder[]> {
    const params: RequestParam = {
      query: updateWorldViewFolder,
      variables: {
        input: {
          novelId,
          items: worldViewFolders,
        },
      },
    };

    const { updateWorldViewFolder: result } = await GraphQLClient.request(params);
    return result.items;
  }
}

/**
 * Query
 */

const fetchAllWorldView = gql`
  query ListWorldView($input: ListWorldViewInput!) {
    worldViews(input: $input) {
      nextToken
      items {
        novelId
        worldViewId
        name
        image
        createdAt
        updatedAt
        detail {
          name
          value
        }
        additionalColumn {
          name
          label
          content
        }
      }
    }
  }
`;

const fetchWorldViewsInFolder = gql`
  query ListWorldViewInFolder($input: GetFolderWorldViewsInput!) {
    folderWorldViews(input: $input) {
      items {
        novelId
        worldViewId
        name
        image
        createdAt
        updatedAt
        detail {
          name
          value
        }
        additionalColumn {
          name
          label
          content
        }
      }
    }
  }
`;

const fetchAllWorldViewFolder = gql`
  query ListWorldViewFolder($novelId: ID!) {
    worldViewFolders(novelId: $novelId) {
      novelId
      items {
        worldViewFolderId
        name
        worldViewKeys
      }
    }
  }
`;

const createWorldView = gql`
  mutation CreateWorldView($input: CreateWorldViewInput!) {
    createWorldView(input: $input) {
      novelId
      worldViewId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const updateWorldView = gql`
  mutation UpdateWorldView($input: UpdateWorldViewInput!) {
    updateWorldView(input: $input) {
      novelId
      worldViewId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const deleteWorldView = gql`
  mutation Delete($input: DeleteWorldViewInput!) {
    deleteWorldView(input: $input) {
      novelId
      worldViewId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const createWorldViewFolder = gql`
  mutation CreateWorldViewFolder($input: CreateWorldViewFolderInput!) {
    createWorldViewFolder(input: $input) {
      worldViewFolderId
      name
    }
  }
`;

const updateWorldViewFolder = gql`
  mutation UpdateWorldViewFolder($input: UpdateWorldViewFolderInput!) {
    updateWorldViewFolder(input: $input) {
      novelId
      items {
        worldViewFolderId
        name
        worldViewKeys
      }
    }
  }
`;
