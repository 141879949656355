import Storage from "@aws-amplify/storage";
import JSZip from "jszip";
import { loadWithCompress, getNovelKey } from "@/lib/s3";

interface ISharedManuscriptStorage {
  /**
   * 原稿をprivateからprotectedに複製する
   */
  duplicateFile: (novelId: string, manuscriptId: string) => Promise<void>;

  /**
   * 原稿のtextファイルを読み込む
   */
  downloadFile: (novelId: string, manuscriptId: string, identityId: string) => Promise<string>;

  /**
   * 原稿のtextファイルを保存する
   */
  uploadFile: (novelId: string, manuscriptId: string, content: string) => Promise<void>;
}

export class SharedManuscriptStorage implements ISharedManuscriptStorage {
  async duplicateFile(novelId: string, manuscriptId: string) {
    const novelKey = getNovelKey(novelId, manuscriptId);
    const { content } = await loadWithCompress(novelKey);
    await this.uploadFile(novelId, manuscriptId, content);
  }

  async downloadFile(novelId: string, manuscriptId: string, identityId: string) {
    const novelKey = getNovelKey(novelId, manuscriptId);
    const data = await Storage.get(novelKey, { level: "protected", download: true, expires: 0, identityId });
    return await this.unzip(data);
  }

  async uploadFile(novelId: string, manuscriptId: string, content: string) {
    const novelKey = getNovelKey(novelId, manuscriptId);
    const binary = await this.zip(content);
    await Storage.put(novelKey, binary, { level: "protected", contentType: "application/zip" });
  }

  private async unzip(data: any) {
    const zip = new JSZip();
    await zip.loadAsync(data.Body);

    const fileData = await zip.file("content.txt");
    if (fileData) {
      return await fileData.async("string");
    }
    return "";
  }

  private async zip(data: any): Promise<Blob> {
    const zip = new JSZip();
    zip.file("content.txt", data);
    const binary = await zip.generateAsync({ type: "blob" });
    return binary;
  }
}
