
import Vue from "vue";

export default Vue.extend<{}, Methods, {}, Props>({
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    fill: {
      type: String,
      default: "#d0d0d0",
    },
    emitEvent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.emitEvent) {
        this.$emit("change", !this.isChecked);
      }
    },
  },
});

interface Props {
  isChecked: boolean;
  iconSize: number;
  fill: string;
  emitEvent: boolean;
}

interface Methods {
  toggleCheck(): void;
}
