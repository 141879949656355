import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { NolaContestClient } from "@/lib/clients";
import { ContestEntries, EntryContestInput, NolaContest, NolaContestUser } from "@/lib/models/nolaContest";

const nolaContestClient = new NolaContestClient();

// Stateの型定義
export interface NolaContestState {
  contest: NolaContest | null;
  user: NolaContestUser | null;
  contestEntries: ContestEntries[];
}

type Getters = {
  contest(state: NolaContestState): (contestId: string) => NolaContest | null;
  user(state: NolaContestState): NolaContestUser | null;
  contestEntries(state: NolaContestState): ContestEntries[];
};

const getters: GetterTree<NolaContestState, {}> & Getters = {
  contest: (state) => (contestId) => (state.contest && state.contest.id === contestId ? state.contest : null),
  user: (state) => state.user,
  contestEntries: (state) => state.contestEntries,
};

type Mutations<S> = {
  setContest(state: S, payload: NolaContest): void;
  setUser(state: S, payload: NolaContestUser): void;
  setContestEntries(state: S, payload: ContestEntries[]): void;
};

const mutations: MutationTree<NolaContestState> & Mutations<NolaContestState> = {
  setContest(state, payload) {
    return (state.contest = payload);
  },
  setUser(state, payload) {
    return (state.user = payload);
  },
  setContestEntries(state, payload) {
    return (state.contestEntries = payload);
  },
};

type Actions = {
  fetchContest: (
    this: Store<{}>,
    injectee: ActionContext<NolaContestState, {}>,
    payload: string
  ) => Promise<NolaContest>;
  entryContest: (
    this: Store<{}>,
    injectee: ActionContext<NolaContestState, {}>,
    payload: EntryContestInput
  ) => Promise<any>;
  fetchUser: (this: Store<{}>, injectee: ActionContext<NolaContestState, {}>) => Promise<NolaContestUser>;
  updateUser: (
    this: Store<{}>,
    injectee: ActionContext<NolaContestState, {}>,
    payload: NolaContestUser
  ) => Promise<NolaContestUser>;
  fetchContestEntries: (this: Store<{}>, injectee: ActionContext<NolaContestState, {}>) => Promise<ContestEntries[]>;
};

const actions: ActionTree<NolaContestState, {}> & Actions = {
  async fetchContest({ commit }, payload) {
    const result = await nolaContestClient.fetchContest(payload);

    commit("setContest", result);
    return result;
  },
  async entryContest(_, payload) {
    const result = await nolaContestClient.entryContest(payload);

    return result;
  },
  async fetchUser({ commit }) {
    const result = await nolaContestClient.fetchUser();

    commit("setUser", result);
    return result;
  },
  async updateUser({ commit }, payload) {
    const result = await nolaContestClient.updateUser(payload);

    commit("setUser", result);
    return result;
  },
  async fetchContestEntries({ commit }) {
    const result = await nolaContestClient.fetchContestEntries();

    commit("setContestEntries", result);
    return result;
  },
};

const state: NolaContestState = {
  contest: null,
  user: null,
  contestEntries: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
