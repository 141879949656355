import { CharacterClient, DisplayOrderClient } from "@/lib/clients";
import {
  Character,
  CharacterFolder,
  CreateCharacter,
  CreateCharacterFolder,
  DeleteCharacter,
  SelectCharacterFolder,
  UpdateCharacter,
  UpdateCharacterFolder,
  CopyCharacter,
} from "@/lib/models";
import { Kind, UpdateOrder } from "@/lib/models/sortOrder";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const characterClient = new CharacterClient();
const displayOrderClient = new DisplayOrderClient();

// Stateの型定義
export interface CharacterState {
  characterList: Character[];
  characterFolderList: CharacterFolder[];
}

type Getters = {
  character(state: CharacterState): (characterId: string) => Character | undefined;
  characterList(state: CharacterState): Character[];
  characterFolder(state: CharacterState): (folderId: string) => CharacterFolder | undefined;
  characterFolderList(state: CharacterState): CharacterFolder[];
  charactersInFolder(state: CharacterState): (folderId: string) => Character[];
  foldersBelongCharacter(state: CharacterState): (characterId: string) => CharacterFolder[];
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<CharacterState, {}>, payload: string) => Promise<void>;
  selectFolder: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: SelectCharacterFolder
  ) => Promise<void>;
  updateOrder: (this: Store<{}>, injectee: ActionContext<CharacterState, {}>, payload: UpdateOrder) => Promise<void>;
  createCharacter: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: CreateCharacter
  ) => Promise<Character>;
  updateCharacter: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: UpdateCharacter
  ) => Promise<void>;
  deleteCharacter: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: DeleteCharacter
  ) => Promise<void>;
  createFolder: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: CreateCharacterFolder
  ) => Promise<void>;
  updateFolder: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: UpdateCharacterFolder
  ) => Promise<void>;
  copyCharacter: (
    this: Store<{}>,
    injectee: ActionContext<CharacterState, {}>,
    payload: CopyCharacter
  ) => Promise<void>;
};

const mutations: MutationTree<CharacterState> = {
  setCharacterList(state, payload) {
    return (state.characterList = payload);
  },
  pushCharacter(state, payload) {
    state.characterList.push(payload);
    return state.characterList;
  },
  updateCharacter(state, payload: Character) {
    return (state.characterList = state.characterList.map((character) => {
      if (character.characterId === payload.characterId) {
        return payload;
      }
      return character;
    }));
  },
  popCharacter(state, payload: Character) {
    return (state.characterList = state.characterList.filter(
      (character) => character.characterId !== payload.characterId
    ));
  },
  setCharacterFolderList(state, payload) {
    return (state.characterFolderList = payload);
  },
  pushCharacterFolder(state, payload) {
    state.characterFolderList.push(payload);
    return state.characterFolderList;
  },
};

const actions: ActionTree<CharacterState, {}> & Actions = {
  async initialize({ commit }, novelId) {
    const characterList = await characterClient.fetchAllCharacter(novelId);
    commit("setCharacterList", characterList);

    const characterFolderList = await characterClient.fetchAllCharacterFolder(novelId);
    commit("setCharacterFolderList", characterFolderList);
  },
  async selectFolder({ commit }, payload) {
    const characterList = await characterClient.fetchCharactersInFolder(payload);
    commit("setCharacterList", characterList);
  },
  async updateOrder(_, payload) {
    await displayOrderClient.updateDisplayOrder({
      ...payload,
      kind: Kind.CHARACTER,
    });
  },
  async createCharacter({ commit }, payload) {
    const character = await characterClient.createCharacter(payload);
    commit("pushCharacter", character);
    return character;
  },
  async updateCharacter({ commit }, payload) {
    const character = await characterClient.updateCharacter(payload);
    commit("updateCharacter", character);
  },
  async deleteCharacter({ commit }, payload) {
    const character = await characterClient.deleteCharacter(payload);
    commit("popCharacter", character);
  },
  async createFolder({ commit }, payload) {
    const folder = await characterClient.createCharacterFolder(payload);
    commit("pushCharacterFolder", { ...folder, characterKeys: [] });
  },
  async updateFolder({ commit }, payload) {
    const folders = await characterClient.updateCharacterFolder(payload);
    commit("setCharacterFolderList", folders);
  },
  async copyCharacter({ commit }, payload) {
    const character = await characterClient.copyCharacter(payload);
    commit("pushCharacter", character);
  },
};

const getters: GetterTree<CharacterState, {}> & Getters = {
  character: (state) => (characterId: string) => state.characterList.find((x) => x.characterId === characterId),
  characterList: (state) => state.characterList,
  characterFolder: (state) => (folderId: string) =>
    state.characterFolderList.find((x) => x.characterFolderId === folderId),
  characterFolderList: (state) => state.characterFolderList,
  charactersInFolder: (state) => (folderId: string) => {
    const folder = state.characterFolderList.find((x) => x.characterFolderId === folderId);
    if (!folder) return [];
    const characters = state.characterList.filter((x) => folder.characterKeys.includes(x.characterId));
    return characters.sort(
      (x, y) => folder.characterKeys.indexOf(x.characterId) - folder.characterKeys.indexOf(y.characterId)
    );
  },
  foldersBelongCharacter: (state) => (characterId: string) =>
    state.characterFolderList.filter((x) => x.characterKeys.includes(characterId)),
};

export default {
  namespaced: true,
  state: {
    characterList: [],
    characterFolderList: [],
  },
  getters,
  actions,
  mutations,
};
