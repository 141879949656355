import { ThemeClient } from "@/lib/clients/themeClient";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const themeClient = new ThemeClient();

export interface NovelThemeState {}

type Getters = {};

const getters: GetterTree<NovelThemeState, {}> & Getters = {};

type Mutations = {};

const mutations: MutationTree<NovelThemeState> & Mutations = {};

type UpdateThemePayload = {
  novelId: string;
  detail: { name: string; value: string }[];
};

type Actions<S> = {
  // update

  updateTheme(
    this: Store<{}>,
    injectee: ActionContext<NovelThemeState, {}>,
    payload: UpdateThemePayload
  ): Promise<void>;
};

const actions: ActionTree<NovelThemeState, {}> & Actions<NovelThemeState> = {
  async updateTheme(_, payload) {
    const { novelId, detail } = payload;
    await themeClient.updateNovelTheme(novelId, detail);
  },
};

const state: NovelThemeState = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
