import { API, Storage } from "aws-amplify";
import env from "./lib/env";
import { AuthService } from "./lib/auth";

export class NolaApiInitializer {
  static async init(): Promise<void> {
    // //////////
    // Amplify
    // //////////
    const service = new AuthService();
    service.configure({
      region: "ap-northeast-1",
      identityPoolId: env.VUE_APP_ID_POOL_ID!,
      userPoolId: env.VUE_APP_USER_POOL_ID!,
      userPoolWebClientId: env.VUE_APP_USER_POOL_CLIENT!,
      authenticationFlowType: "USER_SRP_AUTH",
      clientMetadata: { serviceName: "Nola" },
    });

    const endpoint = env.VUE_APP_API_ENDPOINT;
    const nolaContestEndpoint = env.VUE_APP_NOLACONTEST_API_ENDPOINT;

    API.configure({
      graphql_endpoint: `${endpoint}/graphql`,
      graphql_endpoint_iam_region: "ap-northeast-1",
      aws_appsync_authenticationType: "AWS_IAM",
      endpoints: [
        {
          name: "GW",
          endpoint: `${endpoint}/graphql`,
          region: "ap-northeast-1",
        },
        {
          name: "Rest",
          endpoint,
          region: "ap-northeast-1",
        },
        {
          name: "NolaContestAPI",
          endpoint: nolaContestEndpoint,
          region: "ap-northeast-1",
        },
      ],
    });

    Storage.configure({
      bucket: process.env.VUE_APP_DATA_BUCKET, // REQUIRED -  Amazon S3 bucket
      region: "ap-northeast-1",
    });
  }
}

export class NolaNovelApiInitializer {
  static init(): void {
    const service = new AuthService();
    service.configure({
      region: "ap-northeast-1",
      identityPoolId: process.env.VUE_APP_ID_POOL_ID!,
      userPoolId: process.env.VUE_APP_USER_POOL_ID!,
      userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT!,
      clientMetadata: { serviceName: "Nolaノベル" },
    });

    const endpoint = env.VUE_APP_NOLANOVEL_API_ENDPOINT;
    API.configure({
      graphql_endpoint: `${endpoint}/graphql`,
      graphql_endpoint_iam_region: "ap-northeast-1",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      endpoints: [
        {
          name: "GW",
          endpoint: `${endpoint}/graphql`,
          region: "ap-northeast-1",
        },
        {
          name: "Rest",
          endpoint,
          region: "ap-northeast-1",
        },
      ],
    });

    Storage.configure({
      bucket: process.env.VUE_APP_NOLANOVEL_DATA_BUCKET, // REQUIRED -  Amazon S3 bucket
      region: "ap-northeast-1",
    });
  }
}
