import { AssociatedDataType } from "./common";

export interface Manuscript {
  key?: string;
  content?: string;
  title?: string;
  novelId?: string;
  associatedData?: ManuscriptAssociatedDataType;
  manuscriptFolderId?: string;
  lastModified?: string;
  versions?: Version[];
}

export interface ManuscriptFolderList {
  novelId: string;
  items: ManuscriptFolder[];
}

export interface ManuscriptFolder {
  manuscriptFolderId: string;
  name: string;
  manuscriptKeys: string[];
}

export type ManuscriptAssociatedDataType = AssociatedDataType & {
  nolaNovel: {};
};

export interface MoveManuscript {
  novelId: string;
  key: string;
  direction: string;
}

export interface ShowFlags {
  isShowPlots: boolean;
  isShowCharacters: boolean;
  isShowWorldViews: boolean;
  isShowMemo: boolean;
}

export interface SearchTextIndex {
  from: number;
  to: number;
}

export interface Version {
  isLatest: boolean;
  versionId: string;
  lastModified: string;
}

export interface CreateManuscriptFolder {
  novelId: string;
  name: string;
}

export interface UpdateManuscriptFolder {
  novelId: string;
  manuscriptFolders: ManuscriptFolder[];
}

export const Theme = {
  /** ノーマル */
  default: "default",
  /** ダークモード */
  dark: "dark",
  /** セピアモード */
  sepia: "sepia",
  /** 黒板モード */
  blackboard: "blackboard",
  /** 太字モード */
  bold: "bold",
} as const;
// eslint-disable-next-line no-redeclare
export type Theme = typeof Theme[keyof typeof Theme];

export const CountType = {
  /** 全文字数 */
  onlyCharacter: "onlyCharacter",
  /** 空白を含む全文字数 */
  addBlank: "addBlank",
  /** 改行を含む全文字数 */
  addNewLine: "addNewLine",
  /** 空白/改行を含む全文字数 */
  all: "all",
} as const;
// eslint-disable-next-line no-redeclare
export type CountType = typeof CountType[keyof typeof CountType];
