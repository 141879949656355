import { v4 as uuidv4 } from "uuid";
import { MaterialItem, MaterialItemEnum } from "../models";

export function initSinglelineItem(title: string, isRequired: boolean = true) {
  return initMaterialItem(MaterialItemEnum.TEXT_SINGLELINE, isRequired, {
    title,
  });
}

export function initMultilineItem() {
  return initMaterialItem(MaterialItemEnum.TEXT_MULTILINE, false, {});
}

export function initSquareItem() {
  return initMaterialItem(MaterialItemEnum.IMAGE_SQUARE, false, {
    ratioX: 1,
    ratioY: 1,
  });
}

export function initPortrateItem() {
  return initMaterialItem(MaterialItemEnum.IMAGE_PORTRATE, false, {
    ratioX: 5,
    ratioY: 7,
  });
}

export function initLandscapeItem() {
  return initMaterialItem(MaterialItemEnum.IMAGE_LANDSCAPE, false, {
    ratioX: 25,
    ratioY: 14,
  });
}

function initMaterialItem(
  type: MaterialItemEnum,
  isRequired: boolean,
  args?: { ratioX?: number; ratioY?: number; title?: string; value?: string; imagePath?: string }
): MaterialItem {
  return {
    id: uuidv4(),
    type,
    isRequired,
    ...args,
  };
}
