import { render, staticRenderFns } from "./GlobalComponent.vue?vue&type=template&id=d405b082&scoped=true"
import script from "./GlobalComponent.vue?vue&type=script&lang=ts"
export * from "./GlobalComponent.vue?vue&type=script&lang=ts"
import style0 from "./GlobalComponent.vue?vue&type=style&index=0&id=d405b082&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d405b082",
  null
  
)

export default component.exports