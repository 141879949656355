import gql from "graphql-tag";
import GraphQLClient from "../api";

interface IGeneralClient {
  createSampleData: () => Promise<Result>;
}

interface Result {
  novelId: string;
}

export class GeneralClient implements IGeneralClient {
  async createSampleData(): Promise<Result> {
    const { createSampleData } = await GraphQLClient.request({ query: createSampleDataQuery });
    return createSampleData;
  }
}

/**
 * Mutation
 */

const createSampleDataQuery = gql`
  mutation createSampleData {
    createSampleData {
      novelId
    }
  }
`;
