import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import {
  Plot,
  SubPlot,
  UpdatePlotPayload,
  UpdatePlotModePayload,
  CreateSubPlotPayload,
  UpdateSubPlotPayload,
  DeleteSubPlotPayload,
  GetPlotsByExternalUserInput,
  GetSubPlotsByExternalUserInput,
} from "../models/plot";

interface IPlotClient {
  /**
   * Plot
   */
  fetchPlots: (novelId: string) => Promise<Plot>;
  updatePlots: (payload: UpdatePlotPayload) => Promise<Plot>;
  fetchPlotsByExternalUser: (payload: GetPlotsByExternalUserInput) => Promise<Plot>;

  /**
   * SubPlot
   */
  fetchSubPlots: (novelId: string) => Promise<SubPlot[]>;
  createSubPlot: (payload: CreateSubPlotPayload) => Promise<SubPlot>;
  updateSubPlot: (payload: UpdateSubPlotPayload) => Promise<SubPlot>;
  deleteSubPlot: (payload: DeleteSubPlotPayload) => Promise<SubPlot>;
  fetchSubPlotsByExternalUser: (payload: GetSubPlotsByExternalUserInput) => Promise<SubPlot[]>;
}

export class PlotClient implements IPlotClient {
  /**
   * Plot
   */
  async fetchPlots(novelId: string): Promise<Plot> {
    const params: RequestParam = {
      query: fetchPlots,
      variables: {
        novelId,
      },
    };

    const { plots } = await GraphQLClient.request(params);
    return plots;
  }

  async fetchPlotsByExternalUser(payload: GetPlotsByExternalUserInput): Promise<Plot> {
    const { novelId, userId } = payload;

    const params: RequestParam = {
      query: fetchPlotsByExternalUser,
      variables: {
        input: {
          novelId,
          userId,
        },
      },
    };

    const { plotsByExternalUser } = await GraphQLClient.request(params);
    return plotsByExternalUser;
  }

  async updatePlots(payload: UpdatePlotPayload): Promise<Plot> {
    const { novelId, plotGroups, layout, displayFormat } = payload;
    const params: RequestParam = {
      query: updatePlotsQuery,
      variables: {
        input: {
          novelId,
          plotGroups,
          layout,
          displayFormat,
        },
      },
    };

    const { updateLinkedPlots } = await GraphQLClient.request(params);
    return updateLinkedPlots;
  }

  async updatePlotMode(payload: UpdatePlotModePayload): Promise<Plot> {
    const { novelId, plotGroups, mode, layout, displayFormat } = payload;
    const params: RequestParam = {
      query: updatePlotModeQuery,
      variables: {
        input: {
          novelId,
          plotGroups,
          mode,
          layout,
          displayFormat,
        },
      },
    };

    const { updateLinkedPlotsWithMode } = await GraphQLClient.request(params);
    return updateLinkedPlotsWithMode;
  }

  /**
   * SubPlot
   */
  async fetchSubPlots(novelId: string): Promise<SubPlot[]> {
    const params: RequestParam = {
      query: fetchSubPlots,
      variables: {
        input: {
          novelId,
        },
      },
    };

    const { subPlots } = await GraphQLClient.request(params);
    return subPlots.items;
  }

  async fetchSubPlotsByExternalUser(payload: GetSubPlotsByExternalUserInput): Promise<SubPlot[]> {
    const { novelId, userId } = payload;
    const params: RequestParam = {
      query: fetchSubPlotsByExternalUser,
      variables: {
        input: {
          novelId,
          userId,
        },
      },
    };

    const { subPlotsByExternalUser } = await GraphQLClient.request(params);
    return subPlotsByExternalUser.items;
  }

  async createSubPlot(payload: CreateSubPlotPayload): Promise<SubPlot> {
    const { novelId, name, plotGroups, mode, layout, displayFormat } = payload;
    const params: RequestParam = {
      query: createSubPlotQuery,
      variables: {
        input: {
          novelId,
          name,
          plotGroups,
          mode,
          layout,
          displayFormat,
        },
      },
    };

    const { createSubPlot } = await GraphQLClient.request(params);
    return createSubPlot;
  }

  async updateSubPlot(payload: UpdateSubPlotPayload): Promise<SubPlot> {
    const { novelId, plotId, name, plotGroups, mode, layout, displayFormat } = payload;
    const params: RequestParam = {
      query: updateSubPlotQuery,
      variables: {
        input: {
          novelId,
          plotId,
          name,
          plotGroups,
          mode,
          layout,
          displayFormat,
        },
      },
    };

    const { updateLinkedSubPlot } = await GraphQLClient.request(params);
    return updateLinkedSubPlot;
  }

  async deleteSubPlot(payload: DeleteSubPlotPayload): Promise<SubPlot> {
    const { novelId, plotId } = payload;
    const params: RequestParam = {
      query: deleteSubPlotQuery,
      variables: {
        input: {
          novelId,
          plotId,
        },
      },
    };

    const { deleteSubPlot } = await GraphQLClient.request(params);
    return deleteSubPlot;
  }
}

/**
 * Query
 */

const fetchPlots = gql`
  query Plots($novelId: ID!) {
    plots(novelId: $novelId) {
      novelId
      plotGroups {
        name
        description
        plots {
          key
          text
          linkedPlotKey
        }
      }
      mode
      layout
      displayFormat
    }
  }
`;

const fetchPlotsByExternalUser = gql`
  query PlotsByExternalUser($input: GetPlotsByExternalUserInput!) {
    plotsByExternalUser(input: $input) {
      novelId
      plotGroups {
        name
        description
        plots {
          key
          text
          linkedPlotKey
        }
      }
      mode
      layout
      displayFormat
    }
  }
`;

const fetchSubPlots = gql`
  query SubPlots($input: ListSubPlotInput!) {
    subPlots(input: $input) {
      items {
        novelId
        plotId
        name
        plotGroups {
          name
          description
          plots {
            key
            text
            linkedPlotKey
          }
        }
        mode
        layout
        displayFormat
        createdAt
        updatedAt
      }
    }
  }
`;

const fetchSubPlotsByExternalUser = gql`
  query subPlotsByExternalUser($input: GetSubPlotsByExternalUserInput!) {
    subPlotsByExternalUser(input: $input) {
      items {
        novelId
        plotId
        name
        plotGroups {
          name
          description
          plots {
            key
            text
            linkedPlotKey
          }
        }
        mode
        layout
        displayFormat
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * Mutation
 */

const updatePlotsQuery = gql`
  mutation UpdatePlots($input: UpdatePlotsInput!) {
    updateLinkedPlots(input: $input) {
      novelId
      plotGroups {
        name
        description
        plots {
          key
          text
          linkedPlotKey
        }
      }
      mode
      layout
      displayFormat
    }
  }
`;

const updatePlotModeQuery = gql`
  mutation UpdatePlots($input: UpdatePlotsInput!) {
    updateLinkedPlotsWithMode(input: $input) {
      novelId
      plotGroups {
        name
        description
        plots {
          key
          text
          linkedPlotKey
        }
      }
      mode
      layout
      displayFormat
    }
  }
`;

const createSubPlotQuery = gql`
  mutation CreateSubPlot($input: CreateSubPlotInput!) {
    createSubPlot(input: $input) {
      novelId
      plotId
      name
      plotGroups {
        name
        description
        plots {
          key
          text
        }
      }
      mode
      layout
      displayFormat
      createdAt
      updatedAt
    }
  }
`;

const updateSubPlotQuery = gql`
  mutation UpdateSubPlot($input: UpdateSubPlotInput!) {
    updateLinkedSubPlot(input: $input) {
      novelId
      plotId
      name
      plotGroups {
        name
        description
        plots {
          key
          text
          linkedPlotKey
        }
      }
      mode
      layout
      displayFormat
      createdAt
      updatedAt
    }
  }
`;

const deleteSubPlotQuery = gql`
  mutation DeleteSubPlot($input: DeleteSubPlotInput!) {
    deleteSubPlot(input: $input) {
      novelId
      plotId
      name
      plotGroups {
        name
        description
        plots {
          key
          text
        }
      }
      mode
      layout
      displayFormat
      createdAt
      updatedAt
    }
  }
`;
