import { Store } from "vuex";
import { Plan, User } from "../models";

export async function isBilling(store: Store<any>) {
  const { dispatch, getters } = store;
  let user = getters["userModule/user"] as User;
  if (user.userId) {
    const plan = getters["userModule/plan"] as Plan;
    return plan !== Plan.free;
  }

  await dispatch("userModule/initialize");
  user = getters["userModule/user"];

  if (!user) {
    return false;
  }

  const plan = getters["userModule/plan"] as Plan;
  return plan !== Plan.free;
}
