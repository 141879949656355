import GraphQLClient, { RequestParam } from "../api";
import { Memo, MemoTag } from "../models";

interface IMemoClient {
  /**
   * すべてのメモを取得
   */
  fetchAllMemo: () => Promise<Memo[]>;

  /**
   * すべてのメモタグを取得
   */
  fetchAllMemoTag: () => Promise<MemoTag[]>;

  /**
   * メモの作成
   */
  createMemo: (id: string, novelId: string | null | undefined, s3BucketPath: string) => Promise<Memo>;

  /**
   * メモの更新
   */
  updateMemo: (memo: Memo) => Promise<Memo>;

  /**
   * メモの削除
   */
  deleteMemo: (id: string) => Promise<string>;

  /**
   * メモタグの作成
   */
  createMemoTag: (id: string, name: string) => Promise<MemoTag>;

  /**
   * メモタグの削除
   */
  deleteMemoTag: (memoTag: MemoTag) => Promise<string>;
}

export class MemoClient implements IMemoClient {
  async fetchAllMemo(): Promise<Memo[]> {
    const { memoList } = await GraphQLClient.request({ query: fetchAllMemo });
    return memoList;
  }

  async fetchAllMemoTag(): Promise<MemoTag[]> {
    const { memoTagList } = await GraphQLClient.request({ query: fetchAllMemoTag });
    return memoTagList;
  }

  async createMemo(id: string, novelId: string | null | undefined, s3BucketPath: string) {
    const params: RequestParam = {
      query: createMemo,
      variables: {
        create: {
          id,
          title: "",
          novelId,
          items: [],
          s3BucketPath,
        },
      },
    };

    const response = await GraphQLClient.request(params);
    if (response.createMemo) {
      return response.createMemo;
    }

    return null;
  }

  async updateMemo(memo: Memo) {
    const { id, title, novelId, items } = memo;
    const params: RequestParam = {
      query: updateMemo,
      variables: {
        update: {
          id,
          title,
          novelId,
          items: items ?? [],
        },
      },
    };

    const response = await GraphQLClient.request(params);
    if (response.updateMemo) {
      return response.updateMemo;
    }

    return null;
  }

  async deleteMemo(id: string) {
    const params: RequestParam = {
      query: deleteMemo,
      variables: {
        id,
      },
    };

    const response = await GraphQLClient.request(params);
    if (response.deleteMemo) {
      return response.deleteMemo.id;
    }

    return null;
  }

  async createMemoTag(id: string, name: string) {
    const params: RequestParam = {
      query: createMemoTag,
      variables: {
        create: {
          id,
          name,
          createdAt: new Date().toISOString(),
        },
      },
    };

    const response = await GraphQLClient.request(params);
    if (response.createMemoTag) {
      return response.createMemoTag;
    }

    return null;
  }

  async deleteMemoTag(memoTag: MemoTag) {
    const params: RequestParam = {
      query: deleteMemoTag,
      variables: {
        id: memoTag.id,
      },
    };

    const response = await GraphQLClient.request(params);
    if (response.deleteMemoTag) {
      return response.deleteMemoTag.id;
    }

    return null;
  }
}

/**
 * Query
 */

const fetchAllMemo = `{
  memoList {
    id
    novelId
    title
    items
    s3BucketPath
    createdAt
    updatedAt
  }
}`;

const fetchAllMemoTag = `{
  memoTagList {
    id
    name
    createdAt
    updatedAt
  }
}`;

/**
 * Mutation
 */

const createMemo = `
mutation CreateMemo($create: CreateMemoInput!) {
  createMemo(input: $create) {
    id
    novelId
    title
    items
    s3BucketPath
    createdAt
    updatedAt
  }
}`;

const updateMemo = `
mutation UpdateMemo($update: UpdateMemoInput!) {
  updateMemo(input: $update){
    id
    novelId
    title
    items
    s3BucketPath
    createdAt
    updatedAt
  }
}`;

const deleteMemo = `
mutation DeleteMemo($id: ID!) {
  deleteMemo(id: $id) {
    id
  }
}`;

const createMemoTag = `
mutation CreateMemoTag($create: CreateMemoTagInput!) {
  createMemoTag(input: $create) {
    id
    name
    createdAt
    updatedAt
  }
}`;

const deleteMemoTag = `
mutation DeleteMemoTag($id: ID!) {
  deleteMemoTag(id: $id) {
    id
  }
}`;
