/* eslint-disable no-param-reassign */
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { TimelineClient } from "@/lib/clients";
import {
  Timeline,
  TimelineType,
  TableHeader,
  TableBody,
  BodyItem,
  ListTimelinePayload,
  CreateTimelinePayload,
  UpdateTimelinePayload,
  DeleteTimelinePayload,
  SyncTimelinePayload,
} from "@/lib/models";
import { TimelineSelectBoxItem } from "@/lib/timeline";
import { Color } from "@/lib/colorPicker";
import { v4 as uuidv4 } from "uuid";
import { compareAsc } from "date-fns";

const timelineClient = new TimelineClient();

// Stateの型定義
export interface TimelineState {
  timelines: Timeline[];
}

type Getters = {
  timelines(state: TimelineState): Timeline[];
  timeline(state: TimelineState): (timelineId: string) => Timeline | undefined;
};

/** Action Interface */
interface AddHeaderPayload {
  timelineId: string;
}

interface AddBodyPayload {
  timelineId: string;
  kind: TimelineSelectBoxItem;
  item: TimelineSelectBoxItem | null;
  headers: TableHeader[];
}

export interface UpdateHeaderPayload {
  timelineId: string;
  headers: TableHeader[];
}

export interface UpdateBodyPayload {
  timelineId: string;
  bodies: TableBody[];
}

export interface DeleteHeaderPayload {
  timelineId: string;
  header: TableHeader;
}

export interface DeleteBodyPayload {
  timelineId: string;
  body: TableBody;
}

export interface UpdateHeaderNamePayload {
  timelineId: string;
  headerName: string;
  headerId: string;
}

export interface UpdateBodyItemPayload {
  timelineId: string;
  value: string | null;
  bodyId: string;
  bodyItemId: string;
  isColor?: boolean;
}

/** Mutation Interface */
interface PushHeaderPayload {
  timelineId: string;
  header: TableHeader;
}

interface PushBodyPayload {
  timelineId: string;
  body: TableBody;
}

export interface PushBodyItemPayload {
  timelineId: string;
  bodyItem: BodyItem;
}

interface PopHeaderPayload {
  timelineId: string;
  headerId: string;
}

interface PopBodyPayload {
  timelineId: string;
  bodyId: string;
}

export interface InterpolateBodyItemPayload {
  timelineId: string;
  key: string;
}

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: ListTimelinePayload) => any;
  destroy: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>) => any;
  createTimeline: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: CreateTimelinePayload) => any;
  updateTimeline: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: UpdateTimelinePayload) => any;
  deleteTimeline: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: DeleteTimelinePayload) => any;
  syncTimeline: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: SyncTimelinePayload) => any;
  addHeader: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: AddHeaderPayload) => any;
  addBody: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: AddBodyPayload) => any;
  deleteHeader: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: DeleteHeaderPayload) => any;
  deleteBody: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: DeleteBodyPayload) => any;
  updateHeader: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: UpdateHeaderPayload) => any;
  updateBody: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: UpdateBodyPayload) => any;
  updateHeaderName: (
    this: Store<{}>,
    injectee: ActionContext<TimelineState, {}>,
    payload: UpdateHeaderNamePayload
  ) => any;
  updateBodyItem: (this: Store<{}>, injectee: ActionContext<TimelineState, {}>, payload: UpdateBodyItemPayload) => any;
  interpolateBodyItem: (
    this: Store<{}>,
    injectee: ActionContext<TimelineState, {}>,
    payload: InterpolateBodyItemPayload
  ) => any;
};

const mutations: MutationTree<TimelineState> = {
  setTimelines(state, payload: Timeline[]) {
    return (state.timelines = payload);
  },
  pushTimeline(state, payload: Timeline) {
    state.timelines.push(payload);
    return state.timelines;
  },
  updateTimeline(state, payload: Timeline) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === payload.timelineId) return payload;
      return timeline;
    }));
  },
  popTimeline(state, payload: Timeline) {
    return (state.timelines = state.timelines.filter((timeline) => timeline.timelineId !== payload.timelineId));
  },
  pushHeader(state, { timelineId, header }: PushHeaderPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) timeline.tableHeaders!.push(header);
      return timeline;
    }));
  },
  popHeader(state, { timelineId, headerId }: PopHeaderPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableHeaders! = timeline.tableHeaders!.filter((header) => header.id !== headerId);
      }
      return timeline;
    }));
  },
  pushBody(state, { timelineId, body }: PushBodyPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) timeline.tableBodies.push(body);
      return timeline;
    }));
  },
  popBody(state, { timelineId, bodyId }: PopBodyPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId)
        // eslint-disable-next-line eqeqeq
        timeline.tableBodies = timeline.tableBodies.filter((body) => body.id != bodyId);
      return timeline;
    }));
  },
  pushBodyItem(state, { timelineId, bodyItem }: PushBodyItemPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) timeline.tableBodies.forEach((body) => body.items.push({ ...bodyItem }));
      return timeline;
    }));
  },
  popBodyItem(state, { timelineId, headerId }: PopHeaderPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableBodies = timeline.tableBodies.map((body) => {
          const { id, kind, items } = body;
          return {
            id,
            kind,
            items: items.filter((item) => item.key !== headerId),
          } as TableBody;
        });
      }
      return timeline;
    }));
  },
  updateHeader(state, { timelineId, headers }: UpdateHeaderPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableHeaders = headers;
      }
      return timeline;
    }));
  },
  updateBody(state, { timelineId, bodies }: UpdateBodyPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableBodies = bodies;
      }
      return timeline;
    }));
  },
  updateHeaderName(state, { timelineId, headerName, headerId }: UpdateHeaderNamePayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableHeaders = timeline.tableHeaders!.map((header) => {
          if (header.id === headerId) header.name = headerName;
          return header;
        });
      }
      return timeline;
    }));
  },
  updateBodyItem(state, { timelineId, value, bodyId, bodyItemId, isColor }: UpdateBodyItemPayload) {
    return (state.timelines = state.timelines.map((timeline) => {
      if (timeline.timelineId === timelineId) {
        timeline.tableBodies = timeline.tableBodies.map((body) => {
          if (body.id === bodyId) {
            body.items = body.items.map((item) => {
              if (item.key === bodyItemId) {
                if (isColor) item.color = value as Color;
                else item.value = value!;
              }
              return item;
            });
          }
          return body;
        });
      }
      return timeline;
    }));
  },
};

const actions: ActionTree<TimelineState, {}> & Actions = {
  async initialize({ commit }, payload) {
    const timelines = await timelineClient.fetchTimelines(payload);
    timelines.sort((x, y) => compareAsc(x.createdAt!, y.createdAt!)); // createdAtで昇順にソート
    commit("setTimelines", timelines);
  },
  async destroy({ commit }) {
    commit("setTimelines", []);
  },
  async createTimeline({ commit }, payload) {
    const { callback } = payload;

    const timeline = await timelineClient.createTimeline(payload);
    commit("pushTimeline", timeline);

    if (callback) callback(timeline.timelineId);
  },
  async updateTimeline({ commit }, payload) {
    const { callback } = payload;

    const timeline = await timelineClient.updateTimeline(payload);
    commit("updateTimeline", timeline);

    if (callback) callback();
  },
  async deleteTimeline({ commit }, payload) {
    const { callback } = payload;

    const timeline = await timelineClient.deleteTimeline(payload);
    commit("popTimeline", timeline);

    if (callback) callback();
  },
  async syncTimeline({ commit, state }, payload) {
    const { timelineId } = payload;
    const { timelines } = state;
    const targetTimeline = timelines.find((timeline) => timeline.timelineId === timelineId);

    if (!targetTimeline) return;
    const timeline = await timelineClient.updateTimeline(targetTimeline);
    commit("updateTimeline", timeline);
  },
  addHeader({ commit }, payload) {
    const { timelineId } = payload;
    const id = uuidv4();
    const header: TableHeader = {
      id,
      name: "",
    };
    commit("pushHeader", { timelineId, header } as PushHeaderPayload);

    const bodyItem: BodyItem = {
      key: id,
      value: "",
      color: Color.black,
    };
    commit("pushBodyItem", { timelineId, bodyItem } as PushBodyItemPayload);
  },
  addBody({ commit, state }, payload) {
    const { timelineId, kind, item, headers } = payload;

    let id = uuidv4();

    const kindId = kind.id;
    if ((kindId === "character" || kindId === "material") && item) {
      id = item.id;
    }

    const currentTimeline = state.timelines.find((timeline) => timeline.timelineId === timelineId);
    if (!currentTimeline) return;

    let { tableHeaders } = currentTimeline;
    if (currentTimeline.timelineType === TimelineType.plot) tableHeaders = headers;

    const items: BodyItem[] = tableHeaders!.map(
      (header) =>
        ({
          key: header.id,
          value: "",
          color: Color.black,
        } as BodyItem)
    );
    const body: TableBody = {
      id,
      kind: kindId,
      items,
    };
    commit("pushBody", { timelineId, body } as PushBodyPayload);
  },
  updateHeader({ commit }, payload) {
    commit("updateHeader", payload);
  },
  updateBody({ commit }, payload) {
    commit("updateBody", payload);
  },
  deleteHeader({ commit }, payload) {
    const { timelineId, header } = payload;
    commit("popHeader", { timelineId, headerId: header.id } as PopHeaderPayload);
    commit("popBodyItem", { timelineId, headerId: header.id } as PopHeaderPayload);
  },
  deleteBody({ commit }, payload) {
    const { timelineId, body } = payload;
    commit("popBody", { timelineId, bodyId: body.id } as PopBodyPayload);
  },
  updateHeaderName({ commit }, payload) {
    commit("updateHeaderName", payload);
  },
  updateBodyItem({ commit }, payload) {
    commit("updateBodyItem", payload);
  },
  interpolateBodyItem({ commit, dispatch }, payload) {
    const { timelineId, key } = payload;
    const bodyItem: BodyItem = {
      key,
      value: "",
      color: Color.black,
    };
    commit("pushBodyItem", { timelineId, bodyItem } as PushBodyItemPayload);

    const syncTimelinePayload: SyncTimelinePayload = {
      timelineId,
    };
    dispatch("syncTimeline", syncTimelinePayload);
  },
};

const getters: GetterTree<TimelineState, {}> & Getters = {
  timelines: (state) => state.timelines,
  timeline: (state) => (timelineId: string) => state.timelines.find((timeline) => timeline.timelineId === timelineId),
};

export default {
  namespaced: true,
  state: {
    timelines: [],
  },
  getters,
  actions,
  mutations,
};
