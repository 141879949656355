import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { db, GeneralSettings as GeneralSettingsDexie } from "@/lib/indexeddb";
import clone from "lodash/cloneDeep";
import { GeneralClient } from "@/lib/clients";
import { News } from "@/lib/models/general";

const generalClient = new GeneralClient();

// Stateの型定義
export interface GeneralState {
  readPopupList: string[];
  readLatestNews: News;
  latestNews: News;
  agentPopupDisplayedNovelIds: string[];
}

type Getters = {
  readPopupList(state: GeneralState): string[];
  readLatestNews(state: GeneralState): News;
  latestNews(state: GeneralState): News;
  isAgentPopupDisplayedForNovel(state: GeneralState): (novelId: string) => boolean;
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>) => any;
  readPopup: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>, payload: string) => any;
  createSampleData: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>) => any;
  readNews: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>, payload: News) => Promise<void>;
};

const mutations: MutationTree<GeneralState> = {
  setReadPopupList(state, payload: string[]) {
    return (state.readPopupList = payload);
  },
  setReadLatestNews(state, payload: News) {
    return (state.readLatestNews = payload);
  },
  setLatestNews(state, payload: News) {
    return (state.latestNews = payload);
  },
  setAgentPopupDisplayedNovelIds(state, payload: string[]) {
    return (state.agentPopupDisplayedNovelIds = payload);
  },
};

const actions: ActionTree<GeneralState, {}> & Actions = {
  async initialize({ commit }) {
    const readPopupListInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("readPopupList")
    );
    if (readPopupListInIndexedDB) {
      commit("setReadPopupList", readPopupListInIndexedDB.value);
    }

    const readLatestNewsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("readLatestNews")
    );
    if (readLatestNewsInIndexedDB) {
      commit("setReadLatestNews", readLatestNewsInIndexedDB.value);
    }

    const agentPopupDisplayedNovelIdsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("agentPopupDisplayedNovelIds")
    );
    if (agentPopupDisplayedNovelIdsInIndexedDB) {
      commit("setAgentPopupDisplayedNovelIds", agentPopupDisplayedNovelIdsInIndexedDB.value);
    }
  },
  async readPopup({ commit, state }, payload) {
    const { readPopupList } = state;
    const cloneReadPopupList = clone(readPopupList);
    cloneReadPopupList.push(payload);

    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(cloneReadPopupList), "readPopupList");
    });

    commit("setReadPopupList", cloneReadPopupList);
  },
  async createSampleData() {
    await generalClient.createSampleData();
  },
  async readNews({ commit }, payload) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(payload), "readLatestNews");
    });

    commit("setReadLatestNews", payload);
  },
  async addDisplayedAgentPopupForNovel({ commit, state }, novelId) {
    const agentPopupDisplayedNovelIds = state.agentPopupDisplayedNovelIds || [];

    if (!agentPopupDisplayedNovelIds.includes(novelId)) {
      const updatedNovelIds = [...agentPopupDisplayedNovelIds, novelId];

      await db.transaction("readwrite", db.generalSettings, async () => {
        await db.generalSettings.put({ value: updatedNovelIds }, "agentPopupDisplayedNovelIds");
      });

      commit("setAgentPopupDisplayedNovelIds", updatedNovelIds);
    }
  },
};

const getters: GetterTree<GeneralState, {}> & Getters = {
  readPopupList: (state) => state.readPopupList,
  readLatestNews: (state) => state.readLatestNews,
  latestNews: (state) => state.latestNews,
  isAgentPopupDisplayedForNovel: (state) => (novelId: string) => {
    const agentPopupDisplayedNovelIds = state.agentPopupDisplayedNovelIds || [];
    return agentPopupDisplayedNovelIds.includes(novelId);
  },
};

export default {
  namespaced: true,
  state: {
    readPopupList: [],
    readLatestNews: null,
    latestNews: null,
    agentPopupDisplayedNovelIds: [],
  },
  getters,
  actions,
  mutations,
};
