import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import {
  Character,
  CharacterFolder,
  CreateCharacter,
  CreateCharacterFolder,
  DeleteCharacter,
  ListCharacterInFolder,
  UpdateCharacter,
  UpdateCharacterFolder,
  CopyCharacter,
} from "../models";

interface ICharacterClient {
  fetchAllCharacter: (novelId: string) => Promise<Character[]>;
  fetchCharactersInFolder: ({ novelId, characterFolderId }: ListCharacterInFolder) => Promise<Character[]>;
  fetchAllCharacterFolder: (novelId: string) => Promise<CharacterFolder[]>;
  createCharacter: (payload: CreateCharacter) => Promise<Character>;
  updateCharacter: (payload: UpdateCharacter) => Promise<Character>;
  deleteCharacter: (payload: DeleteCharacter) => Promise<Character>;
  createCharacterFolder: ({ novelId, name }: CreateCharacterFolder) => Promise<CharacterFolder>;
  updateCharacterFolder: ({ novelId, characterFolders }: UpdateCharacterFolder) => Promise<CharacterFolder[]>;
  copyCharacter: (payload: CopyCharacter) => Promise<Character>;
}

export class CharacterClient implements ICharacterClient {
  async fetchAllCharacter(novelId: string): Promise<Character[]> {
    const params: RequestParam = {
      query: fetchAllCharacter,
      variables: {
        input: {
          novelId,
        },
      },
    };

    const { characters } = await GraphQLClient.request(params);
    return characters.items;
  }

  async fetchCharactersInFolder({ novelId, characterFolderId }: ListCharacterInFolder): Promise<Character[]> {
    const params: RequestParam = {
      query: fetchCharactersInFolder,
      variables: {
        input: {
          novelId,
          characterFolderId,
        },
      },
    };

    const { folderCharacters } = await GraphQLClient.request(params);
    return folderCharacters.items;
  }

  async fetchAllCharacterFolder(novelId: string): Promise<CharacterFolder[]> {
    const params: RequestParam = {
      query: fetchAllCharacterFolder,
      variables: {
        novelId,
      },
    };

    const { characterFolders } = await GraphQLClient.request(params);
    return characterFolders.items;
  }

  async createCharacter(payload: CreateCharacter): Promise<Character> {
    const params: RequestParam = {
      query: createCharacter,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { createCharacter: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateCharacter(payload: UpdateCharacter): Promise<Character> {
    const params: RequestParam = {
      query: updateCharacter,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { updateCharacter: result } = await GraphQLClient.request(params);
    return result;
  }

  async deleteCharacter(payload: DeleteCharacter): Promise<Character> {
    const params: RequestParam = {
      query: deleteCharacter,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { deleteCharacter: result } = await GraphQLClient.request(params);
    return result;
  }

  async createCharacterFolder({ novelId, name }: CreateCharacterFolder): Promise<CharacterFolder> {
    const params: RequestParam = {
      query: createCharacterFolder,
      variables: {
        input: {
          novelId,
          name,
        },
      },
    };

    const { createCharacterFolder: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateCharacterFolder({ novelId, characterFolders }: UpdateCharacterFolder): Promise<CharacterFolder[]> {
    const params: RequestParam = {
      query: updateCharacterFolder,
      variables: {
        input: {
          novelId,
          items: characterFolders,
        },
      },
    };

    const { updateCharacterFolder: result } = await GraphQLClient.request(params);
    return result.items;
  }

  async copyCharacter(payload: CopyCharacter): Promise<Character> {
    const params: RequestParam = {
      query: copyCharacter,
      variables: {
        input: {
          ...payload,
        },
      },
    };

    const { copyCharacter: result } = await GraphQLClient.request(params);
    return result;
  }
}

/**
 * Query
 */

const fetchAllCharacter = gql`
  query ListCharacter($input: ListCharacterInput!) {
    characters(input: $input) {
      nextToken
      items {
        novelId
        characterId
        name
        image
        createdAt
        updatedAt
        detail {
          name
          value
        }
        additionalColumn {
          name
          label
          content
        }
      }
    }
  }
`;

const fetchCharactersInFolder = gql`
  query ListCharacterInFolder($input: GetFolderCharactersInput!) {
    folderCharacters(input: $input) {
      items {
        novelId
        characterId
        name
        image
        createdAt
        updatedAt
        detail {
          name
          value
        }
        additionalColumn {
          name
          label
          content
        }
      }
    }
  }
`;

const fetchAllCharacterFolder = gql`
  query ListCharacterFolder($novelId: ID!) {
    characterFolders(novelId: $novelId) {
      novelId
      items {
        characterFolderId
        name
        characterKeys
      }
    }
  }
`;

const createCharacter = gql`
  mutation CreateCharacter($input: CreateCharacterInput!) {
    createCharacter(input: $input) {
      novelId
      characterId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const updateCharacter = gql`
  mutation UpdateCharacter($input: UpdateCharacterInput!) {
    updateCharacter(input: $input) {
      novelId
      characterId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const deleteCharacter = gql`
  mutation Delete($input: DeleteCharacterInput!) {
    deleteCharacter(input: $input) {
      novelId
      characterId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;

const createCharacterFolder = gql`
  mutation CreateCharacterFolder($input: CreateCharacterFolderInput!) {
    createCharacterFolder(input: $input) {
      characterFolderId
      name
    }
  }
`;

const updateCharacterFolder = gql`
  mutation UpdateCharacterFolder($input: UpdateCharacterFolderInput!) {
    updateCharacterFolder(input: $input) {
      novelId
      items {
        characterFolderId
        name
        characterKeys
      }
    }
  }
`;

const copyCharacter = gql`
  mutation CopyCharacter($input: CopyCharacterInput!) {
    copyCharacter(input: $input) {
      novelId
      characterId
      name
      image
      createdAt
      updatedAt
      detail {
        name
        value
      }
      additionalColumn {
        name
        label
        content
      }
    }
  }
`;
