import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { PlotClient, DisplayOrderClient } from "@/lib/clients";
import {
  Plot,
  Plots,
  SubPlot,
  DisplayFormat,
  UpdatePlotPayload,
  UpdatePlotModePayload,
  CreateSubPlotPayload,
  UpdateSubPlotPayload,
  UpdateSubPlotSortOrderPayload,
  DeleteSubPlotPayload,
} from "@/lib/models/plot";
import { BodyItem, SyncTimelinePayload } from "@/lib/models/timeline";
import { SortOrder, Kind } from "@/lib/models/sortOrder";
import { v4 as uuidv4 } from "uuid";
import { Color } from "@/lib/colorPicker";
import { reOrderData, createdAtSort } from "@/lib/utils";
import clone from "lodash/cloneDeep";
import { PushBodyItemPayload } from "./timeline";

const plotClient = new PlotClient();
const displayOrderClient = new DisplayOrderClient();

// Stateの型定義
export interface PlotState {
  plots: Plot;
  subPlots: SubPlot[];
  subPlotsSortOrder: SortOrder;
  displayFormat: DisplayFormat;
  isHamburgerMenuOpen: boolean;
}

type Getters = {
  plots(state: PlotState): Plot;
  subPlots(state: PlotState): SubPlot[];
  subPlot(state: PlotState): (plotId: string) => SubPlot | undefined;
  subPlotsSortOrder(state: PlotState): SortOrder;
  displayFormat(state: PlotState): DisplayFormat;
  isHamburgerMenuOpen(state: PlotState): boolean;
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: string) => any;
  updatePlot: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: UpdatePlotPayload) => any;
  updatePlotMode: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: UpdatePlotModePayload) => any;
  interpolateEmptyPlots: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: string) => any;
  createSubPlot: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: CreateSubPlotPayload) => any;
  updateSubPlot: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: UpdateSubPlotPayload) => any;
  updateSubPlotSortOrder: (
    this: Store<{}>,
    injectee: ActionContext<PlotState, {}>,
    payload: UpdateSubPlotSortOrderPayload
  ) => any;
  deleteSubPlot: (this: Store<{}>, injectee: ActionContext<PlotState, {}>, payload: DeleteSubPlotPayload) => any;
};

const mutations: MutationTree<PlotState> = {
  setPlots(state, payload) {
    return (state.plots = payload);
  },
  setSubPlots(state, payload) {
    return (state.subPlots = payload);
  },
  setSubPlotsSortOrder(state, payload: SortOrder) {
    return (state.subPlotsSortOrder = payload);
  },
  pushSubPlot(state, payload: SubPlot) {
    state.subPlots.push(payload);
    return state.subPlots;
  },
  updateSubPlot(state, payload: SubPlot) {
    return (state.subPlots = state.subPlots.map((subPlot) => {
      if (subPlot.plotId === payload.plotId) return payload;
      return subPlot;
    }));
  },
  popSubPlot(state, payload: SubPlot) {
    return (state.subPlots = state.subPlots.filter((subPlot) => subPlot.plotId !== payload.plotId));
  },
  setIsHamburgerMenuOpen(state, payload: boolean) {
    return (state.isHamburgerMenuOpen = payload);
  },
};

const actions: ActionTree<PlotState, {}> & Actions = {
  async initialize({ dispatch }, novelId: string) {
    await Promise.all([
      dispatch("fetchPlots", novelId),
      dispatch("fetchSubPlots", novelId),
      dispatch("fetchSubPlotsDisplayOrder", novelId),
    ]);
  },
  async fetchPlots({ commit }, novelId) {
    const plots = await plotClient.fetchPlots(novelId);
    commit("setPlots", plots);
  },
  async fetchSubPlots({ commit }, novelId) {
    const subPlots = await plotClient.fetchSubPlots(novelId);
    commit("setSubPlots", subPlots);
  },
  async fetchSubPlotsDisplayOrder({ commit }, novelId) {
    const displayOrder = await displayOrderClient.fetchDisplayOrder({
      novelId,
      kind: Kind.PLOT,
    });
    commit("setSubPlotsSortOrder", displayOrder);
  },
  async updatePlot({ commit }, payload) {
    const updatePlots = await plotClient.updatePlots(payload);
    commit("setPlots", updatePlots);
  },
  async updatePlotMode({ commit }, payload) {
    const updatePlotMode = await plotClient.updatePlotMode(payload);
    commit("setPlots", updatePlotMode);
  },
  async interpolateEmptyPlots({ commit, dispatch, state }, payload: string) {
    const { novelId, plotGroups, layout, displayFormat } = state.plots;

    const interpolatedEmptyPlots = plotGroups.map((plotGroup) => {
      const newPlotGroup = { ...plotGroup };
      if (plotGroup.plots.length === 0) {
        const id = uuidv4();
        const initialPlot: Plots = {
          key: id,
          text: "（時系列から自動で追加）",
          linkedPlotKey: "",
        };
        newPlotGroup.plots = [initialPlot];

        /** storeの時系列に空のボディアイテムを追加 */
        const timelineId = payload;
        const bodyItem: BodyItem = {
          key: id,
          value: "",
          color: Color.black,
        };
        const newBodyItem: PushBodyItemPayload = {
          timelineId,
          bodyItem,
        };
        commit("timelineModule/pushBodyItem", newBodyItem, { root: true });

        /** 現在のstoreデータをDBに書き込む */
        const syncTimelinePayload: SyncTimelinePayload = {
          timelineId,
        };
        dispatch("timelineModule/syncTimeline", syncTimelinePayload, { root: true });
      }
      return newPlotGroup;
    });
    const updatePlots = await plotClient.updatePlots({
      novelId,
      plotGroups: interpolatedEmptyPlots,
      layout,
      displayFormat,
    });

    commit("setPlots", updatePlots);
  },
  async createSubPlot({ commit }, payload) {
    const { callback } = payload;

    const subPlot = await plotClient.createSubPlot(payload);
    commit("pushSubPlot", subPlot);

    if (callback) callback(subPlot.plotId);
  },
  async updateSubPlot({ commit }, payload) {
    const subPlot = await plotClient.updateSubPlot(payload);
    commit("updateSubPlot", subPlot);
  },
  async updateSubPlotSortOrder({ commit }, payload) {
    const displayOrder = await displayOrderClient.updateDisplayOrder({
      ...payload,
      kind: Kind.PLOT,
    });
    commit("setSubPlotsSortOrder", displayOrder);
  },
  async deleteSubPlot({ commit }, payload) {
    const { callback } = payload;

    const subPlot = await plotClient.deleteSubPlot(payload);
    commit("popSubPlot", subPlot);

    if (callback) callback();
  },
};

const getters: GetterTree<PlotState, {}> & Getters = {
  plots: (state) => state.plots,
  subPlots: (state) => {
    const { subPlots, subPlotsSortOrder } = state;

    if (subPlotsSortOrder && subPlotsSortOrder.order.length) {
      return reOrderData(subPlots, subPlotsSortOrder.order, "plotId");
    }
    return clone(subPlots).sort((a, b) => createdAtSort(a, b, "createdAsc"));
  },
  subPlot: (state) => (plotId) => state.subPlots.find((subPlot) => subPlot.plotId === plotId),
  subPlotsSortOrder: (state) => state.subPlotsSortOrder,
  displayFormat: (state) => state.displayFormat,
  isHamburgerMenuOpen: (state) => state.isHamburgerMenuOpen,
};

export default {
  namespaced: true,
  state: {
    plots: null,
    subPlots: [],
    subPlotsSortOrder: null,
    displayFormat: DisplayFormat.HORIZONTAL,
    isHamburgerMenuOpen: false,
  },
  getters,
  actions,
  mutations,
};
