
import Vue from "vue";
import * as s3 from "@/lib/s3";

export default Vue.extend({
  data() {
    return {
      presetImages: [],
      loadingImage: "/img/placeholders/character.png",
      loadingList: false,
      queueingNext: false,
      nextMarker: null,
    };
  },
  created() {
    this.loadNext();
  },
  methods: {
    selected(img) {
      this.$close(img);
    },
    async showPresetImage(e) {
      await this.loadNext();
    },
    async loadNext() {
      this.queueingNext = false;
      this.loadingList = true;
      const { contents, nextMarker } = await s3.listPublicImages(this.nextMarker);
      this.loadingList = false;
      this.nextMarker = nextMarker;

      if (this.queueingNext) {
        setTimeout(this.loadNext, 0);
      }

      this.presetImages.push(...contents);

      // await Promise.all(
      //   contents.map(async (img: any, index) => {
      //     const res = await s3.downloadPublicImage(img.key);
      //     this.$set(img, "data", res);
      //   })
      // );
      setTimeout(this.loadImage, 1);
    },
    loadPrepare() {
      if (this.loadingList) {
        this.queueingNext = true;
      } else {
        this.loadNext();
      }
    },
    cancel() {
      this.$close(false);
    },
    onScroll(e) {
      const { target } = e;
      const bottom = target.scrollTop + target.clientHeight;
      const bottomDistance = target.scrollHeight - bottom;
      if (bottomDistance < 10) {
        this.loadPrepare();
      }

      this.loadImage();
    },
    loadImage() {
      const contents = this.$refs.contents as HTMLDivElement;

      Array.from(contents.children).forEach((elem) => {
        const { dataset } = elem as HTMLAnchorElement;

        const { image, load, index } = dataset;
        if (load === "false") {
          const rects = elem.getBoundingClientRect();
          if (rects.top <= contents.clientHeight + 100) {
            dataset.load = "true";

            s3.downloadPublicImage(image as string).then((res) => {
              this.$set(this.presetImages[Number(index)], "data", res);
            });
          }
        }
      });
    },
  },
});
