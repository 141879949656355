export interface User {
  userId: string;
  name: string;
  agentStartedAt?: Date;
}

export enum Plan {
  /** 無料会員 */
  free = 0,
  /** 月額会員 */
  monthly = 1,
  /** 年額会員 */
  yearly = 2,
  /** スクール会員 */
  school = 3,
}

export interface ConnectedOtherServices {
  nolaPublishing: ExistsOtherServiceUser;
  nolaNovel: ExistsOtherServiceUser;
}

interface ExistsOtherServiceUser {
  result?: boolean;
  error?: string;
}
