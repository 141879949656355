export enum ListType {
  FOLDER,
  ITEM,
}

export enum ListState {
  COMPLETE,
  EDIT,
  DELETE,
}
