import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { Kind, SortOrder } from "../models/sortOrder";

interface IDisplayOrderClient {
  fetchDisplayOrder(input: { novelId?: string; kind: Kind }): Promise<SortOrder>;

  updateDisplayOrder(input: { novelId?: string; kind: Kind; order: string[] }): Promise<SortOrder>;
}

export class DisplayOrderClient implements IDisplayOrderClient {
  async fetchDisplayOrder(input: { novelId?: string; kind: Kind }): Promise<SortOrder> {
    const params: RequestParam = {
      query: fetchDisplayOrder,
      variables: {
        input,
      },
    };

    const { displayOrder } = await GraphQLClient.request(params);
    return displayOrder;
  }

  async updateDisplayOrder(input: { novelId?: string | undefined; kind: Kind; order: string[] }): Promise<SortOrder> {
    const params: RequestParam = {
      query: updateDisplayOrder,
      variables: {
        input,
      },
    };
    const { updateDisplayOrder: result } = await GraphQLClient.request(params);
    return result;
  }
}

/**
 * Query
 */

const fetchDisplayOrder = gql`
  query FetchDisplayOrder($input: DisplayOrderGetInput!) {
    displayOrder(input: $input) {
      novelId
      kind
      order
      createdAt
      updatedAt
    }
  }
`;

/**
 * Mutation
 */

const updateDisplayOrder = gql`
  mutation UpdateDisplayOrder($input: UpdateDisplayOrderInput!) {
    updateDisplayOrder(input: $input) {
      novelId
      kind
      order
      createdAt
      updatedAt
    }
  }
`;
