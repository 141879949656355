import gql from "graphql-tag";
import { API, graphqlOperation } from "aws-amplify";
import * as log from "@/lib/log";
import { NolaApiInitializer, NolaNovelApiInitializer } from "@/initializer";
import { showNotifyDialog } from "./dialog";

const help = process.env.VUE_APP_NOTION_HELP;

export type RequestParam = {
  query: string;
  variables?: any;
};

/**
 * ESLintのErrorが気になったので、Clientのクラスとして定義した
 * 影響範囲が大きいので元のexport functionは残しておく
 */
export default class GraphQLClient {
  static async request(params: RequestParam) {
    try {
      NolaApiInitializer.init();

      const { data, errors } = <any>await API.graphql(graphqlOperation(params.query, params.variables));
      if (errors) {
        await showNotifyDialog({
          title: "エラー",
          content: `エラーが発生しました。 ${errors}`,
        });
      }
      return data;
    } catch (error: any) {
      let content = "エラーが発生しました。";

      if (error.errors) {
        const e = error.errors[0] || {};
        if (e.message === "Network Error") {
          content = `ネットワークエラーです。<br />しばらく経ってから再度送信してください。<br />ご迷惑をおかけして申し訳ありません。<br /><br />原因の解消に関しましては <a href="${help}" target="blank">ヘルプ</a> をご確認ください`;
        } else {
          const { path } = e;
          let { message } = e;

          /** Error handling during subscribe with Stripe */
          if (path && path.includes("subscribe")) {
            if (message.includes("This promotion code has expired.")) {
              message = "このクーポンはご利用上限回数を超えています。";
            } else if (
              message.includes(
                "This promotion code cannot be redeemed because the associated customer has prior transactions."
              )
            ) {
              message = "このクーポンは初回利用限定のためご利用いただけません。";
            }
          }

          content += `<br />${message}`;
        }
      }

      await showNotifyDialog({
        title: "エラー",
        content,
      });

      log.error({ error });

      throw error;
    }
  }
}

export class NolaNovelGraphQLClient {
  static async request(params: RequestParam) {
    try {
      NolaNovelApiInitializer.init();

      const { query, variables } = params;
      const { data, errors } = <any>await API.graphql(graphqlOperation(query, variables));
      if (errors) await this.showError(errors);

      return data;
    } catch (error: any) {
      await this.showError(error);
      log.error({ error });
      throw error;
    } finally {
      NolaApiInitializer.init();
    }
  }

  static async showError(error?: any) {
    const title = "エラー";
    let content = `エラーが発生しました。${error}`;

    if (error.errors) {
      const e = error.errors[0] || {};
      if (e.message === "Network Error") {
        content = `ネットワークエラーです。<br />しばらく経ってから再度送信してください。<br />ご迷惑をおかけして申し訳ありません。<br /><br />原因の解消に関しましては <a href="${help}" target="blank">ヘルプ</a> をご確認ください`;
      } else {
        content += `<br />${e.message}`;
      }
    }

    const message = {
      title,
      content,
    };

    await showNotifyDialog({ ...message });
  }
}

export const novel = gql`
  query($id: ID!) {
    novel(id: $id) {
      title
      description
    }
  }
`;

export async function callApi(query: string, variables?: any) {
  try {
    NolaApiInitializer.init();

    const { data, errors } = <any>await API.graphql(graphqlOperation(query, variables));
    if (errors) {
      await showNotifyDialog({
        title: "エラー",
        content: `エラーが発生しました。 ${errors}`,
      });
    }
    return data;
  } catch (error: any) {
    let content = "エラーが発生しました。";
    if (error.errors) {
      const e = error.errors[0] || {};
      if (e.message === "Network Error") {
        content = `ネットワークエラーです。<br />しばらく経ってから再度送信してください。<br />ご迷惑をおかけして申し訳ありません。<br /><br />原因の解消に関しましては <a href="${help}" target="blank">ヘルプ</a> をご確認ください`;
      } else {
        content += `<br />${e.message}`;
      }
    }

    await showNotifyDialog({
      title: "エラー",
      content,
    });

    log.error({ error });

    throw error;
  }
}
