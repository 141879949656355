import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { WritingDataClient } from "@/lib/clients";
import { WritingData, WritingDataListInput } from "@/lib/models/writingData";
import { format, subDays } from "date-fns";

const writingDataClient = new WritingDataClient();

// Stateの型定義
export interface GeneralState {
  last7DaysWritingCountList: WritingData[];
}

type Getters = {
  todayWritingCount(state: GeneralState): number;
  last7DaysWritingCount(state: GeneralState): number;
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>) => Promise<void>;
};

const mutations: MutationTree<GeneralState> = {
  setLast7DaysWritingCountList(state, payload: WritingData[]) {
    return (state.last7DaysWritingCountList = payload);
  },
};

const actions: ActionTree<GeneralState, {}> & Actions = {
  async initialize({ commit }) {
    const endDate = format(new Date(), "YYYYMMDD");
    const startDate = format(subDays(endDate, 6), "YYYYMMDD");
    const writingCountListInput: WritingDataListInput = {
      duration: "daily",
      startDate,
      endDate,
    };
    const last7DaysWritingCountList = await writingDataClient.writingCountList(writingCountListInput);

    commit("setLast7DaysWritingCountList", last7DaysWritingCountList);
  },
};

const getters: GetterTree<GeneralState, {}> & Getters = {
  todayWritingCount: (state) => {
    if (state.last7DaysWritingCountList.length === 0) return 0;

    const today = format(new Date(), "YYYYMMDD");
    const todayWritingCount = state.last7DaysWritingCountList.find((x) => format(x.updatedAt, "YYYYMMDD") === today);
    return todayWritingCount ? todayWritingCount.analyticsValue : 0;
  },
  last7DaysWritingCount: (state) => state.last7DaysWritingCountList.reduce((acc, cur) => acc + cur.analyticsValue, 0),
};

export default {
  namespaced: true,
  state: {
    last7DaysWritingCountList: [],
  },
  getters,
  actions,
  mutations,
};
