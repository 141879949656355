import Dexie from "dexie";

export class AppDatabase extends Dexie {
  comment: Dexie.Table<Comment, string>;

  generalSettings: Dexie.Table<GeneralSettings, string>;

  manuscriptSettings: Dexie.Table<ManuscriptSettings, string>;

  proofreadingSettings: Dexie.Table<ProofreadingSettings, string>;

  constructor(databaseName: string) {
    super(databaseName);

    const db = this;

    db.version(2).stores({
      comment: "commentId, manuscriptKey, plotId, sharedLinkId, userId, novelId, alreadyRead",
      generalSettings: "",
      manuscriptSettings: "novelId",
      proofreadingSettings: "",
    });

    db.comment.mapToClass(Comment);
    db.comment.mapToClass(ManuscriptSettings);
  }
}

export interface IComment {
  commentId: string;
  manuscriptKey: string;
  plotId: string;
  sharedLinkId: string;
  userId: string;
  novelId: string;
  alreadyRead: boolean;
}

export class Comment implements IComment {
  commentId: string;

  manuscriptKey: string;

  plotId: string;

  sharedLinkId: string;

  userId: string;

  novelId: string;

  alreadyRead: boolean;

  constructor(
    manuscriptKey: string,
    plotId: string,
    sharedLinkId: string,
    commentId: string,
    userId: string,
    novelId: string,
    alreadyRead: boolean = false
  ) {
    this.commentId = commentId;
    this.manuscriptKey = manuscriptKey;
    this.plotId = plotId;
    this.sharedLinkId = sharedLinkId;
    this.userId = userId;
    this.novelId = novelId;
    this.alreadyRead = alreadyRead;
  }
}

export interface IGeneralSettings {
  value: any;
}

export class GeneralSettings implements IGeneralSettings {
  value: any;

  constructor(value: any) {
    this.value = value;
  }
}

export interface IManuscriptSettings {
  novelId: string;
  wordCountOnVertical: number;
  lineCountPerPage: number;
  fontSizeRate: number;
}

export class ManuscriptSettings implements IManuscriptSettings {
  novelId: string;

  wordCountOnVertical: number;

  lineCountPerPage: number;

  fontSizeRate: number;

  constructor(novelId: string, wordCountOnVertical: number, lineCountPerPage: number, fontSizeRate: number) {
    this.novelId = novelId;
    this.wordCountOnVertical = wordCountOnVertical;
    this.lineCountPerPage = lineCountPerPage;
    this.fontSizeRate = fontSizeRate;
  }
}

export interface IProofreadingSettings {
  enable: boolean;
  rules: object;
  isConfirmed: boolean;
}

export class ProofreadingSettings implements IProofreadingSettings {
  enable: boolean;

  rules: object;

  isConfirmed: boolean;

  constructor(enable: boolean, rules: any, isConfirmed: boolean) {
    this.enable = enable;
    this.rules = rules;
    this.isConfirmed = isConfirmed;
  }
}

export const db = new AppDatabase("PersistLocalStorage");
