import { MaterialClient } from "@/lib/clients";
import {
  LayoutEnum,
  MaterialAttribute,
  MaterialFolder,
  MaterialInputTemplate,
  MaterialItem,
  NovelMaterial,
  RecentMaterialId,
} from "@/lib/models";
import { SortOrder } from "@/lib/models/sortOrder";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { createdAtSort, reOrderData } from "@/lib/utils";
import { uploadImage } from "@/lib/storages";
import { db, GeneralSettings as GeneralSettingsDexie } from "@/lib/indexeddb";

const materialClient = new MaterialClient();

const defaultAttributes = (novelId: string): MaterialAttribute[] => [
  {
    id: "character",
    novelId,
    name: "登場人物",
    materialOrder: [],
    folderOrder: [],
  },
  {
    id: "worldview",
    novelId,
    name: "世界観",
    materialOrder: [],
    folderOrder: [],
  },
  {
    id: "correlation",
    novelId,
    name: "相関関係",
    materialOrder: [],
    folderOrder: [],
  },
];

export interface MaterialState {
  attributeSortOrders: SortOrder[];
  attributes: MaterialAttribute[];
  materials: NovelMaterial[];
  templateSortOrder: SortOrder | null;
  templates: MaterialInputTemplate[];
  folders: MaterialFolder[];
  recentMaterialIds: RecentMaterialId[];
  recentTemplateId: string;
}

type Getters = {
  attributeSortOrder(state: MaterialState): (id: string) => SortOrder | undefined;
  attributes(state: MaterialState): MaterialAttribute[];
  attributesFromNovelId(state: MaterialState): (id: string, onlyMaterial: boolean) => MaterialAttribute[];
  attribute(state: MaterialState): (id: string) => MaterialAttribute | undefined;
  materials(state: MaterialState): NovelMaterial[];
  materialsFromAttribute(state: MaterialState): (attributeId: string, folderId?: string) => NovelMaterial[];
  material(state: MaterialState): (id: string) => NovelMaterial | undefined;
  templateSortOrder(state: MaterialState): SortOrder | null;
  templates(state: MaterialState): MaterialInputTemplate[];
  template(state: MaterialState): (id: string) => MaterialInputTemplate | undefined;
  folders(state: MaterialState): MaterialFolder[];
  foldersFromAttribute(state: MaterialState): (attributeId: string) => MaterialFolder[];
  folder(state: MaterialState): (id: string) => MaterialFolder | undefined;
  recentMaterialId(state: MaterialState): (attributeId: string) => string | undefined;
  recentTemplateId(state: MaterialState): string;
};

const getters: GetterTree<MaterialState, {}> & Getters = {
  attributeSortOrder: (state) => (id: string) => {
    const { attributeSortOrders } = state;
    return attributeSortOrders.find((sortOrder) => sortOrder.novelId === id);
  },
  attributes: (state) => state.attributes,
  attributesFromNovelId: (state) => (id: string, onlyMaterial: boolean = false) => {
    const { attributes } = state;
    if (onlyMaterial) {
      return attributes.filter((attribute) => attribute.novelId === id);
    }

    return defaultAttributes(id).concat(attributes.filter((attribute) => attribute.novelId === id));
  },
  attribute: (state) => (id: string) => {
    const { attributes } = state;
    return attributes.find((attribute) => attribute.id === id);
  },
  materials: (state) => state.materials,
  materialsFromAttribute: (state) => (attributeId: string, folderId?: string) => {
    const { materials, attributes, folders } = state;
    const attribute = attributes.find((attribute) => attribute.id === attributeId);
    const folder = folders.find((folder) => folder.id === folderId);
    const filterd = materials.filter((material) => {
      const inAttribute = material.attributeId === attributeId;

      if (folderId && folder) {
        return inAttribute && folder.materialOrder.includes(material.id);
      }

      return inAttribute;
    });

    let sorted = filterd.sort((a, b) => createdAtSort(a, b, "createdAsc"));
    // 資料種別全体のソート
    if (attribute) {
      sorted = reOrderData(filterd, attribute.materialOrder);
    }

    // フォルダ内のソート
    if (folder) {
      sorted = reOrderData(filterd, folder.materialOrder);
    }

    return sorted;
  },
  material: (state) => (id: string) => {
    const { materials } = state;
    return materials.find((material) => material.id === id);
  },
  templateSortOrder: (state) => state.templateSortOrder,
  templates: (state) => {
    const { templates, templateSortOrder } = state;
    const filtered = templates.concat();
    let sorted = filtered.sort((a, b) => createdAtSort(a, b, "createdAsc"));

    if (templateSortOrder) {
      sorted = reOrderData(templates, templateSortOrder.order);
    }

    return sorted.map((template) => ({
      ...template,
      items: template.items.map((item) => ({
        ...item,
        // NOTE: inputしたときに判定しやすいようにIDをつけてViewに返す
        // 実際に資料に適用するとき固定のIDを付与していると参照時にいちいち変えないといけないので、保存時はIDなしになっている
        id: uuidv4(),
      })),
    }));
  },
  template: (state) => (id: string) => {
    const { templates } = state;
    const template = templates.find((template) => template.id === id);
    if (!template) {
      return undefined;
    }

    const items = template.items.map((item) => ({
      ...item,
      id: uuidv4(),
    }));

    return {
      ...template,
      items,
    };
  },
  folders: (state) => state.folders,
  foldersFromAttribute: (state) => (attributeId: string) => {
    const { attributes, folders } = state;
    const attribute = attributes.find((attribute) => attribute.id === attributeId);
    const filterd = folders.filter((folder) => folder.attributeId === attributeId);
    let sorted = filterd.sort((a, b) => createdAtSort(a, b, "createdAsc"));

    if (attribute) {
      sorted = reOrderData(filterd, attribute.folderOrder);
    }

    return sorted;
  },
  folder: (state) => (id: string) => {
    const { folders } = state;
    return folders.find((folder) => folder.id === id);
  },
  recentMaterialId: (state) => (attributeId: string) => {
    const { recentMaterialIds } = state;
    const recentMaterial = recentMaterialIds.find((x) => x.attributeId === attributeId);
    if (!recentMaterial) return undefined;
    return recentMaterial.materialId;
  },
  recentTemplateId(state) {
    const { recentTemplateId } = state;
    return recentTemplateId;
  },
};

type CreateMaterialAttributePayload = {
  novelId: string;
  name: string;
};

type CreateNovelMaterialPayload = {
  id: string;
  novelId: string;
  attributeId: string;
  layout: LayoutEnum;
  items: MaterialItem[];
};

type CreateMaterialInputTemplatePayload = {
  id: string;
  name: string;
  layout: LayoutEnum;
  items: MaterialItem[];
};

type CreateMaterialFolderPayload = { novelId: string; attributeId: string; name: string };

type UpdateNovelMaterialPayload = {
  id: string;
  novelId: string;
  attributeId: string;
  layout: LayoutEnum;
  items: MaterialItem[];
};

type UpdateMaterialInputTemplatePayload = {
  id: string;
  name: string;
  layout: LayoutEnum;
  items: MaterialItem[];
};

type UpdateMaterialAttributeOrderPayload = {
  novelId: string;
  order: string[];
};

type Actions = {
  /**
   * Util
   */

  initialize(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;
  clear(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: unknown): void;
  openMaterial(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: RecentMaterialId): void;
  openTemplate(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;

  /**
   * Fetch
   */

  fetchAttributeSortOrder(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;
  fetchAttributes(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;
  fetchMaterials(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;
  fetchTemplateSortOrder(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: unknown): void;
  fetchTemplates(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: unknown): void;
  fetchFolders(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string): void;

  /**
   * Create
   */

  createMaterialAttribute(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: CreateMaterialAttributePayload
  ): void;
  createNovelMaterial(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: CreateNovelMaterialPayload
  ): void;
  createMaterialInputTemplate(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: CreateMaterialInputTemplatePayload
  ): void;
  createMaterialFolder(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: CreateMaterialFolderPayload
  ): void;

  /**
   * Update
   */

  updateMaterialAttributeOrder(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: UpdateMaterialAttributeOrderPayload
  ): void;
  updateMaterialAttribute(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: MaterialAttribute[]
  ): void;
  updateNovelMaterial(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: UpdateNovelMaterialPayload
  ): void;
  updateTemplateSortOrder(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string[]): void;
  updateMaterialInputTemplate(
    this: Store<{}>,
    injectee: ActionContext<MaterialState, {}>,
    payload: UpdateMaterialInputTemplatePayload
  ): void;
  updateMaterialFolder(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: MaterialFolder[]): void;

  /**
   * Delete
   */

  deleteMaterialAttribute(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string[]): void;
  deleteNovelMaterial(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string[]): void;
  deleteMaterialInputTemplate(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string[]): void;
  deleteMaterialFolder(this: Store<{}>, injectee: ActionContext<MaterialState, {}>, payload: string[]): void;
};

const actions: ActionTree<MaterialState, {}> & Actions = {
  async initialize({ dispatch }, payload) {
    await Promise.all([
      dispatch("fetchAttributeSortOrder", payload),
      dispatch("fetchAttributes", payload),
      dispatch("fetchMaterials", payload),
      dispatch("fetchTemplates"),
      dispatch("fetchFolders", payload),
      dispatch("initializeRecentMaterialIds"),
      dispatch("initializeRecentTemplateId"),
    ]);
  },
  clear({ commit }) {
    commit("setAttributes", []);
    commit("setMaterials", []);
    commit("setTemplates", []);
    commit("setFolders", []);
  },
  async initializeRecentMaterialIds({ commit }) {
    const recentMaterialIdsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("recentMaterialIds")
    );
    if (recentMaterialIdsInIndexedDB) commit("setRecentMaterialIds", recentMaterialIdsInIndexedDB.value);
  },
  async initializeRecentTemplateId({ commit }) {
    const recentTemplateIdInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("recentTemplateId")
    );
    if (recentTemplateIdInIndexedDB) commit("setRecentTemplateId", recentTemplateIdInIndexedDB.value);
  },
  async openMaterial({ commit }, payload) {
    let list: RecentMaterialId[] = [];
    const recentMaterialIdsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("recentMaterialIds")
    );
    if (recentMaterialIdsInIndexedDB) {
      list = recentMaterialIdsInIndexedDB.value;
      if (!list.some((x) => x.attributeId === payload.attributeId)) list.push(payload);
      else
        list.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          if (x.attributeId === payload.attributeId) x.materialId = payload.materialId;
        });
    } else list.push(payload);

    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(list), "recentMaterialIds");
    });
    commit("setRecentMaterialIds", list);
  },
  async openTemplate({ commit }, payload) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(payload), "recentTemplateId");
    });
    commit("setRecentTemplateId", payload);
  },

  async fetchAttributeSortOrder({ state, commit }, payload) {
    const response = await materialClient.fetchAttributeSortOrder(payload);
    let sortOrders = state.attributeSortOrders.concat().filter((sortOrder) => sortOrder.novelId !== payload);
    sortOrders = sortOrders.concat(response);

    commit("setAttributeSortOrders", sortOrders);
  },
  async fetchAttributes({ state, commit }, payload) {
    const response = await materialClient.fetchAttributes(payload);
    let attributes = state.attributes.concat().filter((attribute) => attribute.novelId !== payload);
    attributes = attributes.concat(response);

    commit("setAttributes", attributes);
  },
  async fetchMaterials({ commit }, payload) {
    const response = await materialClient.fetchNovelMaterials(payload);
    let materials = state.materials.concat().filter((material) => material.novelId !== payload);
    materials = materials.concat(response);

    commit("setMaterials", materials);
  },
  async fetchTemplateSortOrder({ commit }) {
    const response = await materialClient.fetchTemplateSortOrder();
    commit("setTemplateSortOrder", response);
  },
  async fetchTemplates({ commit }) {
    const response = await materialClient.fetchTemplates();
    commit("setTemplates", response);
  },
  async fetchFolders({ commit }, payload) {
    const response = await materialClient.fetchFolders(payload);
    let folders = state.folders.concat().filter((folder) => folder.novelId !== payload);
    folders = folders.concat(response);

    commit("setFolders", folders);
  },

  async createMaterialAttribute({ state, commit }, payload) {
    const { novelId, name } = payload;
    const attribute: MaterialAttribute = {
      id: uuidv4(),
      novelId,
      name,
      materialOrder: [],
      folderOrder: [],
    };

    const response = await materialClient.createMaterialAttribute(attribute);
    const attributes = state.attributes.concat(response);
    commit("setAttributes", attributes);
  },
  async createNovelMaterial({ state, commit }, payload) {
    const { novelId, items } = payload;
    const uploads: any[] = [];
    for (let index = 0; index < items.length; index += 1) {
      const item = items[index];
      const { imagePath } = item;
      if (imagePath && imagePath.startsWith("data:")) {
        const key = `novels/${novelId}/materials/${item.id}.jpg`;
        uploads.push(uploadImage(key, imagePath));
        items[index].imagePath = key;
      }
    }

    await Promise.all(uploads);

    const response = await materialClient.createNovelMaterial({
      ...payload,
      items,
    });

    const materials = state.materials.concat(response);
    commit("setMaterials", materials);
  },
  async createMaterialInputTemplate({ state, commit }, payload) {
    const { id, layout, name, items } = payload;
    const template: MaterialInputTemplate = {
      id,
      layout,
      name,
      items,
    };

    const response = await materialClient.createMaterialInputTemplate(template);
    const templates = state.templates.concat(response);
    commit("setTemplates", templates);
  },
  async createMaterialFolder({ state, commit }, payload) {
    const { novelId, attributeId, name } = payload;
    const folder: MaterialFolder = {
      id: uuidv4(),
      novelId,
      attributeId,
      name,
      materialOrder: [],
    };

    const response = await materialClient.createMaterialFolder(folder);
    const folders = state.folders.concat(response);
    commit("setFolders", folders);
  },

  async updateMaterialAttributeOrder({ state, commit }, payload) {
    const { novelId, order } = payload;
    const preOrder = state.attributeSortOrders.find(
      (sortOrder) => sortOrder.novelId === novelId && sortOrder.kind === "materialAttribute"
    );

    let sortOrder: SortOrder = {
      novelId,
      order,
      kind: "materialAttribute",
      createdAt: 0,
      updatedAt: 0,
    };

    if (preOrder) {
      sortOrder = {
        ...preOrder,
        order,
      };
    }

    const response = await materialClient.updateMaterialAttributeOrder(sortOrder);
    let sortOrders = state.attributeSortOrders.concat();
    if (preOrder) {
      sortOrders = state.attributeSortOrders.concat().filter((sortOrder) => sortOrder !== preOrder);
    }

    sortOrders = sortOrders.concat(response);
    commit("setAttributeSortOrders", sortOrders);
  },
  async updateMaterialAttribute({ state, commit }, payload) {
    const { attributes: preAttributes } = state;
    const promise: any[] = [];
    let tmpAttributes = preAttributes.concat();

    for (let index = 0; index < payload.length; index += 1) {
      const newAttribute = payload[index];
      const preAttribute = preAttributes.find((preAttribute) => preAttribute.id === newAttribute.id);
      if (
        preAttribute &&
        (preAttribute.name !== newAttribute.name ||
          preAttribute.materialOrder !== newAttribute.materialOrder ||
          preAttribute.folderOrder !== newAttribute.folderOrder)
      ) {
        promise.push(materialClient.updateMaterialAttribute(newAttribute));
        tmpAttributes = tmpAttributes.filter((attribute) => attribute.id !== newAttribute.id);
      }
    }

    const results = await Promise.all(promise);
    const attributes = tmpAttributes.concat(results);
    commit("setAttributes", attributes);
  },
  async updateNovelMaterial({ state, commit }, payload) {
    const { novelId, items } = payload;
    const uploads: any[] = [];
    for (let index = 0; index < items.length; index += 1) {
      const item = items[index];
      const { imagePath } = item;
      if (imagePath && imagePath.startsWith("data:")) {
        const key = `novels/${novelId}/materials/${item.id}.jpg`;
        uploads.push(uploadImage(key, imagePath));
        items[index].imagePath = key;
      }
    }

    await Promise.all(uploads);

    const response = await materialClient.updateNovelMaterial({
      ...payload,
      items,
    });

    const tmpMaterials = state.materials.filter((material) => material.id !== payload.id);
    const materials = tmpMaterials.concat(response);
    commit("setMaterials", materials);
  },
  async updateTemplateSortOrder({ state, commit }, payload) {
    const preOrder = state.templateSortOrder;

    let sortOrder: SortOrder = {
      order: payload,
      kind: "materialInputTemplate",
      createdAt: 0,
      updatedAt: 0,
    };

    if (preOrder) {
      sortOrder = {
        ...preOrder,
        order: payload,
      };
    }

    const response = await materialClient.updateTemplateOrder(sortOrder);
    commit("setTemplateSortOrder", response);
  },
  async updateMaterialInputTemplate({ state, commit }, payload) {
    const { templates: preTemplates } = state;
    const tmpTemplates = preTemplates.filter((template) => template.id !== payload.id);

    const response = await materialClient.updateMaterialInputTemplate({ ...payload });
    const templates = tmpTemplates.concat(response);
    commit("setTemplates", templates);
  },
  async updateMaterialFolder({ state, commit }, payload) {
    const { folders: preFolders } = state;
    const promise: any[] = [];
    let tmpFolders = preFolders.concat();

    for (let index = 0; index < payload.length; index += 1) {
      const newFolder = payload[index];
      const preFolder = preFolders.find((preFolder) => preFolder.id === newFolder.id);
      if (preFolder && (preFolder.name !== newFolder.name || preFolder.materialOrder !== newFolder.materialOrder)) {
        promise.push(materialClient.updateMaterialFolder(newFolder));
        tmpFolders = tmpFolders.filter((folder) => folder.id !== newFolder.id);
      }
    }

    const results = await Promise.all(promise);
    const folders = tmpFolders.concat(results);
    commit("setFolders", folders);
  },

  async deleteMaterialAttribute({ state, dispatch, commit }, payload) {
    const { attributes: preAttributes } = state;
    const promise: any[] = [];

    for (let index = 0; index < payload.length; index += 1) {
      const id = payload[index];
      const attribute = preAttributes.find((attribute) => attribute.id === id);
      if (attribute) {
        promise.push(materialClient.deleteMaterialAttribute(attribute));
      }
    }

    const results = await Promise.all(promise);
    const attributes = preAttributes.filter((attribute) => !results.includes(attribute.id));
    commit("setAttributes", attributes);

    const { materials: preMaterials } = state;
    const deleteMaterials = preMaterials.filter((material) => payload.includes(material.attributeId));
    const ids = deleteMaterials.map((material) => material.id);
    dispatch("deleteNovelMaterial", ids);
  },
  async deleteNovelMaterial({ state, commit }, payload) {
    const { materials: preMaterials } = state;
    const promise: any[] = [];

    for (let index = 0; index < payload.length; index += 1) {
      const id = payload[index];
      const material = preMaterials.find((material) => material.id === id);
      if (material) {
        promise.push(materialClient.deleteNovelMaterial(material));
      }
    }

    const results = await Promise.all(promise);
    const materials = preMaterials.filter((material) => !results.includes(material.id));
    commit("setMaterials", materials);
  },
  async deleteMaterialInputTemplate({ state, commit }, payload) {
    const { templates: preTemplates } = state;
    const promise: any[] = [];

    for (let index = 0; index < payload.length; index += 1) {
      const id = payload[index];
      const template = preTemplates.find((template) => template.id === id);
      if (template) {
        promise.push(materialClient.deleteMaterialInputTemplate(template));
      }
    }

    const results = await Promise.all(promise);
    const template = preTemplates.filter((template) => !results.includes(template.id));
    commit("setTemplates", template);
  },
  async deleteMaterialFolder({ state, commit }, payload) {
    const { folders: preFolders } = state;
    const promise: any[] = [];

    for (let index = 0; index < payload.length; index += 1) {
      const id = payload[index];
      const folder = preFolders.find((folder) => folder.id === id);
      if (folder) {
        promise.push(materialClient.deleteMaterialFolder(folder));
      }
    }

    const results = await Promise.all(promise);
    const folders = preFolders.filter((folder) => !results.includes(folder.id));
    commit("setFolders", folders);
  },
};

type Mutations<S> = {
  setAttributeSortOrders(state: S, payload: SortOrder[]): void;
  setAttributes(state: S, payload: MaterialAttribute[]): void;
  setMaterials(state: S, payload: NovelMaterial[]): void;
  setTemplateSortOrder(state: S, payload: SortOrder): void;
  setTemplates(state: S, payload: MaterialInputTemplate[]): void;
  setFolders(state: S, payload: MaterialFolder[]): void;
  setRecentMaterialIds(state: S, payload: RecentMaterialId[]): void;
  setRecentTemplateId(state: S, payload: string): void;
};

const mutations: MutationTree<MaterialState> & Mutations<MaterialState> = {
  setAttributeSortOrders(state, payload) {
    state.attributeSortOrders = payload;
  },
  setAttributes(state, payload) {
    state.attributes = payload;
  },
  setMaterials(state, payload) {
    state.materials = payload;
  },
  setTemplateSortOrder(state, payload) {
    state.templateSortOrder = payload;
  },
  setTemplates(state, payload) {
    state.templates = payload;
  },
  setFolders(state, payload) {
    state.folders = payload;
  },
  setRecentMaterialIds(state, payload) {
    state.recentMaterialIds = payload;
  },
  setRecentTemplateId(state, payload) {
    state.recentTemplateId = payload;
  },
};

const state: MaterialState = {
  attributeSortOrders: [],
  attributes: [],
  materials: [],
  templateSortOrder: null,
  templates: [],
  folders: [],
  recentMaterialIds: [],
  recentTemplateId: "",
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
