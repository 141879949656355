import { MemoClient } from "@/lib/clients";
import { Memo, MemoTag } from "@/lib/models";
import { MemoStorage } from "@/lib/storages";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { db, GeneralSettings as GeneralSettingsDexie } from "@/lib/indexeddb";

const memoClient = new MemoClient();
const memoStorage = new MemoStorage();

// Stateの型定義
export interface MemosState {
  memos: Memo[];
  memoTags: MemoTag[];
  recentMemoId: string;
}

type Actions = {
  createMemo: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: CreateMemoPayload) => any;
  updateMemo: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: UpdateMemoPayload) => any;
  deleteMemo: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: DeleteMemoPayload) => any;
  createMemoTag: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: CreateMemoTagPayload) => any;
  deleteMemoTag: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: DeleteMemoTagPayload) => any;
  openMemo: (this: Store<{}>, injectee: ActionContext<MemosState, {}>, payload: string) => any;
};

interface CreateMemoPayload {
  id: string;
  novelId: string | null | undefined;
  s3BucketPath: string;
  callback: () => void;
}

interface UpdateMemoPayload {
  memo: Memo;
  content?: string;
  callback: () => void;
}

interface DeleteMemoPayload {
  id: string;
  callback: () => void;
}

interface DeleteMemoTagPayload {
  memoTag: MemoTag;
  callback: () => void;
}

interface CreateMemoTagPayload {
  id: string;
  name: string;
  callback?: () => void;
}

type Getters = {
  memo(state: MemosState): (id: string) => Memo | undefined;
  memosFromMemoTagsAndNovel(state: MemosState): (id: string, memoTags: MemoTag[]) => Memo[];
  memoTag(state: MemosState): (id: string) => MemoTag | undefined;
  memoTagsFromMemo(state: MemosState): (memo: Memo) => MemoTag[];
  recentMemoId(state: MemosState): string;
};

const mutations: MutationTree<MemosState> = {
  setMemos(state, payload) {
    return (state.memos = payload);
  },
  setMemoTags(state, payload) {
    return (state.memoTags = payload);
  },
  pushMemo(state, payload) {
    state.memos.push(payload);
    return state.memos;
  },
  pushMemoTag(state, payload) {
    state.memoTags.push(payload);
    return state.memoTags;
  },
  updateMemo(state, payload: Memo) {
    return (state.memos = state.memos.map((memo) => {
      if (memo.id === payload.id) {
        return payload;
      }

      return memo;
    }));
  },
  popMemo(state, payload) {
    const memos = state.memos.filter((memo) => memo.id !== payload);
    return (state.memos = memos);
  },
  popMemoTag(state, payload) {
    const memoTags = state.memoTags.filter((memoTag) => memoTag.id !== payload);
    return (state.memoTags = memoTags);
  },
  setRecentMemoId(state, payload) {
    return (state.recentMemoId = payload);
  },
};

const actions: ActionTree<MemosState, {}> & Actions = {
  async initialize({ dispatch }) {
    await Promise.all([
      dispatch("initializeMemos"),
      dispatch("initializeMemoTags"),
      dispatch("initializeRecentMemoId"),
    ]);
  },
  async initializeMemos({ commit }, _) {
    const memos = await memoClient.fetchAllMemo();
    commit("setMemos", memos);
  },
  async initializeMemoTags({ commit }, _) {
    const memoTags = await memoClient.fetchAllMemoTag();
    commit("setMemoTags", memoTags);
  },
  async initializeRecentMemoId({ commit }) {
    const recentMemoIdInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("recentMemoId")
    );
    if (recentMemoIdInIndexedDB) commit("setRecentMemoId", recentMemoIdInIndexedDB.value);
  },
  async createMemo({ commit }, payload: CreateMemoPayload) {
    const { id, novelId, s3BucketPath, callback } = payload;
    const memo: Memo = await memoClient.createMemo(id, novelId, s3BucketPath);
    if (memo) {
      commit("pushMemo", memo);
      callback();
    }
  },
  async createMemoTag({ commit }, payload: CreateMemoTagPayload) {
    const { id, name, callback } = payload;
    const memoTag = await memoClient.createMemoTag(id, name);
    if (memoTag) {
      commit("pushMemoTag", memoTag);
      if (callback) callback();
    }
  },
  async updateMemo({ commit }, payload: UpdateMemoPayload) {
    const { callback, content } = payload;
    if (content) await memoStorage.uploadFile(payload.memo.id, content);
    const memo = await memoClient.updateMemo(payload.memo);
    if (memo) {
      commit("updateMemo", memo);
      if (callback) callback();
    }
  },
  async deleteMemo({ commit }, payload: DeleteMemoPayload) {
    const { callback } = payload;
    const id = await memoClient.deleteMemo(payload.id);
    if (id) {
      commit("popMemo", id);
      if (callback) callback();
    }
  },
  async deleteMemoTag({ commit }, payload: DeleteMemoTagPayload) {
    const { memoTag, callback } = payload;
    const id = await memoClient.deleteMemoTag(memoTag);
    if (id) {
      commit("popMemoTag", id);
      callback();
    }
  },
  async openMemo({ commit }, payload: string) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(payload), "recentMemoId");
    });
    commit("setRecentMemoId", payload);
  },
};

const getters: GetterTree<MemosState, {}> & Getters = {
  memo: (state) => (id: string) => state.memos.find((memo) => memo.id === id),
  memosFromMemoTagsAndNovel: (state) => (id: string, memoTags: MemoTag[]) => {
    let { memos } = state;
    if (id && id !== "all") {
      memos = state.memos.filter((memo) => memo.novelId === id);
    }

    if (memoTags.length === 0) return memos;

    return memos.filter((memo) => {
      if (!memo.items) return false;

      return memo.items.some((item) =>
        // Memoが持つTagIdが引数のMemoTag[]に含まれている場合はtrue
        memoTags.some((tag) => tag.id === item)
      );
    });
  },
  memoTag: (state) => (id: string) => state.memoTags.find((memoTag) => memoTag.id === id),
  memoTagsFromMemo: (state) => (memo: Memo) => {
    if (memo.items === null || memo.items === undefined) return [];

    const memoTags = memo.items.map((item) => {
      const memoTag = state.memoTags.find((memoTag) => item === memoTag.id);
      if (memoTag) return memoTag;
    });

    return memoTags.filter((memoTag) => memoTag !== undefined) as MemoTag[];
  },
  recentMemoId: (state) => state.recentMemoId,
};

export default {
  namespaced: true,
  state: {
    memos: [],
    memoTags: [],
    recentMemoId: "",
  },
  getters,
  actions,
  mutations,
};
