import Vue from "vue";

import "normalize.css";
import "./style.scss";

import Vue2TouchEvents from "vue2-touch-events";

// modal
import * as ModalDialogs from "vue-modal-dialogs";
import Notifications from "vue-notification";

// analytics
import VueAnalytics from "vue-analytics";
import VueGtm from "@gtm-support/vue2-gtm";

import { Auth, API } from "aws-amplify";
// @ts-ignore
import vuescroll from "vue-scroll";
import { StripePlugin, StripePluginOptions } from "@vue-stripe/vue-stripe";
import VueMq from "vue-mq";
import sanitizeHTML from "sanitize-html";
import VueMeta from "vue-meta";

import { createPinia, PiniaVuePlugin } from "pinia";
import { NolaApiInitializer } from "./initializer";
import initializeTwitterAuth from "./lib/twitterAuth";
import store from "./store";
import router from "./router";
import App from "./App.vue";

NolaApiInitializer.init();

Vue.config.productionTip = false;

Vue.config.errorHandler = function (error, vm, info) {
  NolaApiInitializer.init();

  API.post("Rest", "/log", {
    body: `${JSON.stringify(error, Object.getOwnPropertyNames(error))} ${JSON.stringify(info)}`,
  });
  /** アラートは非表示にする */
  // alert(`Error: ${JSON.stringify({ error, info })}`);
  console.log(`Captured in Vue.config.errorHandler: ${info}`, error);
};
window.addEventListener("error", (event) => {
  console.log("Captured in error EventListener", event.error);
});
window.addEventListener("unhandledrejection", (event) => {
  console.log("Captured in unhandledrejection EventListener", event);
});
Vue.use(vuescroll);
Vue.use(Vue2TouchEvents);
Vue.use(ModalDialogs);
Vue.use(Notifications);

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  router,
  ecommerce: {
    enabled: true,
  },
});
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CONTAINER_ID,
  vueRouter: router,
});

const options: StripePluginOptions = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY!,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT!,
  apiVersion: process.env.VUE_APP_STRIPE_API_VERSION!,
  locale: process.env.VUE_APP_STRIPE_LOCALE!,
};
Vue.use(StripePlugin, options);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sp: 768,
    pc: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});

const defaultOptions = sanitizeHTML.defaults;
const customOptions = {
  allowedTags: [...defaultOptions.allowedTags, "img"],
  allowedAttributes: {
    ...defaultOptions.allowedAttributes,
    img: ["src"],
  },
  allowedSchemes: [...defaultOptions.allowedSchemes, "data", "http", "https"],
};
const customSanitizeHtml = (dirtyHtml: string) => sanitizeHTML(dirtyHtml, customOptions);
Vue.prototype.$sanitize = customSanitizeHtml;
Vue.use(VueMeta);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

async function init() {
  if (!(await initializeTwitterAuth())) {
    return;
  }

  try {
    NolaApiInitializer.init();

    const res = await Auth.currentUserPoolUser();

    if (res.attributes.profile) {
      const profile = JSON.parse(res.attributes.profile);
      // Federated Identity登録
      if (!profile.identityId) {
        profile.identityId = res.signInUserSession.idToken.payload.sub;
        await Auth.updateUserAttributes(res, { profile: JSON.stringify(profile) });
      }

      // データ移行
      if (profile.migrateStatus === "ready") {
        await API.post("Rest", "/migrateUserData", {
          body: {
            currentUserPoolUserId: res.signInUserSession.idToken.payload.sub,
            migrateFromIdentityId: profile.migrateIdentityId,
          },
        });

        profile.migrateStatus = "done";
        await Auth.updateUserAttributes(res, { profile: JSON.stringify(profile) });
      }
    }
  } catch (error) {
    if (error !== "No current user") {
      const err = JSON.stringify(error, Object.getOwnPropertyNames(error));
      console.error(err);
      await API.post("Rest", "/log", { body: `Migrate error:${err}` });
    }
  }

  // window.nameを設定する
  window.name = process.env.VUE_APP_NOLA_NAME ?? "";

  new Vue({
    router,
    store,
    pinia,
    render: (h) => h(App),
  }).$mount("#app");
}

init();
