import { showNotifyDialog } from "../dialog";
import { saveBinary } from "../s3";

export async function uploadImage(key: string, src: string): Promise<void> {
  if (!src.startsWith("data:")) {
    return;
  }

  const blob = uriToBlob(src);

  try {
    await saveBinary(key, blob);
  } catch (e) {
    let message = `画像のアップロードに失敗しました。<br />${e}`;

    if (e instanceof Error) {
      message = `画像のアップロードに失敗しました<br />${e.message}`;
    }

    await showNotifyDialog({
      title: "画像アップロードエラー",
      content: message,
    });

    throw e;
  }
}

function uriToBlob(data: string) {
  const binary = atob(data.split(",")[1]);
  const array = [] as number[];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}
