import { Auth } from "aws-amplify";
import gql from "graphql-tag";
import { NolaNovelGraphQLClient, RequestParam } from "../api";
import {
  AnalyticsFromNolaNovelInput,
  ListAnalytics,
  NotificationSortType,
  NotificationsFromNolaNovel,
  NovelFromNolaNovel,
  SortDirection,
  UserFromNolaNovel,
} from "../models";

interface INolaNovelClient {
  fetchUserFromNolaNovel: () => Promise<UserFromNolaNovel>;
  fetchAllNovelFromNolaNovel: () => Promise<NovelFromNolaNovel[]>;
  fetchNovelByIdFromNolaNovel: (id: string) => Promise<NovelFromNolaNovel>;
  addNolaNovelLink: (novelId: string, nolaNovelId: string) => Promise<{ id: string; novelIdsNola: string[] }>;
  deleteNolaNovelLink: (novelId: string, nolaNovelId: string) => Promise<{ id: string; novelIdsNola: string[] }>;
  fetchAnalytics: (payload: AnalyticsFromNolaNovelInput[]) => Promise<ListAnalytics>;
  fetchNotifications: (payload: boolean) => Promise<NotificationsFromNolaNovel>;
  loadNotifications: (isRead: boolean, lastKey: string) => Promise<NotificationsFromNolaNovel>;
  readNotification: (typeTargetIdSubTargetIds: string[]) => Promise<void>;
}

export class NolaNovelClient implements INolaNovelClient {
  async fetchUserFromNolaNovel(): Promise<UserFromNolaNovel> {
    const user = await Auth.currentUserPoolUser();
    const id = user.signInUserSession.idToken.payload.sub;

    const params: RequestParam = {
      query: fetchUserFromNolaNovel,
      variables: {
        input: {
          cognitoId: id,
        },
      },
    };

    const { getUser } = await NolaNovelGraphQLClient.request(params);
    return getUser;
  }

  async fetchAllNovelFromNolaNovel(): Promise<NovelFromNolaNovel[]> {
    const params: RequestParam = {
      query: fetchAllNovelFromNolaNovel,
      variables: {
        input: {},
      },
    };

    const { listNovels } = await NolaNovelGraphQLClient.request(params);
    return listNovels.items;
  }

  async fetchNovelByIdFromNolaNovel(id: string): Promise<NovelFromNolaNovel> {
    const params: RequestParam = {
      query: fetchNovelByIdFromNolaNovel,
      variables: {
        input: {
          id,
        },
      },
    };

    const { getNovel } = await NolaNovelGraphQLClient.request(params);
    return getNovel;
  }

  async addNolaNovelLink(novelId: string, nolaNovelId: string): Promise<{ id: string; novelIdsNola: string[] }> {
    const params: RequestParam = {
      query: linkNolaNovel,
      variables: {
        input: {
          linkId: novelId,
          novelId: nolaNovelId,
          operation: "ADD",
          service: "NOLA",
        },
      },
    };

    const { linkNovelId: result } = await NolaNovelGraphQLClient.request(params);
    return result;
  }

  async deleteNolaNovelLink(novelId: string, nolaNovelId: string): Promise<{ id: string; novelIdsNola: string[] }> {
    const params: RequestParam = {
      query: linkNolaNovel,
      variables: {
        input: {
          linkId: novelId,
          novelId: nolaNovelId,
          operation: "DELETE",
          service: "NOLA",
        },
      },
    };

    const { linkNovelId: result } = await NolaNovelGraphQLClient.request(params);
    return result;
  }

  async fetchAnalytics(payload: AnalyticsFromNolaNovelInput[]): Promise<ListAnalytics> {
    const query = `
      query ListAnalytics(
        ${payload.map((_, index) => `$input${index}: ListAnalyticsInput!\n`)}
      ) {
        ${payload.map(
          (_, index) => `
          result${index}: listAnalytics(input: $input${index}) {
            count
            items {
              targetId
              typeTermDate
              count
              meta
              createdAt
              updatedAt
            }
          }\n`
        )}
      }`;

    const variables = {} as { [key: string]: any };
    payload.forEach((item, index) => {
      variables[`input${index}`] = item;
    });

    const params: RequestParam = {
      query,
      variables,
    };

    return await NolaNovelGraphQLClient.request(params);
  }

  async fetchNotifications(isRead: boolean, limit = 15): Promise<NotificationsFromNolaNovel> {
    const params: RequestParam = {
      query: listNotifications,
      variables: {
        input: {
          direction: SortDirection.DESC,
          sort: NotificationSortType.PUBLISHED_AT,
          limit,
          isRead,
        },
      },
    };

    const { listNotifications: result } = await NolaNovelGraphQLClient.request(params);
    return result;
  }

  async loadNotifications(isRead: boolean, lastKey: string, limit = 15): Promise<NotificationsFromNolaNovel> {
    const params: RequestParam = {
      query: listNotifications,
      variables: {
        input: {
          direction: SortDirection.DESC,
          sort: NotificationSortType.PUBLISHED_AT,
          limit,
          isRead,
          startAt: lastKey,
        },
      },
    };

    const { listNotifications: result } = await NolaNovelGraphQLClient.request(params);
    return result;
  }

  async readNotification(payload: string[]): Promise<void> {
    const params: RequestParam = {
      query: updateNotifications,
      variables: {
        input: payload.map((typeTargetIdSubTargetId) => ({
          typeTargetIdSubTargetId,
          isRead: true,
        })),
      },
    };

    const { updateNotifications: result } = await NolaNovelGraphQLClient.request(params);
    return result;
  }
}

// Query

const fetchUserFromNolaNovel = gql`
  query GetUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      name
      profile
      imagePath
      usePurpose
      followCount
      followerCount
      updatedAt
      createdAt
      company {
        companyId
        companyName
        genre
        tag
        name
      }
      novels {
        id
        title
        outline
        appeal
        imagePath
        workType
        characterCount
        genreType
        genre
        subGenre
        isComplete
        isPublic
        isOncePublic
        isAcceptReview
        isAcceptComment
        isAcceptTypoReport
        selfRating
        draftType
        rating
        readerGender
        readerAgeGroup
        readerTitle
        tag
        bookmarkCount
        reviewOverview {
          allPointCount
          allPointSum
          characterPointCount
          characterPointSum
          interestPointCount
          interestPointSum
          readablePointCount
          readablePointSum
          reviewCount
          storyPointCount
          storyPointSum
        }
        createdAt
        updatedAt
        publishedAt
      }
    }
  }
`;

const fetchAllNovelFromNolaNovel = gql`
  query ListNovels($input: ListNovelsInput!) {
    listNovels(input: $input) {
      items {
        id
        title
        outline
        appeal
        imagePath
        workType
        characterCount
        genreType
        genre
        subGenre
        isComplete
        isPublic
        isOncePublic
        isAcceptReview
        isAcceptComment
        isAcceptTypoReport
        selfRating
        draftType
        rating
        readerGender
        readerAgeGroup
        readerTitle
        tag
        bookmarkCount
        reviewOverview {
          allPointCount
          allPointSum
          characterPointCount
          characterPointSum
          interestPointCount
          interestPointSum
          readablePointCount
          readablePointSum
          reviewCount
          storyPointCount
          storyPointSum
        }
        createdAt
        updatedAt
        publishedAt
        user {
          id
          name
          profile
          imagePath
          followCount
          followerCount
          usePurpose
          updatedAt
          createdAt
        }
        episodes {
          id
          title
          type
          order
          publishedAt
          characterCount
          novelIdNola
          episodeIdNola
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const fetchNovelByIdFromNolaNovel = gql`
  query GetNovel($input: GetNovelInput!) {
    getNovel(input: $input) {
      id
      title
      outline
      appeal
      imagePath
      workType
      characterCount
      genreType
      genre
      subGenre
      isComplete
      isPublic
      isOncePublic
      isAcceptReview
      isAcceptComment
      isAcceptTypoReport
      selfRating
      draftType
      rating
      readerGender
      readerAgeGroup
      readerTitle
      tag
      bookmarkCount
      reviewOverview {
        allPointCount
        allPointSum
        characterPointCount
        characterPointSum
        interestPointCount
        interestPointSum
        readablePointCount
        readablePointSum
        reviewCount
        storyPointCount
        storyPointSum
      }
      createdAt
      updatedAt
      publishedAt
      episodes {
        id
        type
        title
        order
        preface
        postscript
        bodyPath
        publishedAt
        isOncePublic
        characterCount
        updatedAt
        createdAt
      }
    }
  }
`;

const linkNolaNovel = gql`
  mutation LinkNolaNovel($input: LinkNovelIdInput!) {
    linkNovelId(input: $input) {
      id
      novelIdsNola
    }
  }
`;

const listNotifications = gql`
  query ListNotifications($input: ListNotificationsInput!) {
    listNotifications(input: $input) {
      items {
        userId
        typeTargetIdSubTargetId
        type
        isRead
        publishedAt
        novel {
          id
          title
        }
        episode {
          id
          title
        }
        comment {
          id
          body
        }
        user {
          id
          name
        }
        activity {
          id
          title
        }
        company {
          id
          name
        }
      }
      count
      lastKey
    }
  }
`;

export const updateNotifications = gql`
  mutation UpdateNotifications($input: [UpdateNotificationsInput]!) {
    updateNotifications(input: $input)
  }
`;
