import { NolaApiInitializer } from "@/initializer";
import { API } from "aws-amplify";

let _expire = 0;
let _isUnderMaintenance = false;

// メンテナンス中かどうかを返す
export async function isMaintainance() {
  if (Date.now() < _expire) {
    return _isUnderMaintenance;
  }

  NolaApiInitializer.init();

  const res = await API.get("Rest", "/isundermaintenance", {});
  const { isUnderMaintenance } = res;
  _isUnderMaintenance = isUnderMaintenance;

  // Cache 5 seconds.
  _expire = Date.now() + 5000;
  return isUnderMaintenance;
}
