
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      expandSideMenu: true,
    };
  },
  methods: {
    toggleExpand() {
      this.expandSideMenu = !this.expandSideMenu;
    },
  },
});
