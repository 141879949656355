import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { Theme, Detail } from "../models/theme";

interface IThemeClient {
  fetchTheme: (novelId: string) => Promise<Theme>;
  updateNovelTheme: (novelId: string, detail: Detail[]) => Promise<Theme>;
}

export class ThemeClient implements IThemeClient {
  async fetchTheme(novelId: string): Promise<Theme> {
    const params: RequestParam = {
      query: fetchTheme,
      variables: {
        novelId,
      },
    };

    const { theme } = await GraphQLClient.request(params);
    return theme;
  }

  async updateNovelTheme(novelId: string, detail: Detail[]): Promise<Theme> {
    const params: RequestParam = {
      query: updateTheme,
      variables: {
        input: {
          novelId,
          detail,
        },
      },
    };

    const { updateTheme: updatedTheme } = await GraphQLClient.request(params);
    return updatedTheme;
  }
}

const fetchTheme = gql`
  query Theme($novelId: ID!) {
    theme(novelId: $novelId) {
      novelId
      detail {
        name
        value
      }
    }
  }
`;

const updateTheme = gql`
  mutation UpdateTheme($input: UpdateThemeInput!) {
    updateTheme(input: $input) {
      novelId
      detail {
        name
        value
      }
    }
  }
`;
