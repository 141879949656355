import { render, staticRenderFns } from "./UserDeleteDialog.vue?vue&type=template&id=276f2827&scoped=true"
import script from "./UserDeleteDialog.vue?vue&type=script&lang=ts"
export * from "./UserDeleteDialog.vue?vue&type=script&lang=ts"
import style0 from "./UserDeleteDialog.vue?vue&type=style&index=0&id=276f2827&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276f2827",
  null
  
)

export default component.exports