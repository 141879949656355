// import Vue from 'vue';
// import { AnyARecord } from 'dns';
import { Route, NavigationGuardNext } from "vue-router";
import { showOkCancelDialog } from "./dialog";

let _modified = false;

export function setModified(modified: boolean) {
  _modified = modified;
}

export function isModified() {
  return _modified;
}

const CONFIRM_MESSAGE = "保存されていない変更があります。<br />変更を破棄して移動しますか？";

export class Plugin {
  private _modified: Boolean;

  constructor() {
    window.addEventListener("beforeunload", this.beforeunload);
    this._modified = false;
  }

  beforeunload = (e: BeforeUnloadEvent) => {
    if (!this._modified) return;

    e.returnValue = CONFIRM_MESSAGE;
  };

  install(Vue: any, options: any) {
    Vue.directive("my-directive", {});

    Vue.mixin({
      //     mounted() {
      //         this._modified = false;
      //         window.addEventListener('beforeunload', this.beforeunload);
      //         console.log(`ff`);

      //     },
      beforeRouteLeave: async (to: Route, from: Route, next: NavigationGuardNext) => {
        if (this._modified) {
          const yes = await showOkCancelDialog({
            content: CONFIRM_MESSAGE,
            okButton: "破棄して移動する",
            cancelButton: "留まる",
          });
          if (!yes) {
            return;
          }
          setModified(false);

          this._modified = false;
        }
        next();
      },
      beforeRouteUpdate: async (to: Route, from: Route, next: NavigationGuardNext) => {
        if (this._modified) {
          const yes = await showOkCancelDialog({
            content: CONFIRM_MESSAGE,
            okButton: "破棄して移動する",
            cancelButton: "留まる",
          });
          if (!yes) {
            return;
          }
          setModified(false);

          this._modified = false;
        }
        next();
      },
    });
    //     data() {
    //         return {
    //             _modified: false
    //         };
    //     },
    //     methods: {
    //         setChange(changed: Boolean = true) {
    //             this._modified = changed;
    //             console.log({ change__: this._modified });
    //         },

    //         beforeunload(e: BeforeUnloadEvent) {

    //             if (!this._modified) return;

    //             var confirmMessage = '未保存の変更があります。ページから離れますか？';
    //             e.returnValue = confirmMessage;
    //             return confirmMessage;
    //         }

    //     }
    // })

    // 4. インスタンスメソッドを追加
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.setModified = (m: Boolean = true) => {
      this._modified = m;
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.isModified = () => this._modified;
  }
}
