
import Vue from "vue";

export default Vue.extend({
  methods: {
    cancel() {
      this.$close(false);
    },
    onSelectColorTheme(event) {
      this.$store.dispatch("manuscriptModule/selectTheme", event.target.value);
    },
  },
  computed: {
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
  },
});
