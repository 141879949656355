import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { MaterialAttribute, MaterialFolder, MaterialInputTemplate, NovelMaterial } from "../models";
import { SortOrder } from "../models/sortOrder";
import { DisplayOrderClient } from "./displayOrderClient";

const displayOrderClient = new DisplayOrderClient();

interface IMaterialClient {
  fetchAttributeSortOrder(novelId: string): Promise<SortOrder>;
  fetchAttributes(novelId: string): Promise<MaterialAttribute[]>;
  fetchNovelMaterials(novelId: string): Promise<NovelMaterial[]>;
  fetchTemplateSortOrder(): Promise<SortOrder>;
  fetchTemplates(): Promise<MaterialInputTemplate[]>;
  fetchFolders(novelId: string): Promise<MaterialFolder[]>;

  createMaterialAttribute(attribute: MaterialAttribute): Promise<MaterialAttribute>;
  createNovelMaterial(novelMaterial: NovelMaterial): Promise<NovelMaterial>;
  createMaterialInputTemplate(template: MaterialInputTemplate): Promise<MaterialInputTemplate>;
  createMaterialFolder(folder: MaterialFolder): Promise<MaterialFolder>;

  updateMaterialAttribute(attribute: MaterialAttribute): Promise<MaterialAttribute>;
  updateNovelMaterial(novelMaterial: NovelMaterial): Promise<NovelMaterial>;
  updateMaterialInputTemplate(template: MaterialInputTemplate): Promise<MaterialInputTemplate>;
  updateMaterialFolder(folder: MaterialFolder): Promise<MaterialFolder>;
  updateMaterialAttributeOrder(sortOrder: SortOrder): Promise<SortOrder>;
  updateTemplateOrder(sortOrder: SortOrder): Promise<SortOrder>;

  deleteMaterialAttribute(attribute: MaterialAttribute): Promise<string>;
  deleteNovelMaterial(material: NovelMaterial): Promise<string>;
  deleteMaterialInputTemplate(template: MaterialInputTemplate): Promise<string>;
  deleteMaterialFolder(folder: MaterialFolder): Promise<string>;
}

export class MaterialClient implements IMaterialClient {
  async fetchAttributeSortOrder(novelId: string): Promise<SortOrder> {
    const displayOrder = await displayOrderClient.fetchDisplayOrder({
      novelId,
      kind: "materialAttribute",
    });
    return displayOrder;
  }

  async fetchAttributes(novelId: string): Promise<MaterialAttribute[]> {
    const params: RequestParam = {
      query: fetchAttributes,
      variables: {
        input: {
          novelId,
          items: [],
        },
      },
    };
    const { syncMaterialAttribute } = await GraphQLClient.request(params);
    return syncMaterialAttribute;
  }

  async fetchNovelMaterials(novelId: string): Promise<NovelMaterial[]> {
    const params: RequestParam = {
      query: fetchNovelMaterials,
      variables: {
        input: {
          novelId,
          items: [],
        },
      },
    };

    const { syncNovelMaterial } = await GraphQLClient.request(params);
    return syncNovelMaterial;
  }

  async fetchTemplateSortOrder(): Promise<SortOrder> {
    const displayOrder = await displayOrderClient.fetchDisplayOrder({
      kind: "materialInputTemplate",
    });
    return displayOrder;
  }

  async fetchTemplates(): Promise<MaterialInputTemplate[]> {
    const params: RequestParam = {
      query: fetchTemplates,
      variables: {
        input: {
          items: [],
        },
      },
    };

    const { syncMaterialInputTemplate } = await GraphQLClient.request(params);
    return syncMaterialInputTemplate;
  }

  async fetchFolders(novelId: string): Promise<MaterialFolder[]> {
    const params: RequestParam = {
      query: fetchFolders,
      variables: {
        input: {
          novelId,
          items: [],
        },
      },
    };

    const { syncMaterialFolder } = await GraphQLClient.request(params);
    return syncMaterialFolder;
  }

  async createMaterialAttribute(attribute: MaterialAttribute): Promise<MaterialAttribute> {
    const { id, novelId, name } = attribute;
    const params: RequestParam = {
      query: createMaterialAttribute,
      variables: {
        input: {
          id,
          novelId,
          name,
        },
      },
    };

    const { createMaterialAttribute: result } = await GraphQLClient.request(params);
    return result;
  }

  async createNovelMaterial(novelMaterial: NovelMaterial): Promise<NovelMaterial> {
    const { id, novelId, attributeId, layout, items } = novelMaterial;
    const params: RequestParam = {
      query: createNovelMaterial,
      variables: {
        input: {
          id,
          novelId,
          attributeId,
          layout,
          items,
        },
      },
    };

    const { createNovelMaterial: result } = await GraphQLClient.request(params);
    return result;
  }

  async createMaterialInputTemplate(template: MaterialInputTemplate): Promise<MaterialInputTemplate> {
    const input = template;
    delete input.updatedAt;
    input.createdAt = new Date().toISOString();
    const params: RequestParam = {
      query: createMaterialInputTemplate,
      variables: {
        input,
      },
    };

    const { createMaterialInputTemplate: result } = await GraphQLClient.request(params);
    return result;
  }

  async createMaterialFolder(folder: MaterialFolder): Promise<MaterialFolder> {
    const { id, novelId, attributeId, name } = folder;

    const params: RequestParam = {
      query: createMaterialFolder,
      variables: {
        input: {
          id,
          novelId,
          attributeId,
          name,
        },
      },
    };

    const { createMaterialFolder: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateMaterialAttribute(attribute: MaterialAttribute): Promise<MaterialAttribute> {
    const input = attribute;
    delete input.createdAt;
    input.updatedAt = new Date().toISOString();
    const params: RequestParam = {
      query: updateMaterialAttribute,
      variables: { input },
    };

    const { updateMaterialAttribute: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateNovelMaterial(novelMaterial: NovelMaterial): Promise<NovelMaterial> {
    const input = novelMaterial;
    delete input.createdAt;
    input.updatedAt = new Date().toISOString();
    const params: RequestParam = {
      query: updateNovelMaterial,
      variables: {
        input,
      },
    };

    const { updateNovelMaterial: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateMaterialInputTemplate(template: MaterialInputTemplate): Promise<MaterialInputTemplate> {
    const input = template;
    delete input.createdAt;
    input.updatedAt = new Date().toISOString();
    const params: RequestParam = {
      query: updateMaterialInputTemplate,
      variables: {
        input,
      },
    };

    const { updateMaterialInputTemplate: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateMaterialFolder(folder: MaterialFolder): Promise<MaterialFolder> {
    const input = folder;
    delete input.createdAt;
    input.updatedAt = new Date().toISOString();
    const params: RequestParam = {
      query: updateMaterialFolder,
      variables: { input },
    };

    const { updateMaterialFolder: result } = await GraphQLClient.request(params);
    return result;
  }

  async updateMaterialAttributeOrder(sortOrder: SortOrder): Promise<SortOrder> {
    const { novelId, kind, order } = sortOrder;

    const displayOrder = await displayOrderClient.updateDisplayOrder({
      novelId,
      kind,
      order,
    });
    return displayOrder;
  }

  async updateTemplateOrder(sortOrder: SortOrder): Promise<SortOrder> {
    const { kind, order } = sortOrder;

    const displayOrder = await displayOrderClient.updateDisplayOrder({
      kind,
      order,
    });
    return displayOrder;
  }

  async deleteMaterialAttribute(attribute: MaterialAttribute): Promise<string> {
    const { id, novelId } = attribute;
    const params: RequestParam = {
      query: deleteMaterialAttribute,
      variables: {
        input: {
          id,
          novelId,
        },
      },
    };

    const { deleteMaterialAttribute: result } = await GraphQLClient.request(params);
    return result.id;
  }

  async deleteNovelMaterial(material: NovelMaterial): Promise<string> {
    const { id, novelId } = material;
    const params: RequestParam = {
      query: deleteNovelMaterial,
      variables: {
        input: {
          id,
          novelId,
        },
      },
    };

    const { deleteNovelMaterial: result } = await GraphQLClient.request(params);
    return result.id;
  }

  async deleteMaterialInputTemplate(template: MaterialInputTemplate): Promise<string> {
    const { id } = template;
    const params: RequestParam = {
      query: deleteMaterialInputTemplate,
      variables: {
        input: id,
      },
    };

    const { deleteMaterialInputTemplate: result } = await GraphQLClient.request(params);
    return result.id;
  }

  async deleteMaterialFolder(folder: MaterialFolder): Promise<string> {
    const { id, novelId } = folder;
    const params: RequestParam = {
      query: deleteMaterialFolder,
      variables: {
        input: {
          id,
          novelId,
        },
      },
    };

    const { deleteMaterialFolder: result } = await GraphQLClient.request(params);
    return result.id;
  }
}

/**
 * Mutation
 */

/**
 * Fetch
 * Fetchと言いつつアプリで同期用に使ってるスキーマを使用している
 * ※それぞれに値を含めれば更新も同時に可能
 */

const fetchAttributes = gql`
  mutation FetchAttributes($input: SyncMaterialAttributeInput!) {
    syncMaterialAttribute(input: $input) {
      id
      name
      novelId
      materialOrder
      folderOrder
      createdAt
      updatedAt
    }
  }
`;

const fetchNovelMaterials = gql`
  mutation FetchNovelMaterials($input: SyncNovelMaterialInput!) {
    syncNovelMaterial(input: $input) {
      id
      novelId
      layout
      attributeId
      items {
        id
        type
        isRequired
        ratioX
        ratioY
        title
        value
        imagePath
      }
      createdAt
      updatedAt
    }
  }
`;

const fetchTemplates = gql`
  mutation FetchTemplates($input: SyncMaterialInputTemplateInput!) {
    syncMaterialInputTemplate(input: $input) {
      id
      name
      layout
      items {
        id
        type
        isRequired
        title
        ratioX
        ratioY
        imagePath
        value
      }
      createdAt
      updatedAt
    }
  }
`;

const fetchFolders = gql`
  mutation FetchFolder($input: SyncMaterialFolderInput!) {
    syncMaterialFolder(input: $input) {
      id
      novelId
      attributeId
      name
      materialOrder
      createdAt
      updatedAt
    }
  }
`;

/**
 * Create
 */

const createMaterialAttribute = gql`
  mutation CreateMaterialAttribute($input: CreateMaterialAttributeInput!) {
    createMaterialAttribute(input: $input) {
      id
      name
      novelId
      materialOrder
      folderOrder
      createdAt
      updatedAt
    }
  }
`;

const createNovelMaterial = gql`
  mutation CreateNovelMaterial($input: CreateNovelMaterialInput!) {
    createNovelMaterial(input: $input) {
      id
      novelId
      attributeId
      layout
      items {
        id
        type
        isRequired
        ratioX
        ratioY
        value
        title
        imagePath
      }
      createdAt
      updatedAt
    }
  }
`;

const createMaterialInputTemplate = gql`
  mutation CreateMaterialInputTemplate($input: CreateMaterialInputTemplateInput!) {
    createMaterialInputTemplate(input: $input) {
      id
      name
      layout
      items {
        id
        type
        isRequired
        title
        ratioX
        ratioY
        imagePath
        value
      }
      createdAt
      updatedAt
    }
  }
`;

const createMaterialFolder = gql`
  mutation CreateMaterialFolder($input: CreateMaterialFolderInput!) {
    createMaterialFolder(input: $input) {
      id
      novelId
      attributeId
      name
      materialOrder
      createdAt
      updatedAt
    }
  }
`;

/**
 * Update
 */

const updateMaterialAttribute = gql`
  mutation UpdateMaterialAttribute($input: UpdateMaterialAttributeInput!) {
    updateMaterialAttribute(input: $input) {
      id
      name
      novelId
      materialOrder
      folderOrder
      createdAt
      updatedAt
    }
  }
`;

const updateNovelMaterial = gql`
  mutation UpdateNovelMaterial($input: UpdateNovelMaterialInput!) {
    updateNovelMaterial(input: $input) {
      id
      novelId
      attributeId
      layout
      items {
        id
        type
        isRequired
        ratioX
        ratioY
        value
        title
        imagePath
      }
      createdAt
      updatedAt
    }
  }
`;

const updateMaterialInputTemplate = gql`
  mutation UpdateMaterialInputTemplate($input: UpdateMaterialInputTemplateInput!) {
    updateMaterialInputTemplate(input: $input) {
      id
      name
      layout
      items {
        id
        type
        isRequired
        title
        ratioX
        ratioY
        imagePath
        value
      }
      createdAt
      updatedAt
    }
  }
`;

const updateMaterialFolder = gql`
  mutation UpdateMaterialFolder($input: UpdateMaterialFolderInput!) {
    updateMaterialFolder(input: $input) {
      id
      novelId
      attributeId
      name
      materialOrder
      createdAt
      updatedAt
    }
  }
`;

/**
 * Delete
 */

const deleteMaterialAttribute = gql`
  mutation DeleteMaterialAttribute($input: DeleteMaterialAttributeInput!) {
    deleteMaterialAttribute(input: $input) {
      id
    }
  }
`;

const deleteNovelMaterial = gql`
  mutation DeleteNovelMaterial($input: DeleteNovelMaterialInput!) {
    deleteNovelMaterial(input: $input) {
      id
    }
  }
`;

const deleteMaterialInputTemplate = gql`
  mutation DeleteMaterialInputTemplate($input: ID!) {
    deleteMaterialInputTemplate(id: $input) {
      id
    }
  }
`;

const deleteMaterialFolder = gql`
  mutation DeleteMaterialFolder($input: DeleteMaterialFolderInput!) {
    deleteMaterialFolder(input: $input) {
      id
    }
  }
`;
