import { NolaApiInitializer } from "@/initializer";
import { API, Storage } from "aws-amplify";

interface IS3Client {
  getImagePath(src: string): Promise<string>;
}

export class S3Client implements IS3Client {
  async getImagePath(src: string): Promise<string> {
    const isPreset = src.startsWith("shared");

    if (isPreset) {
      NolaApiInitializer.init();

      const res: { url: string } = await API.get("Rest", `/s3/public/${src}`, {});
      return res.url;
    }

    if (src.startsWith("data:")) {
      return src;
    }

    const res = await Storage.vault.get(src, {
      download: false,
    });

    return res as string;
  }
}
