import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { Plan } from "../models/user";
import {
  RegisterCustomerInput,
  SubscriptionInput,
  VerifyPromotionCode,
  VerifyPromotionCodeInput,
} from "../models/billing";

interface IBillingClient {
  fetchPlan: () => Promise<Plan>;
  verifyPromotionCode: (payload: VerifyPromotionCodeInput) => Promise<VerifyPromotionCode>;
  registerCustomer: (payload: RegisterCustomerInput) => Promise<boolean>;
  subscribe: (payload: SubscriptionInput) => Promise<boolean>;
}

export class BillingClient implements IBillingClient {
  async fetchPlan(): Promise<Plan> {
    const { billingInfo } = await GraphQLClient.request({ query: fetchPlan });
    return billingInfo.plan;
  }

  async verifyPromotionCode(payload: VerifyPromotionCodeInput): Promise<VerifyPromotionCode> {
    const { code, plan } = payload;
    const params: RequestParam = {
      query: verifyPromotionCodeQuery,
      variables: {
        input: {
          code,
          plan,
        },
      },
    };

    const { verifyPromotionCode } = await GraphQLClient.request(params);
    return verifyPromotionCode;
  }

  async registerCustomer(payload: RegisterCustomerInput): Promise<boolean> {
    const { token, verificationToken, email } = payload;

    const params: RequestParam = {
      query: registerCustomerQuery,
      variables: {
        input: {
          token,
          verificationToken,
          email,
        },
      },
    };

    const { registerCustomer } = await GraphQLClient.request(params);
    return registerCustomer.result;
  }

  async subscribe(payload: SubscriptionInput): Promise<boolean> {
    const { plan, promotionCode } = payload;
    const input = {
      plan,
      promotionCode,
    };

    if (!promotionCode) {
      delete input.promotionCode;
    }

    const params: RequestParam = {
      query: subscribeQuery,
      variables: {
        input,
      },
    };

    const { subscribe } = await GraphQLClient.request(params);
    return subscribe.result;
  }
}

/**
 * Query
 */

const fetchPlan = gql`
  {
    billingInfo {
      plan
    }
  }
`;

const verifyPromotionCodeQuery = gql`
  query VerifyPromotionCode($input: VerifyPromotionCodeInput!) {
    verifyPromotionCode(input: $input) {
      result
      data {
        id
        description
      }
    }
  }
`;

const registerCustomerQuery = gql`
  mutation RegisterCustomer($input: RegisterCustomerInput!) {
    registerCustomer(input: $input) {
      result
    }
  }
`;

const subscribeQuery = gql`
  mutation subscribe($input: SubscribeInput!) {
    subscribe(input: $input) {
      result
    }
  }
`;
