import { render, staticRenderFns } from "./PresetSelectorDialog.vue?vue&type=template&id=54fde1ca&scoped=true"
import script from "./PresetSelectorDialog.vue?vue&type=script&lang=ts"
export * from "./PresetSelectorDialog.vue?vue&type=script&lang=ts"
import style0 from "./PresetSelectorDialog.vue?vue&type=style&index=0&id=54fde1ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54fde1ca",
  null
  
)

export default component.exports